import React from 'react';
import PropTypes from 'prop-types';
import { nanoid } from 'nanoid';

import { useStyles } from './style';

/**
 * Search dropdown list component
 *
 * @param {Array} list  list for search
 *
 * @param {Function} select_action  action for select
 *
 * @class
 */
const SearchList = ({
	list = null,

	select_action = () => null,
}) => {
	const classes = useStyles();

	if (!list || list.length === 0) return null;

	return (
		<div className={`flex flex-col py-xs w-full ${classes.container}`}>
			{list?.length > 0 ? (
				<ul className='flex flex-col'>
					{list.map((item) => (
						<li key={nanoid()} className={`flex`}>
							<button
								className={`flex py-xs px-sm w-full text-left ${classes.button}`}
								type='button'
								onClick={() => {
									select_action(item);
								}}>
								{item.name}
							</button>
						</li>
					))}
				</ul>
			) : (
				<p>Ничего не найдено...</p>
			)}
		</div>
	);
};

SearchList.propTypes = {
	list: PropTypes.array,

	select_action: PropTypes.func,
};

export default SearchList;
