import { makeStyles } from '@material-ui/core/styles';

import { borderDefault, borderRadius, color_map } from '../../../const/style';

export const useStyles = makeStyles({
	serviceList: {
		listStyle: 'none',
		display: 'flex',
		flexDirection: 'column',
		border: borderDefault,
		borderRadius: borderRadius,
		backgroundColor: color_map.white,
		padding: 0,
		margin: '6px 0 0 0',
	},

	service: {
		display: 'flex',
		padding: 12,
		alignItems: 'center',
	},

	serviceContainer: {
		borderBottom: `1px solid ${color_map.outline_gray}`,
		transition: '.2s ease',

		'&:last-child': {
			borderBottom: `none`,
		},

		'&:is(:hover, :active)': {
			backgroundColor: color_map.light_gray,
		},
	},

	service__text: {
		fontSize: 14,
		color: color_map.black,
		margin: 0,
	},
});
