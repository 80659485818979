import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { Link, useRedirect } from 'react-admin';
import PropTypes from 'prop-types';

import { color_map } from '../../../../const/style';

const useStyles = makeStyles({
	mainItemContainer: {
		overflow: 'hidden',
		display: 'flex',
		flexWrap: 'wrap',
	},

	mainItem: {
		padding: '6px 14px',
		display: 'flex',
		width: '100%',

		'& p': {
			margin: 0,
			fontSize: 16,
			lineHeight: '24px',
			userSelect: 'none',
		},

		'&:hover': {
			cursor: 'pointer',
			color: color_map.blue,
			backgroundColor: 'rgba(18, 99, 175, 0.1)',
		},
	},

	mainItem__imageContainer: {
		minWidth: 40,
		display: 'flex',
	},

	subContainer: {
		transition: 'all .2s ease',
		marginBottom: '-100%',
		width: '100%',
	},
	subContainer_open: {
		marginBottom: '0',
	},

	subCategoriesList: {
		padding: 0,
		listStyle: 'none',
	},

	subCategory: {
		padding: '5px 14px 7px 54px',
		display: 'flex',
		color: color_map.black,
		transition: 'all .2s ease',

		'& p': {
			margin: 0,
			fontSize: 16,
			lineHeight: '24px',
		},

		'&:hover': {
			color: color_map.blue,
			backgroundColor: 'rgba(18, 99, 175, 0.1)',
		},
	},

	subCategory_active: {
		backgroundColor: color_map.blue,
		color: color_map.white,

		'&:hover': {
			backgroundColor: color_map.blue,
			color: color_map.white,
		},

		'& svg': {
			color: color_map.black,
		},
	},
});

const SubMenuContainer = ({ icon = null, title = null, sub_menu = [] }) => {
	const classes = useStyles();
	const redirect = useRedirect();
	// открыто ли подменю
	const [is_open, toggle_open] = React.useState(false);

	/**
	 * Проверка `url` (если сейчас на странице из подменю, то открываем подменю)
	 */
	React.useEffect(() => {
		if (typeof window !== 'undefined' && sub_menu) {
			sub_menu.forEach((item) => {
				if (window.location.hash.includes(item.link)) toggle_open(true);
			});
		}
	}, []);

	/**
	 * Переход в первое подменю, когда оно там единственное
	 */
	React.useEffect(() => {
		if (is_open && sub_menu.length > 0) redirect(sub_menu[0].link);
	}, [is_open]);

	return (
		<div className={classes.mainItemContainer}>
			<div className={classes.mainItem} onClick={() => toggle_open(!is_open)}>
				<div className={classes.mainItem__imageContainer}>{icon}</div>
				<p>{title}</p>
			</div>
			<div
				className={
					is_open
						? `${classes.subContainer_open} ${classes.subContainer}`
						: `${classes.subContainer}`
				}
			>
				{sub_menu && sub_menu.length > 0 ? (
					<ul className={classes.subCategoriesList}>
						{sub_menu.map((sub_category, index) => {
							let is_active = false;

							if (window.location.hash.includes(sub_category.link)) is_active = true;

							return (
								<li key={index}>
									<Link
										className={
											is_active
												? `${classes.subCategory} ${classes.subCategory_active}`
												: `${classes.subCategory}`
										}
										to={sub_category.link}
									>
										<p>{sub_category.title}</p>
									</Link>
								</li>
							);
						})}
					</ul>
				) : null}
			</div>
		</div>
	);
};

SubMenuContainer.propTypes = {
	icon: PropTypes.element,
	title: PropTypes.string,
	sub_menu: PropTypes.array,
};

export default SubMenuContainer;
