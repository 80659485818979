import { makeStyles } from '@material-ui/core/styles';

import { borderDefault, borderRadius, color_map } from '../../../const/style';

export const useStyles = makeStyles({
	mainContainer: {
		display: 'flex',
		flexDirection: 'column',
	},

	productsContainer: {
		border: borderDefault,
		borderRadius: borderRadius,
		backgroundColor: color_map.white,

		'& p': {
			margin: '0',
		},
	},

	headerBlock: {
		display: 'flex',
		flexWrap: 'nowrap',
		borderBottom: borderDefault,
		padding: '6px 12px',
	},
	headerBlock__sectionText: {
		fontWeight: 700,
		fontSize: 12,
		lineHeight: 1.2,
		display: 'flex',
		alignItems: 'center',
		color: 'rgba(0, 0, 0, 0.6)',
	},

	nameSpace: {
		marginLeft: '72px !important',
	},

	priceSpace: {
		width: 80,
		marginLeft: '50px !important',
	},

	stateSpace: {
		width: 100,
		marginLeft: 'auto !important',
	},

	statusSpace: {
		width: 50,
		marginLeft: '50px !important',
	},

	products: {
		margin: 0,
		padding: 0,
		listStyle: 'none',
	},
});
