import React from 'react';
import { Link } from 'react-admin';
import PropTypes from 'prop-types';

import { useStyles } from './style';

import plus from '../../../../assets/components/plus.svg';

const AddButton = ({
	action = () => null,
	parameter_for_action = null,
	is_link = false,
	link = '/',
}) => {
	const classes = useStyles();

	if (is_link) {
		return (
			<Link to={link} className={classes.addButton} label={'Добавить'}>
				<img src={'/assets/icons/plus.svg'} alt='добавить' />
			</Link>
		);
	} else {
		return (
			<button
				className={classes.addButton}
				type='button'
				onClick={() => action(parameter_for_action)}
			>
				<img src={plus} alt='добавить' />
			</button>
		);
	}
};

AddButton.propTypes = {
	action: PropTypes.func,
	parameter_for_action: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
	is_link: PropTypes.bool,
	link: PropTypes.string,
};

export default AddButton;
