import { useStyles } from './style';

const ImageComponent = ({ data = null }) => {
	const classes = useStyles();

	return (
		<div className={classes.mainCategoryItem__imageContainer}>
			{data ? (
				<img className={classes.categoryItem__image} src={data} alt='category' />
			) : (
				<img
					className={classes.categoryItem__image}
					src={'/assets/temp/placeholder.png'}
					alt='placeholder'
				/>
			)}
		</div>
	);
};

export default ImageComponent;
