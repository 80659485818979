import React from 'react';
import {
	Create,
	DataProviderContext,
	ReferenceInput,
	required,
	SaveButton,
	SelectInput,
	SimpleForm,
	TextInput,
	Toolbar,
	useNotify,
	useRedirect,
	useRefresh,
} from 'react-admin';

import SEO from '../../Common/SEO';
import Dropdown from '../../Common/Interface/Dropdown';
import UndoButton from '../../Common/Interface/UndoButton';

import { categories_sorter, change_sub_categories } from '../../../utils/categories_sort';

import { createEditStyles } from '../../Common/createEditStyle';

export const CategoryCreate = (props) => {
	const classes = createEditStyles();

	const image_ref = React.createRef();

	const [image_data, update_image_data] = React.useState(null);
	const [parent_category_id, update_parent_category_id] = React.useState(null);
	const [sorted_categories, update_sorted_categories] = React.useState(null);

	const dataProvider = React.useContext(DataProviderContext);

	const refresh = useRefresh();
	const redirect = useRedirect();
	const notify = useNotify();

	/**
	 * Categories processing for Dropdown component
	 */
	React.useEffect(() => {
		// prepare a categories list
		dataProvider
			.getList('categories', {
				pagination: { page: 1, perPage: 200 },
				sort: { field: 'title', order: 'ASC' },
				filter: {},
			})
			.then(({ data }) => {
				// sort categories list (with subcategories sort)
				let new_categories_data = categories_sorter(data);

				// formatting data for Dropdown component
				new_categories_data = change_sub_categories(new_categories_data);

				// send new data to the `state`
				update_sorted_categories(new_categories_data);
			});

		refresh();
	}, []);

	const undo_edit = React.useCallback(() => {
		notify(`Создание отменено`);
		redirect('/categories');
	}, []);

	/**
	 * Dropdown processing
	 *
	 * @param {Array} new_data  dropdown selected array
	 */
	const _dropdown_processing = (new_data) => {
		if (new_data.length > 0) update_parent_category_id(new_data[0].id);
		else update_parent_category_id(null);
	};

	const reset_image_data = () => {
		const element = image_ref.current;

		element.value = '';

		update_image_data(null);
	};

	const PostCreateToolbar = (props) => (
		<Toolbar {...props}>
			<SaveButton
				transform={(data) => {
					const new_data = {
						...data,
						image: image_data,
						parent_category_id: parent_category_id,
					};

					if (image_data === null) delete new_data.image;

					return new_data;
				}}
				submitOnEnter={true}
				label='Создать'
			/>
			<UndoButton action={undo_edit} />
		</Toolbar>
	);

	return (
		<Create {...props}>
			<SimpleForm toolbar={<PostCreateToolbar />}>
				<SEO title={'Создание категории'} />
				<h1 className={classes.pageHeader}>Создание категории</h1>
				<TextInput source='title' validate={[required()]} />
				<h2 className={classes.blockHeader}>Родительская категория</h2>
				<Dropdown
					name='Название'
					list_data={sorted_categories}
					action={_dropdown_processing}
					max_items={1}
				/>
				<h2 className={classes.blockHeader}>Изображение</h2>
				<div className={classes.dataBlock}>
					<input
						type='file'
						onChange={(event) => update_image_data(event.target.files[0])}
						ref={image_ref}
					/>
					{image_data ? (
						<div className={classes.imagesBlock}>
							<img
								src={
									image_data && typeof image_data !== 'string'
										? URL.createObjectURL(image_data)
										: image_data && typeof image_data === 'string'
										? image_data
										: null
								}
								alt='изображение'
							/>
						</div>
					) : null}
					{/* <button
                        type="button"
                        className='flex self-baseline btn btn-delete mt-sm'
                        onClick={reset_image_data}>
                        Сбросить
                    </button> */}
				</div>
			</SimpleForm>
		</Create>
	);
};
