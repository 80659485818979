import { makeStyles } from '@material-ui/core/styles';

import { borderRadius } from '../../../../../const/style';

export const useStyles = makeStyles({
	image: {
		border: `1px solid var(--outline-gray)`,
		borderRadius: borderRadius,
		maxWidth: 60,
		maxHeight: 60,
	},

	product: {
		borderTop: `1px solid var(--outline-gray)`,

		'&:last-child': {
			borderBottom: `1px solid var(--outline-gray)`,
		},
	},
});
