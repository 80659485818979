import { makeStyles } from '@material-ui/core/styles';

import { borderDefault, color_map } from '../../../../../const/style';

export const useStyles = makeStyles({
	inputsContainer: {
		display: 'flex',
		flexWrap: 'nowrap',
		padding: '12px',
		borderBottom: borderDefault,
		margin: '0 -12px',
		width: 'calc(100% + 24px)',
		maxWidth: 'calc(100% + 24px)',
		backgroundColor: color_map.white,

		'&:nth-last-child(-n+1)': {
			borderBottom: 'none',
		},
	},
});
