import { makeStyles } from '@material-ui/core/styles';

import { borderDefault, borderRadius, color_map } from '../../../../const/style';

export const createProductListStyles = makeStyles({
	productList: {
		marginLeft: -12,
		marginRight: -12,
		width: 'calc(100% + 24px)',
	},

	image: {
		border: `1px solid var(--outline-gray)`,
		borderRadius: borderRadius,
		width: 60,
		height: 60,
	},

	product: {
		borderTop: `1px solid var(--outline-gray)`,

		'&:last-child': {
			borderBottom: `1px solid var(--outline-gray)`,
		},
	},
});
