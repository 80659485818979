import React from 'react';
import {
	// Link,
	List,
	useListContext,
} from 'react-admin';

import HeaderBlock from '../../Common/HeaderBlock';
import ImageComponent from '../../Common/ImageComponent';
import SEO from '../../Common/SEO';

import { useStyles } from './style';

/**
 * Компонент списка пользователя
 */
const Users = () => {
	const classes = useStyles();
	const { data, ids } = useListContext();
	const [users_data, change_users_data] = React.useState(null);

	React.useEffect(() => {
		let new_users_data = [];

		if (ids.length > 0) {
			for (let key in data) {
				new_users_data.push(data[key]);
			}
		}

		change_users_data(new_users_data);
	}, [data, ids]);

	return (
		<div className={classes.listContainer}>
			<div className={classes.headerContainer}>
				<p className={classes.header}>Наименование</p>
			</div>
			{users_data !== null && users_data.length > 0 ? (
				<ul className={classes.userList}>
					{users_data.map((user, index) => (
						<li key={index} className={classes.userContainer}>
							<div className={classes.user}>
								<ImageComponent data={user?.avatar?.url} />
								<p className={`${classes.user__text}`}>{user.name}</p>
							</div>
						</li>
					))}
				</ul>
			) : null}
		</div>
	);
};

export const UsersList = (props) => {
	const classes = useStyles();

	return (
		<List
			component='div'
			bulkActionButtons={false}
			{...props}
			perPage={300}
			actions={false}
			pagination={null}
		>
			<div className={classes.mainContainer}>
				<SEO title={'Пользователи'} />
				<HeaderBlock title={'Пользователи'} link={'users'} is_new={false} />
				<Users />
			</div>
		</List>
	);
};
