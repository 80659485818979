import React from 'react';
import { List, useListContext } from 'react-admin';
import { nanoid } from 'nanoid';

import HeaderBlock from '../../Common/HeaderBlock';
import Pagination from '../../Common/Pagination';
import SEO from '../../Common/SEO';
import ListItem from './ListItem';

import { currency_request } from '../../../utils/fetch';
import { product_per_page } from '../../../const/params';

import { useStyles } from './style';

/**
 *
 * @class
 */
const Products = () => {
	const classes = useStyles();
	// const dataProvider = useDataProvider();
	const [products, update_products] = React.useState([]);
	const [currencies_data, update_currencies_data] = React.useState(null);

	const {
		// fetched data
		filterValues,
		ids, // an array listing the ids of the records in the list, e.g [123, 456, ...]
		data, // an id-based dictionary of the list data, e.g. { 123: { id: 123, title: 'hello world' }, 456: { ... } }
		perPage,
		setPerPage,
		total,
	} = useListContext();

	React.useEffect(() => {
		setPerPage(product_per_page);

		if (filterValues && filterValues.search) {
			setPerPage(total);
		}

		currency_request().then((new_data) => update_currencies_data(new_data));
	}, []);

	React.useEffect(() => {
		if (filterValues.search && total && isFinite(total) && total !== perPage) setPerPage(total);
		else if (filterValues.search && isFinite(total) && total === 0) setPerPage(1);
		else if (!filterValues.search) setPerPage(product_per_page);
	}, [total]);

	React.useEffect(() => {
		let new_products = [];

		if (ids.length > 0) {
			ids.forEach((id) => {
				if (data[id]) new_products.push(data[id]);
			});
		}

		update_products(new_products);
	}, [data, ids]);

	return (
		<div className={classes.mainContainer}>
			<SEO title='Список товаров' />
			<HeaderBlock
				title={'Товары'}
				link={'products'}
				is_search={true}
				is_filter={true}
				counter_visible={true}
			/>
			<div className={classes.productsContainer}>
				<div className={classes.headerBlock}>
					<p className={`${classes.headerBlock__sectionText} ${classes.nameSpace}`}>
						Наименование
					</p>
					<p className={`${classes.headerBlock__sectionText} ${classes.stateSpace}`}>
						Наличие
					</p>
					<p className={`${classes.headerBlock__sectionText} ${classes.statusSpace}`}>
						Статус
					</p>
					<p className={`${classes.headerBlock__sectionText} ${classes.priceSpace}`}>
						Цена, руб.
					</p>
				</div>
				<ul className={classes.products}>
					{products.map((product) => (
						<ListItem key={nanoid()} data={product} currencies_data={currencies_data} />
					))}
				</ul>
			</div>
			<Pagination />
		</div>
	);
};

export const ProductList = (props) => {
	return (
		<List
			{...props}
			component='div'
			filter={{}}
			bulkActionButtons={false}
			actions={false}
			pagination={false}
			perPage={product_per_page}
		>
			<Products />
		</List>
	);
};
