/**
 * Функция-обёртка для запросов данных
 *
 * @param {String} url              адресс запроса
 * @param {Function} error_func     опциональная функция, которая выполняется при ошибке
 * @returns response
 */
export const fetch_wrapper = async (
	url = '',
	error_func = () => null,
	method = 'GET',
	body = null
) => {
	const h = new Headers(); //заголовки
	h.append('Accept', '*/*');
	h.append('Content-Type', 'application/json');
	if (localStorage.getItem('auth_token'))
		h.append('Authorization', localStorage.getItem('auth_token'));

	const req = new Request(url, {
		//создаём запрос: адресс, {заголовки}, тип, передаём это далее в fetch
		method: method,
		headers: h, //заголовки
		mode: 'cors',
		body: body ? JSON.stringify(body) : null,
	});
	const response = await fetch(req);
	if (!response.ok) {
		if (error_func) error_func(true);

		console.log(response);
	} else {
		if (error_func) error_func(false);

		return response;
	}
};
