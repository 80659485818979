import React from 'react';

import { useStyles } from './style';

const LoadingIco = () => {
	const classes = useStyles();

	return (
		<div className={classes.icoContainer}>
			<img
				className={classes.ico}
				draggable={false}
				src={'/assets/icons/climatprofi-logo-symbol.svg'}
				alt='logo'
			/>
		</div>
	);
};

export default LoadingIco;
