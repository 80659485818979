import React from 'react';
import PropTypes from 'prop-types';

import InputList from './InputList';

import { close_listener } from '../../../../utils/key_listener';

import { useStyles } from './style';

const close_ico = '/assets/icons/close-12.svg';

/**
 * Компонент выпадающего списка
 *
 * @param {Function} action     функция обновления данных в родителя
 * @param {Array} list_data     список данных для выбора
 * @param {Array} active_data   выбранные данные
 * @param {String} name         имя для поля выпадающего списка
 * @param {Number} max_items    max items list to select (optional)
 * @param {Number} current_id   id for exclude item from list (to hide the current record item)
 *
 * @class
 */
const Dropdown = ({
	action = () => null,
	list_data = [],
	active_data = [],
	name = '',
	max_items = null,
	current_id = null,
}) => {
	const classes = useStyles();

	const main_block = React.useRef(),
		dropdown_block = React.useRef();

	// локальные данные для квадратиков
	const [local_data, update_local_data] = React.useState([]);
	// локальные активные данные для квадратиков
	const [active_local_data, update_active_local_data] = React.useState([]);
	// открыт ли выпадающий список
	const [is_open, toggle_is_open] = React.useState(false);
	// активно ли внимание на выпадающем поле
	const [is_focus, toggle_is_focus] = React.useState(false);
	// активно ли само выпадающее поле
	const [is_active, toggle_is_active] = React.useState(false);

	React.useEffect(() => {
		return () => {
			_close_processing();
		};
	}, []);

	/**
	 * First active data load
	 */
	React.useEffect(() => {
		if (active_data && active_data.length > 0 && active_local_data.length === 0)
			update_active_local_data(active_data);
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [active_data]);

	/**
	 * Обработка данных, приходящих от родителя, приводя к формату работы внутри компонента
	 */
	React.useEffect(() => {
		if (list_data && list_data.length > 0) {
			/**
			 * Функция составления данных для квадратиков в выпадающем списке
			 *
			 * @param {Array} all_data  данные для форматирования
			 * @returns список данных для квадратиков в выпадающем списке
			 */
			const make_dropdown_data = (all_data = []) => {
				// составляем новые данные
				const new_data = all_data.map((item) => {
					// проверяем, выбран ли элемент
					let active_element = null;
					if (active_data && active_data.length > 0)
						active_element = active_data.find(
							(active_item) => active_item.id === item.id
						);

					let sub_data = [];

					// если есть подсписок, то отправляем составляться его рекурсивно
					if (item.sub_data && item.sub_data.length > 0)
						sub_data = make_dropdown_data(item.sub_data);

					return {
						id: item.id,
						title: item.title,
						sub_data: sub_data,
						checked: active_element ? true : false,
					};
				});

				return new_data;
			};

			// составляем данные квадратиков
			const new_local_data = make_dropdown_data(list_data);

			// отправляем обработанные данные для работы в локальное состояние
			update_local_data(new_local_data);
		} else if ((!list_data || (list_data && list_data.length === 0)) && local_data.length > 0)
			update_local_data([]);
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [list_data]);

	/**
	 * Обработка при изменении активного списка данных
	 */
	React.useEffect(() => {
		// переключение активного состояния
		if (active_local_data && active_local_data.length > 0 && !is_active) toggle_is_active(true);
		else if ((!active_local_data || active_local_data.length === 0) && is_active && !is_focus)
			toggle_is_active(false);

		// отправка активных (отмеченных) данных родителю
		if (active_local_data) action(active_local_data);
		else action([]);
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [active_local_data]);

	/**
	 * Обработка открытого состояния
	 */
	React.useEffect(() => {
		// переключение активного состояния
		if ((active_local_data && active_local_data.length > 0) || is_open) toggle_is_active(true);
		else if (!is_open && active_local_data.length === 0) toggle_is_active(false);

		if (is_open) {
			window.addEventListener('click', _outbox_click_check);
			window.addEventListener('keydown', _keyboard_check);
		} else {
			_close_processing();
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [is_open]);

	/**
	 * Обработка состояния внимания
	 */
	React.useEffect(() => {
		if (is_open && !is_focus) toggle_is_focus(true);
		else if (!is_open && is_focus) toggle_is_focus(false);
	}, [is_open, is_focus]);

	/**
	 * Формирование активных локальных данных из всех локальных
	 */
	React.useEffect(() => {
		if (local_data && local_data.length > 0) {
			const new_active_data = [];

			/**
			 * Функция проверки подданных
			 *
			 * @param {Array} data  данные для проверки
			 */
			const check_sub_data = (data = []) => {
				if (data && data.length > 0) {
					data.forEach((item) => {
						// если квадратик отмечен, отправляем его в активные данные
						if (item.checked)
							new_active_data.push({
								id: item.id,
								title: item.title,
							});

						// если есть подданные, то рекурсивно отправляем его в функцию проверки
						if (item.sub_data && item.sub_data.length > 0)
							check_sub_data(item.sub_data);
					});
				}
			};

			// первичная проверка
			check_sub_data(local_data);

			// отправляем сформированные данные
			update_active_local_data(new_active_data);
		}
	}, [local_data]);

	/**
	 * Функция удаления отдельной метки
	 *
	 * @param {Number} id   идентификатор метки
	 */
	const delete_active_data = (id) => {
		toggle_list_item(id, false);
	};

	/**
	 * Функция удаления всех меток
	 */
	const clear_active_data = () => {
		if (active_local_data && active_local_data.length > 0)
			active_local_data.forEach((item) => toggle_list_item(item.id, false));
	};

	/**
	 * Функция переключения квадратика
	 *
	 * @param {Number} id           `id` квадратика для поиска в данных
	 * @param {Boolean} checked     состояние квадратика
	 */
	const toggle_list_item = (id = null, checked = false) => {
		if (id) {
			// флаг "найдено ли", чтобы не отправлять проверку дальше
			let is_found = false;

			/**
			 * Функция изменения данных после взаимодействия с квадратиком
			 *
			 * @param {Array} data  данные для проверки
			 * @returns изменённые данные после взаимодействия с квадратиком
			 */
			const check_sub_items = (data = []) => {
				// защита от дурака
				if (Array.isArray(data)) {
					// проходим по массиву и изменяем его (ищем нужный объект данных, который связан с отдельным квадратиком)
					const temp_data = data.map((sub_item) => {
						if (sub_item.id === id) {
							sub_item.checked = checked;
							is_found = true;
							// если не найдено, то ищем среди подданных
						} else if (
							sub_item.sub_data &&
							sub_item.sub_data.length > 0 &&
							is_found === false
						) {
							check_sub_items(sub_item.sub_data);
						}

						return sub_item;
					});

					return temp_data;
				}
			};

			// формируем новые данные после взаимодействия с квадратиком
			const new_data = check_sub_items(local_data);

			// отправляем новые данные
			update_local_data(new_data);
		}
	};

	/**
	 * Click processing
	 *
	 * @param {ClickEvent} event
	 */
	const _process_main_block_click = (event) => {
		let element = event.target;

		if (element.tagName.toLowerCase() === 'div') {
			toggle_is_open(!is_open);
		}
	};

	/**
	 * Checking outbox click
	 *
	 * @param {ClickEvent} event
	 */
	const _outbox_click_check = (event) => {
		const element = event.target;

		if (
			!element.contains(main_block.current) &&
			dropdown_block.current &&
			!dropdown_block.current.contains(element) &&
			main_block.current &&
			!main_block.current.contains(element)
		) {
			toggle_is_open(false);
			_close_processing();
		}
	};

	/**
	 * Keypress check for close menu
	 *
	 * @param {KeyboardEvent} event
	 */
	const _keyboard_check = (event) => {
		close_listener(event, () => {
			toggle_is_open(false);
			_close_processing();
		});
	};

	/**
	 * After close menu processing (remove event listeners)
	 */
	const _close_processing = () => {
		window.removeEventListener('keydown', _keyboard_check);
		window.removeEventListener('click', _outbox_click_check);
	};

	// классы для блоков
	let main_block_class = `${classes.mainBlock}`,
		dropdown_name_class = `${classes.dropdownName}`;

	// добавляем активное состояние
	if (is_active) {
		dropdown_name_class += ` ${classes.dropdownName_active}`;
	}
	// добавляем состояние внимания
	if (is_focus) {
		main_block_class += ` ${classes.mainBlock_focus}`;
		dropdown_name_class += ` ${classes.dropdownName_focus}`;
	}

	return (
		<div className={classes.dropdownContainer}>
			<div className={main_block_class} onClick={_process_main_block_click} ref={main_block}>
				<p className={dropdown_name_class}>{name}</p>
				<div className={classes.tagList}>
					{active_local_data.map((active_item, index) => (
						<button
							key={index}
							className={`${classes.tag} tag`}
							type='button'
							onClick={() => delete_active_data(active_item.id)}
						>
							{active_item.title}
							<img
								className={`${classes.tag__close} tag__close`}
								src={close_ico}
								loading={'lazy'}
								alt='close'
							/>
						</button>
					))}
				</div>
				<div className={classes.buttons}>
					<button
						type='button'
						className={`${classes.button} ${classes.button_clear} button_clear`}
						onClick={() => clear_active_data()}
					>
						<svg
							width='16'
							height='16'
							viewBox='0 0 16 16'
							fill='none'
							xmlns='http://www.w3.org/2000/svg'
						>
							<path d='M4.15028 3.20747C3.88993 2.94712 3.46782 2.94712 3.20747 3.20747C2.94712 3.46782 2.94712 3.88993 3.20747 4.15028L7.05729 8.0001L3.20751 11.8499C2.94716 12.1102 2.94716 12.5323 3.20751 12.7927C3.46786 13.053 3.88997 13.053 4.15032 12.7927L8.0001 8.94291L11.8499 12.7927C12.1102 13.053 12.5323 13.053 12.7927 12.7927C13.053 12.5323 13.053 12.1102 12.7927 11.8499L8.94291 8.0001L12.7927 4.15028C13.0531 3.88993 13.0531 3.46782 12.7927 3.20747C12.5324 2.94712 12.1103 2.94712 11.8499 3.20747L8.0001 7.05729L4.15028 3.20747Z' />
						</svg>
					</button>
					<div className={classes.line} />
					<button
						type='button'
						className={`${classes.button} ${classes.button_open}`}
						onClick={() => toggle_is_open(!is_open)}
					>
						<svg
							width='16'
							height='16'
							viewBox='0 0 16 16'
							fill='none'
							xmlns='http://www.w3.org/2000/svg'
						>
							<path
								fillRule='evenodd'
								clipRule='evenodd'
								d='M2.19453 5.43341C2.45488 5.17306 2.87699 5.17306 3.13734 5.43341L7.99973 10.2958L12.8621 5.43341C13.1225 5.17306 13.5446 5.17306 13.8049 5.43341C14.0653 5.69375 14.0653 6.11586 13.8049 6.37621L8.47114 11.71C8.21079 11.9704 7.78868 11.9704 7.52833 11.71L2.19453 6.37621C1.93418 6.11587 1.93418 5.69376 2.19453 5.43341Z'
							/>
						</svg>
					</button>
				</div>
			</div>
			{local_data && local_data.length > 0 ? (
				<div
					className={
						is_open
							? `${classes.dropdown} ${classes.dropdown_open}`
							: `${classes.dropdown}`
					}
					ref={dropdown_block}
				>
					<InputList
						data={local_data}
						level={1}
						action={toggle_list_item}
						disabled={max_items && active_local_data.length >= max_items ? true : false}
						current_id={current_id}
					/>
				</div>
			) : null}
		</div>
	);
};

Dropdown.propTypes = {
	action: PropTypes.func,
	list_data: PropTypes.array,
	active_data: PropTypes.array,
	name: PropTypes.string,
	max_items: PropTypes.number,
	current_id: PropTypes.number,
};

export default Dropdown;
