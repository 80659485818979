import { makeStyles } from '@material-ui/core/styles';

export const useStyles = makeStyles({
	container: {
		margin: '0 -12px',
		width: 'calc(100% + 24px)',
	},

	addNewContainer: {
		display: 'flex',
		padding: '9px 12px',
	},
});
