import React from 'react';
import { List } from 'react-admin';

import HeaderBlock from '../../Common/HeaderBlock';
import SEO from '../../Common/SEO';
import { BrandList as BrandListComponent } from './BrandList';

/**
 * @class
 */
export const BrandList = (props) => (
	<List
		component='div'
		bulkActionButtons={false}
		{...props}
		perPage={300}
		actions={false}
		pagination={null}
	>
		<div>
			<SEO title={'Список брендов'} />
			<HeaderBlock title={'Бренды'} link={'brands'} />
			<BrandListComponent />
		</div>
	</List>
);
