import React from 'react';
import { Link } from 'react-admin';
import PropTypes from 'prop-types';
import { Draggable } from 'react-beautiful-dnd';

import ImageComponent from '../../../../Common/ImageComponent';
import DragButton from '../../../../Common/Interface/DragButton';

import { useStyles } from './style';

/**
 * Brand item component
 *
 * @param {String} index    brand element index
 * @param {String} id       brand id
 * @param {String} image    brand image
 * @param {String} title    brand title
 *
 * @class
 */
const BrandItem = ({ index = null, id = null, image = null, title = null }) => {
	const classes = useStyles();

	if (!id && !title) return null;

	return (
		<Draggable draggableId={`attribute-${id}`} index={index}>
			{(provided) => (
				<li
					className={`flex pl-2sm ${classes.brandContainer}`}
					ref={provided.innerRef}
					{...provided.draggableProps}
				>
					<DragButton {...provided.dragHandleProps} />
					<Link to={`/brands/${id}`} className={`w-full flex items-center py-2sm pr-2sm`}>
						<ImageComponent data={image ? image : null} />
						<p className={`m-0 ${classes.brand__text}`}>{title}</p>
					</Link>
				</li>
			)}
		</Draggable>
	);
};

BrandItem.propTypes = {
	index: PropTypes.number,
	id: PropTypes.number,
	image: PropTypes.string,
	title: PropTypes.string,
};

export default React.memo(BrandItem);
