import { makeStyles } from '@material-ui/core/styles';

import { borderDefault, borderRadius, color_map } from '../../const/style';

export const createEditStyles = makeStyles({
	mainContainer: {
		marginTop: 0,
	},
	editContainer: {
		display: 'flex',
		flexDirection: 'column',
		backgroundColor: color_map.white,
		borderRadius: borderRadius,
		border: borderDefault,

		'& .ql-editor': {
			wordBreak: 'break-word',
			border: `1px solid ${color_map.control_gray}`,
			borderRadius: borderRadius,
			backgroundColor: color_map.white,
			color: color_map.black,
			padding: 12,
			fontSize: 14,
			lineHeight: 1.15,
			transition: 'all .2s ease',
			minHeight: 200,
			maxHeight: 1000,

			'&::after': {
				display: 'none',
			},
			'&:before': {
				display: 'none',
			},
			'&:focus': {
				backgroundColor: 'inherit',
				borderColor: color_map.blue,
			},
		},
		'& .ql-picker-options': {
			zIndex: '2 !important',
		},
	},
	pageHeader: {
		color: color_map.black,
		fontSize: 30,
		lineHeight: 1.2,
		fontWeight: 600,
		marginTop: 0,
		marginBottom: 4,
	},
	blockHeader: {
		color: color_map.black,
		fontSize: 24,
		lineHeight: 1.2,
		fontWeight: 600,
		marginTop: 12,
		marginBottom: 4,
	},

	dataBlock: {
		display: 'flex',
		flexDirection: 'column',
		marginTop: 12,

		'& img': {
			maxWidth: '100%',
		},
	},

	imagesBlock: {
		display: 'flex',
		alignItems: 'center',
		marginTop: 12,

		'& img': {
			maxWidth: 250,
			maxHeight: 250,
		},
		'& img:nth-child(n+2)': {
			marginLeft: 16,
		},
	},

	marginTopDefault: {
		marginTop: 12,
	},
});
