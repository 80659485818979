import { makeStyles } from '@material-ui/core/styles';

import { color_map, borderRadius } from '../../../const/style';

export const useStyles = makeStyles({
	textArea__title: {
		position: 'absolute',
		color: color_map.dark_gray,
		fontSize: 14,
		lineHeight: 1.2,
		top: 12,
		left: 9,
		backgroundColor: color_map.white,
		padding: '0 3px',
		transition: '.2s ease',
		transform: 'translate(0, 0) scale(1)',
	},
	textArea__title_open: {
		transform: 'translate(0, -21px) scale(0.75)',
	},

	textArea__input: {
		resize: 'none',
		padding: 12,
		marginTop: 0,
		borderRadius: borderRadius,
		border: `1px solid ${color_map.control_gray}`,
		transition: '.2s ease',
		outline: 'none !important',
		color: color_map.black,
		fontSize: 14,
		lineHeight: 1.2,
	},
	textArea__input_notFull: {
		height: 72 - 26,
	},

	textArea_maxLength: {
		marginTop: 3,
		marginBottom: 0,
		textAlign: 'end',
		fontSize: 12,
		lineHeight: 1.0,
		color: color_map.dark_gray,
	},
});
