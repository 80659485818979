import React from 'react';
import PropTypes from 'prop-types';

import { useStyles } from './style';

/**
 * Компонент "квадратик"
 *
 * @param {Function} action     функция обработки квадратика от родителя
 * @param {Object} data         данные для вывода в квадратик
 * @param {Boolean} disabled    disabled flag
 *
 * @class
 */
const Checkbox = ({ action = () => null, data = null, disabled = false }) => {
	const classes = useStyles();

	if (data) {
		return (
			<label className={classes.checkbox}>
				<input
					type={'checkbox'}
					name={data.title}
					className={classes.checkbox__input}
					onChange={(event) => action(data.id, event.target.checked)}
					checked={data.checked}
					disabled={!data.checked && disabled ? true : false}
				/>
				{data.title ? (
					<span
						className={
							!data.checked && disabled
								? `${classes.checkbox__text} ${classes.checkbox__text_disabled}`
								: classes.checkbox__text
						}
					>
						{data.title}
					</span>
				) : null}
			</label>
		);
	}
	return null;
};

Checkbox.propTypes = {
	action: PropTypes.func,
	data: PropTypes.object,
	disabled: PropTypes.bool,
};

export default Checkbox;
