import { makeStyles } from '@material-ui/core/styles';

export const useStyles = makeStyles({
	categoryItem__image: {
		maxWidth: '100%',
		maxHeight: '100%',
	},
	mainCategoryItem__imageContainer: {
		width: 60,
		height: 60,
		marginRight: 12,
		display: 'flex',
		alignItems: 'center',
		justifyContent: 'center',
	},
});
