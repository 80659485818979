import { makeStyles } from '@material-ui/core/styles';

export const useStyles = makeStyles({
	icoContainer: {
		display: 'flex',
		justifyContent: 'center',
	},

	ico: {
		userSelect: 'none',
		animation: 'ico_twisting 1s cubic-bezier(.7,.3,.3,.99) infinite',
		width: 30,
		height: 30,
	},
});
