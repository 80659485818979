import PropTypes from 'prop-types';
import { Draggable } from 'react-beautiful-dnd';

import DeleteButton from '../../../../Common/Interface/DeleteButton';
import DragButton from '../../../../Common/Interface/DragButton';

import { useStyles } from './style';

/**
 * @param {Object} product
 * @param {Function} delete_action
 *
 * @class
 */
const ComplementaryItem = ({
	index = null,
	product = null,

	delete_action = () => null,
}) => {
	const classes = useStyles();

	if (!product) return null;

	const { images, title, price, id } = product;

	return (
		<Draggable draggableId={`complementary-attribute-${id}`} index={index}>
			{(provided) => (
				<li
					className={`flex items-center py-2sm ${classes.product}`}
					ref={provided.innerRef}
					{...provided.draggableProps}>
					<DragButton {...provided.dragHandleProps} />
					<div className='flex items-center w-8/12'>
						{images ? (
							<img className={`${classes.image} mr-2sm`} src={images} alt={title} />
						) : null}
						<p className='h8'>{title}</p>
					</div>
					<div className='pl-2sm w-2/12 items-center'>
						<p className='h8'>{+price} руб.</p>
					</div>
					<div className='flex items-center pl-2sm w-2/12'>
						<DeleteButton action={delete_action} parameter_for_action={id} />
					</div>
				</li>
			)}
		</Draggable>
	);
};

ComplementaryItem.propTypes = {
	index: PropTypes.number,
	product: PropTypes.object,

	delete_action: PropTypes.func,
};

export default ComplementaryItem;
