import { makeStyles } from '@material-ui/core/styles';

import { borderDefault, color_map, borderRadius } from '../../../../const/style';

export const useStyles = makeStyles({
	imageBlock: {
		display: 'flex',
		padding: 12,
		borderBottom: borderDefault,
		backgroundColor: color_map.white,
		transition: '.2s ease',

		'&:is(:hover, :active)': {
			background: 'rgba(236, 236, 236, 0.9)',
			boxShadow: '0px 1px 5px rgba(0, 0, 0, 0.2)',
		},
	},

	imageContainer: {
		width: 250,
		marginRight: 12,
	},

	image__input: {
		width: 1,
		visibility: 'hidden',
	},

	image: {
		display: 'flex',
		position: 'relative',
		height: 250,
		alignItems: 'center',
		overflow: 'hidden',
		borderRadius: borderRadius,
		border: `1px solid ${color_map.control_gray}`,
	},

	image__show: {
		maxWidth: '100%',
	},
	image__button: {
		position: 'absolute',
		left: 0,
		right: 0,
		bottom: 0,
		top: '-100%',
		padding: 0,
		backgroundColor: 'transparent',
		border: 0,
		maxWidth: '100%',
		fontSize: 12,
		lineHeight: 1.2,
		height: '200%',
		width: '100%',
		opacity: '0.01',
		transition: '.5s ease',
		color: color_map.white,
		fontWeight: 'bold',
		mixBlendMode: 'difference',

		'&:is(:hover, :active)': {
			cursor: 'pointer',
			top: '-50%',
			opacity: 1,
		},
	},

	nameContainer: {
		display: 'flex',
		marginTop: 8,
		justifyContent: 'center',
	},
	name: {
		fontSize: 10,
		lineHeight: 1.2,
		margin: 0,
	},

	buttonContainer: {
		marginLeft: 'auto',
		display: 'flex',
		flexShrink: 0,
		width: 39,
	},
});
