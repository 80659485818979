import React from 'react';
import PropTypes from 'prop-types';

import Checkbox from '../../Checkbox';

import { useStyles } from './style';

/**
 * Компонент списка квадратиков
 *
 * @param {Array} data          данные квадратиков
 * @param {Number} level        уровень вложенности списка
 * @param {Function} action     функция обновления данных для квадратиков
 * @param {Boolean} disabled    disabled flag
 * @param {Number} current_id   id for exclude item from list (to hide the current record item)
 *
 * @class
 */
const InputList = ({
	data = [],
	level = 1,
	action = () => {},
	disabled = false,
	current_id = null,
}) => {
	const classes = useStyles();

	return (
		<div className={classes.checkboxList}>
			{data && data.length > 0
				? data.map((input, index) => {
						return (
							<React.Fragment key={index}>
								{!current_id || input.id !== current_id ? (
									<div
										className={classes.checkedContainer}
										style={{
											paddingLeft: 6 * +level,
											fontWeight: level === 1 ? 'bold' : '400',
										}}
									>
										<Checkbox
											data={input}
											action={action}
											disabled={disabled}
										/>
									</div>
								) : null}
								{input.sub_data && input.sub_data.length > 0 ? (
									<InputList
										data={input.sub_data}
										level={level + 1}
										action={action}
										disabled={disabled}
										current_id={current_id}
									/>
								) : null}
							</React.Fragment>
						);
				  })
				: null}
		</div>
	);
};

InputList.propTypes = {
	data: PropTypes.array,
	level: PropTypes.number,
	action: PropTypes.func,
	disabled: PropTypes.bool,
	current_id: PropTypes.number,
};

export default InputList;
