import { makeStyles } from '@material-ui/core/styles';

import { color_map } from '../../../../const/style';

export const useStyles = makeStyles({
	btnContainer: {
		marginRight: 12,
		display: 'flex',
		alignSelf: 'center',
		backgroundColor: 'transparent',
		position: 'relative',
		zIndex: 1,
	},

	btn: {
		border: 'none',
		alignSelf: 'center',
		padding: 0,
		width: 8,
		height: 10,
		backgroundColor: 'transparent',
		zIndex: 0,
		display: 'flex',

		'&:is(:hover, :active)': {
			cursor: 'grab',
		},

		'& svg rect': {
			transition: '.2s ease',
		},

		'&:is(:hover, :active) svg rect': {
			fill: color_map.blue,
			fillOpacity: 1,
		},
	},
});
