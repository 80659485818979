import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
// import {
//     // fetchEnd, fetchStart,
//     useNotify,
//     // useQuery
// } from 'react-admin';

import { attributes_data } from '../../../../../../data/attributes';
// import { apiUrl } from '../../../../dataProvider';

import { useStyles } from './style';

/**
 * Поле ввода для компонента атрибутов
 *
 * @param {Number} props.id                 `id` поля (для связи с `label`)
 * @param {String} props.value              значение поля
 * @param {String} props.placeholder        текст-название поля
 * @param {Boolean} props.attribute_name    флаг является ли поле значением или именем атрибута
 * @param {Function} props.change_value     функция для передачи введённых значений из поля родителю
 *
 * @memberof Attributes
 */
const Input = (props) => {
	const classes = useStyles();
	// const notify = useNotify();

	//значения поля
	const [value, change_value] = useState('');

	//активно ли поле (нужно для перемещения `label`)
	const [active, change_active] = useState(false);

	//в фокусе ли поле
	const [focus, change_focus] = useState(false);

	//данные атрибутов с сервера (нужны для связи названий атрибутов с таблицей данных (чтобы нельзя было вписывать какие угодно атрибуты))
	const [back_attributes_data, update_back_attributes_data] = useState(null);

	/**
	 * Запрос данных и первичное обновление атрибутов на основе данных записи
	 */
	useEffect(() => {
		if (props.attribute_name) _check_attributes_data();

		if (props.value) {
			change_value(props.value);
			change_active(true);
		}
	}, []);

	/**
	 * Запрос данных и первичное обновление атрибутов на основе данных записи
	 */
	useEffect(() => {
		change_value(props.value);
	}, [props.value]);

	/**
	 * При вводе новых значений в поле, вызываем заново список атрибутов с сервера
	 */
	useEffect(() => {
		if (props.attribute_name) _check_attributes_data();
	}, [value]);

	// const { data } = useQuery({
	//     type: 'getList',
	//     resource: 'product_attributes',
	//     // payload: { id: userId }
	// });

	// console.log(data);

	const _check_attributes_data = () => {
		if (value !== null) {
			// fetchStart(); // start the global loading indicator
			// fetch(`${apiUrl}/product_attributes?page=1`)
			// .then((res) => {
			//     console.log(res);
			// })
			// .catch((e) => {
			//     notify('Ошибка запроса атрибутов', { type: 'warning' })
			// })
			// .finally(() => {
			//     fetchEnd(); // stop the global loading indicator
			// });
			// const { data, loading, error } = useQuery({
			//     type: 'getList',
			//     resource: 'product_attributes',
			//     // payload: { id: userId }
			// });

			let new_data = attributes_data.filter((item) =>
				item.toLowerCase().includes(value.toLowerCase())
			);
			if (new_data.length > 20) new_data.length = 20;
			update_back_attributes_data(new_data);
		}
	};

	/**
	 * Обработка клика по атрибуту из предлагаемого списка
	 *
	 * @param {String} value выбранное значение
	 */
	const _list_click = (value) => {
		change_value(value);

		change_active(true);
	};

	//изменение класса `label` в зависимости от состояния компонента
	let label_class = `${classes.label}`;
	if (active) label_class += ` ${classes.label_active}`;
	if (focus) label_class += ` ${classes.label_focus}`;

	return (
		<div className={classes.inputContainer}>
			<label htmlFor={props.id} className={label_class}>
				{props.placeholder}
			</label>
			<input
				className={active ? `${classes.input} ${classes.input_active}` : classes.input}
				type='text'
				id={props.id}
				value={value === null || value === undefined ? '' : value}
				onChange={(e) => {
					if ((!props.attribute_name || e.target.value === '') && props.change_value)
						props.change_value(e.target.value);

					change_value(e.target.value);
				}}
				onFocus={() => {
					change_active(true);
					change_focus(true);
				}}
				onBlur={() => {
					if (!value) change_active(false);

					setTimeout(() => {
						change_focus(false);
					}, 200);
				}}
			/>
			{value && focus && props.attribute_name === true ? (
				<div className={classes.attributesListContainer}>
					{back_attributes_data && back_attributes_data.length > 0 ? (
						<ul className={classes.attributesList}>
							{back_attributes_data.map((attribute, index) => (
								<li
									className={classes.attributesList__item}
									key={index}
									onClick={() => {
										props.change_value(attribute);
										_list_click(attribute);
									}}
								>
									{attribute}
								</li>
							))}
						</ul>
					) : (
						<p className={classes.attributesListContainer__text}>Такого атрибута нет</p>
					)}
				</div>
			) : null}
		</div>
	);
};

Input.defaultProps = {
	id: null,
	value: null,
	placeholder: null,
	attribute_name: null,
	change_value: null,
};

Input.propTypes = {
	id: PropTypes.string,
	value: PropTypes.string,
	placeholder: PropTypes.string,
	attribute_name: PropTypes.bool,
	change_value: PropTypes.func,
};

export default Input;
