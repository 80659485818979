/**
 * Days end of the string processing
 *
 * @param {Number} number
 */
export const days_processing = (number = 0) => {
	if (number > 5 && number < 21) {
		return 'дней';
	} else {
		let last_number = number + '';
		last_number = +last_number[last_number.length - 1];

		if (last_number === 1) return 'день';
		else if (last_number > 1 && last_number < 5) return 'дня';
		else return 'дней';
	}
};
