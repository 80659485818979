import React from 'react';
import { withStyles } from '@material-ui/styles';
import PropTypes from 'prop-types';

import LoadingIco from '../../LoadingIco';

import { fetch_wrapper } from '../../../../wrappers/fetch_wrapper';
import { filter_sort } from '../../../../utils/products/filter_sort';
import { filter_to_server } from '../../../../data/products/filter';
import { API_URL } from '../../../../const/params';

import { styles } from './styles';

/**
 * Компонент фильтра с поиском
 */
class Filter extends React.Component {
	/**
	 * Блок поля поиска
	 */
	search_input = null;
	/**
	 * Блок-контейнер всего компонента
	 */
	filter_container = null;
	/**
	 * Контейнер с фильтром
	 */
	filter = null;
	/**
	 * Контейнер с выпадающим списком
	 */
	dropdown = null;
	/**
	 * Контейнер списка фильтров
	 */
	main_tags_container = null;
	/**
	 * Контейнер значений выбранного фильтра
	 */
	sub_tags_container = null;
	/**
	 * Контейнер с выбранными метками и полем поиска
	 */
	tag_list_element = null;

	/**
	 * @param {Function} props.update_filters   обратная функция обновления фильтров приложения
	 * @param {Object} props.filter_values      значения фильтров приложения
	 *
	 * @constructor
	 */
	constructor(props) {
		super(props);

		this.state = {
			/**
			 * Список фильтров для меток
			 *
			 * @type {Array}
			 */
			tags_data: [],
			/**
			 * Список значений для выбранного фильтра
			 * @param {String} parent    значение родителя
			 * @param {Array} data       список значений
			 *
			 * @type {Object}
			 */
			tag_list_data: {
				parent: null,
				data: [],
			},
			/**
			 * Список активных фильтров
			 *
			 * @type {Array}
			 */
			tags: [],

			/**
			 * Данные фильтров с сервера
			 *
			 * @type {Object}
			 */
			filters_data: null,

			/**
			 * Текущее значение поиска
			 *
			 * @type {String}
			 */
			input_value: '',

			/**
			 * Активное значение выпадающего списка (для работы стрелок)
			 *
			 * @type {Number}
			 */
			active_dropdown_index: null,
			/**
			 * Текущий выпадающий список (для работы стрелок)
			 *
			 * @type {String}
			 */
			active_input: null,

			/**
			 * Является ли поле поиска во внимании
			 *
			 * @type {Boolean}
			 */
			focus: false,
			/**
			 * Наведено ли на контейнер с метками
			 *
			 * @type {Boolean}
			 */
			search_hover: false,
			/**
			 * Активен ли выпадающий список
			 *
			 * @type {Boolean}
			 */
			active: false,

			/**
			 * Загружаются ли данные (для прокручивания снежинки)
			 *
			 * @type {Boolean}
			 */
			loading_filter_data: true,

			/**
			 * Запущена ли первичная синхронизация с фильтрами из приложения при монтировании
			 *
			 * @type {Boolean}
			 */
			sync_init: false,
		};

		this.add_tag = this.add_tag.bind(this);
		this.delete_tag = this.delete_tag.bind(this);
		this.toggle_tag = this.toggle_tag.bind(this);
		this.show_tag_data = this.show_tag_data.bind(this);
		this.check_active = this.check_active.bind(this);
		this.check_empty_tags = this.check_empty_tags.bind(this);
		this.check_tags = this.check_tags.bind(this);
		this.input_change_listener = this.input_change_listener.bind(this);
		this.focus_key_listener = this.focus_key_listener.bind(this);
		this.active_dropdown_key_listener = this.active_dropdown_key_listener.bind(this);
		this.enter_listener = this.enter_listener.bind(this);
		this.close_menu = this.close_menu.bind(this);
		this.blur_search_input = this.blur_search_input.bind(this);
		this.clear_input_value = this.clear_input_value.bind(this);
		this.left_arrow_listener = this.left_arrow_listener.bind(this);
		this.right_arrow_listener = this.right_arrow_listener.bind(this);
		this.check_window_width = this.check_window_width.bind(this);
		this.set_dropdown_position = this.set_dropdown_position.bind(this);
		this.scroll_filter = this.scroll_filter.bind(this);
		this.sync_tags_with_store = this.sync_tags_with_store.bind(this);
		this.set_dropdown_height = this.set_dropdown_height.bind(this);
		this.update_app_filter = this.update_app_filter.bind(this);

		this.search_input = React.createRef();
		this.filter_container = React.createRef();
		this.filter = React.createRef();
		this.dropdown = React.createRef();
		this.main_tags_container = React.createRef();
		this.sub_tags_container = React.createRef();
		this.tag_list_element = React.createRef();
	}

	componentDidMount() {
		// запускаем первичную проверку ширины контейнера с метками
		this.check_window_width();

		// вешаем слушателя ширины контейнера с метками, чтобы при изменении ширины происходил перерасчёт
		window.addEventListener('resize', this.check_window_width);

		// запрос данных фильтров с сервера
		fetch_wrapper(`${API_URL}/products/filters`)
			.then((res) => res.json())
			.then((res) => {
				this.setState({
					filters_data: res,
					loading_filter_data: false,
				});
			});
	}

	componentDidUpdate(prevProps, prevState) {
		const { filter_values } = this.props;
		const {
			active,
			focus,
			active_dropdown_index,
			tags_data,
			active_input,
			search_hover,
			sync_init,
			filters_data,
			tag_list_data,
		} = this.state;

		const body = document.body;

		// обработка выпадающего списка при изменении активного состояния
		if (prevState.active !== active) {
			// вызываем функцию проверки высоты
			this.set_dropdown_height();

			// если список активен, вешаем слушателей для обработки нажатия на клавиши и нажатие мыши (чтобы закрывать при нажатии вне контейнера меток)
			if (active) {
				body.addEventListener('click', this.check_active);
				body.addEventListener('keydown', this.active_dropdown_key_listener);
				// если список неактивен, убираем слушателей
			} else {
				body.removeEventListener('click', this.check_active);
				body.removeEventListener('keydown', this.active_dropdown_key_listener);
			}

			// вызываем функцию проверки положения списка
			this.set_dropdown_position();
		}

		// обработка нажатия `backspace` при внимании на поле поиска
		if (prevState.focus !== focus) {
			if (focus) body.addEventListener('keydown', this.focus_key_listener);
			else body.removeEventListener('keydown', this.focus_key_listener);
		}

		// обработка при изменении активного значения в выпадающем списке
		if (prevState.active_dropdown_index !== active_dropdown_index) {
			const dropdown_elements = document.getElementsByClassName('filter-item');

			// удаляем активное состояние предыдущего значения
			if (
				dropdown_elements &&
				dropdown_elements[prevState.active_dropdown_index] &&
				prevState.active_dropdown_index !== null
			) {
				dropdown_elements[prevState.active_dropdown_index].classList.remove(
					'filter-item_active'
				);
			}

			if (
				dropdown_elements &&
				dropdown_elements[active_dropdown_index] &&
				active_dropdown_index !== null
			) {
				const parent_element = dropdown_elements[active_dropdown_index].offsetParent;

				const offset_top = dropdown_elements[active_dropdown_index].offsetTop,
					container_height = parent_element.offsetHeight,
					block_height = dropdown_elements[active_dropdown_index].offsetHeight,
					scroll_top = parent_element.scrollTop,
					scroll_height = parent_element.scrollHeight,
					padding = 6;

				// добавляем активное состояние текущего значения
				dropdown_elements[active_dropdown_index].classList.add('filter-item_active');

				// высчитыванием положение активного элемента при переключении на стрелках
				if (scroll_height > 0) {
					if (offset_top < scroll_top) {
						parent_element.scrollTop = scroll_top - block_height - padding;
					} else if (scroll_top < offset_top - container_height + block_height) {
						parent_element.scrollTop =
							offset_top - container_height + block_height + padding;
					}
				}
			}
		}

		// вызываем высчитывание положения выпадающего списка при открытии
		if (prevState.active_input !== active_input) this.set_dropdown_position();

		// обработка наведения на контейнер со списком меток
		if (prevState.search_hover !== search_hover) {
			if (search_hover) this.disable_page_scroll_event();
			else this.enable_page_scroll_event();
		}

		// синхронизация фильтров в filterValues с данными фильтров RA
		if (
			filter_values &&
			(JSON.stringify(filter_values) !== JSON.stringify(prevProps.filter_values) ||
				sync_init === false) &&
			filters_data &&
			tags_data.length > 0
		) {
			this.sync_tags_with_store();
		}

		// при открытии списка вызываем пересчёт высоты
		if (active && prevState.tag_list_data.parent !== tag_list_data.parent)
			this.set_dropdown_height();

		// первичное построение списка для меток из статического `data` списка
		if (prevState.filters_data === null && filters_data !== null) {
			const new_tags_data = [];

			// проходим по статическому массиву и собираем список для меток
			if (typeof filters_data === 'object' && !Array.isArray(filters_data)) {
				for (let filter_group in filters_data) {
					filters_data[filter_group].forEach((filter) => {
						const new_options = [];

						for (let option in filter.values) {
							new_options.push({
								id: option,
								value: filter.values[option],
								checked: false,
								name: filter_to_server[filter.id]
									? filter_to_server[filter.id]
									: filter.id,
							});
						}

						new_tags_data.push({
							checked: false,
							name: filter_to_server[filter.id]
								? filter_to_server[filter.id]
								: filter.id,
							value: filter.title,
							data: new_options,
						});
					});
				}
			}

			new_tags_data.sort(filter_sort);

			// отправляем сформированный список меток
			this.setState({
				tags_data: new_tags_data,
			});
		}
	}

	componentWillUnmount() {
		// возвращаем значения по-умолчанию и убираем обработку

		this.enable_page_scroll_event();

		const body = document.body;

		window.removeEventListener('resize', this.check_window_width);
		body.removeEventListener('click', this.check_active);
		body.removeEventListener('keydown', this.active_dropdown_key_listener);
		body.removeEventListener('keydown', this.focus_key_listener);
	}

	/**
	 * Высчитывание высоты блока при открытии блока (пока не работает)
	 */
	set_dropdown_height = () => {
		// const dropdown_element = this.dropdown.current;
		// if (dropdown_element) {
		// }
		// console.log(dropdown_element);
	};

	/**
	 * Запрет прокрутки страницы при наведении на контейнер со списком меток
	 */
	disable_page_scroll_event = () => {
		const TopScroll = window.pageYOffset || document.documentElement.scrollTop,
			LeftScroll = window.pageXOffset || document.documentElement.scrollLeft;

		const body = document.body;

		// запрет прокрутки страницы
		body.style.overflow = 'hidden';

		// костыль для `Safari`, чтобы при прокрутке (которая не блокируется в этом браузере) прокручивать обратно к исходному положению
		window.onscroll = () => {
			window.scrollTo(LeftScroll, TopScroll);
		};
	};

	/**
	 * Включение прокрутки страницы
	 */
	enable_page_scroll_event = () => {
		const body = document.body;

		body.style.overflow = '';

		// костыль для `Safari`
		window.onscroll = () => {};
	};

	/**
	 * Метод установки положения выпадающего списка
	 */
	set_dropdown_position = () => {
		let dropdown_element = null;

		const { active_input } = this.state;

		// устанавливаем элемент контейнера по-умолчанию
		if (this.dropdown.current) dropdown_element = this.dropdown.current;

		const tag_elements = document.getElementsByClassName('tag-container');

		if (dropdown_element) {
			let needed_element = null;

			// если во внимании поиск, то берём другой блок
			if (active_input === 'search') {
				if (this.search_input.current) needed_element = this.search_input.current;
				// проверяем, чтобы активный фильтр существовал
			} else if (active_input !== null) {
				// ищем нужный блок
				for (let i = 0; i < tag_elements.length; i++) {
					if (
						tag_elements[i].attributes.data_name.nodeValue === active_input &&
						i < tag_elements.length
					) {
						needed_element = tag_elements[i];
						break;
					}
				}
			}

			// высчитыванием положение слева
			if (needed_element) {
				needed_element = needed_element.parentElement;

				const tag_list_element = this.tag_list_element.current;

				// высчитываем положение выпадающего списка относительно текущей метки
				// offset left + form paddings/margins - tag padding - current scroll
				let left_pos = needed_element.offsetLeft + 20 - 6 - tag_list_element.scrollLeft;

				// если метка за границей экрана, то устанавливаем в начальную позицию (она 20px)
				if (left_pos < 20) left_pos = 20;

				// устанавливаем положение блока
				dropdown_element.style.left = `${left_pos}px`;
			}
		}
	};

	/**
	 * Метод проверки ширины окна
	 */
	check_window_width = () => {
		const header = document.getElementsByTagName('header')[0];

		// высчитываем ширину контейнера со списком фильтром
		// header width - main padding - left menu width - content margin left - content padding - filter border width
		const filter_max_width = header.clientWidth - 12 * 2 - 250 - 12 - 12 * 2 - 2;

		// устанавливаем ширину окна
		this.filter.current.style.maxWidth = filter_max_width + 'px';
	};

	/**
	 * Метод прокрутки списка фильтров при добавлении/удалении меток
	 * @param {Element} element     контейнер для прокрутки
	 */
	scroll_filter = (element) => {
		setTimeout(() => {
			const tag_list_element = this.tag_list_element.current;

			const { active_input } = this.state;

			let left_max = tag_list_element.scrollLeftMax;

			// костыль для WebKit, чтобы высчитывать положение без удобного свойства
			if (!tag_list_element.scrollLeftMax)
				left_max = tag_list_element.scrollWidth - tag_list_element.clientWidth;

			// проверяем на контейнер для прокрутки
			if (!element) {
				if (tag_list_element.scrollLeft !== left_max)
					tag_list_element.scrollLeft = left_max;
			} else if (tag_list_element.scrollLeft > element.offsetLeft - 7) {
				tag_list_element.scrollLeft = element.offsetLeft - 7;
			} else if (
				tag_list_element.clientWidth + tag_list_element.scrollLeft <
				element.offsetLeft + element.clientWidth - 7
			) {
				tag_list_element.scrollLeft = element.offsetLeft - 7;
			}

			if (active_input !== 'search') this.set_dropdown_position();
		}, 4);
	};

	/**
	 * Метод прослушивания прокрутки колеса мыши для прокручивания контейнера
	 * @param {Event} event     событие прокрутки
	 */
	mouse_scroll_listener = (event) => {
		const element = this.tag_list_element.current;

		// шаг прокрутки
		const scroll_step = 20;

		let left_max = element.scrollLeftMax;

		// костыль для WebKit, чтобы высчитывать положение без удобного свойства
		if (!element.scrollLeftMax) left_max = element.scrollWidth - element.clientWidth;

		// прокручиваем список
		for (let i = 0; i < scroll_step; i++) {
			// mouse up
			if (event.deltaY < 0 && element.scrollLeft - 1 > 0) {
				element.scrollLeft -= 1;
				// mouse down
			} else if (element.scrollLeft + 1 < left_max) {
				element.scrollLeft += 1;
			}
		}
	};

	/**
	 * Метод добавления нового фильтра
	 * @param {Event} event     вызывающее событие
	 * @param {String} type     тип для метода (фильтр или значение фильтра)
	 */
	add_tag = (event, type) => {
		this.toggle_tag(event.target, type, 'add');
	};

	/**
	 * Метод удаления нового фильтра
	 * @param {Event} event     вызывающее событие
	 * @param {String} type     тип для метода (фильтр или значение фильтра)
	 */
	delete_tag = (event, type) => {
		this.toggle_tag(event.target, type, 'delete');
	};

	/**
	 * Универсальный метод переключения фильтра
	 * @param {Element} element     вызывающий событие элемент
	 * @param {String} type         тип для метода (фильтр или значение фильтра)
	 * @param {String} action       тип переключения (удаление или добавление)
	 */
	toggle_tag = (element, type, action) => {
		const { tags, tags_data, tag_list_data, input_value, filters_data } = this.state;

		// убираем внимание с поля поиска
		this.blur_search_input();

		// удаляем текущее значение поиска
		if (input_value !== '') {
			this.setState({
				input_value: '',
			});
		}

		// копируем все данные меток для будущих изменений
		let updated_tags = JSON.parse(JSON.stringify(tags));
		let updated_tag_list_data = JSON.parse(JSON.stringify(tag_list_data));
		let updated_tags_data = JSON.parse(JSON.stringify(tags_data));

		// создаём объект новой метки
		let tag_object = Object.create(null);

		// если тип переключения "фильтр"
		if (type === 'title' && updated_tags) {
			// составляем первичные данные
			tag_object.name = element.attributes.data_value.nodeValue;
			tag_object.title = element.textContent;
			tag_object.data = [];

			// для динамического фильтра нужно брать другое поле для списка фильтров
			const name_in_filters = element.attributes.data_value_in_filters
				? element.attributes.data_value_in_filters.nodeValue
				: null;

			// проверка на правильность работы
			if (tag_object.name) {
				// отправляем первичные данные
				updated_tags.push(tag_object);

				// ищем нужные значения для выбранного фильтра
				for (let i = 0; i < tags_data.length; i++) {
					if (tags_data[i].name === tag_object.name) {
						this.setState({
							tag_list_data: {
								parent: tag_object.name,
								data: tags_data[i].data.sort(filter_sort),
							},
						});
					}
				}
			}
			// если тип переключения "значение"
		} else if (
			type === 'value' &&
			element.attributes.data_value.nodeValue !== 'search_filter'
		) {
			// составляем первичные данные
			tag_object.value = element.attributes.data_value.nodeValue;
			tag_object.parent = element.attributes.data_parent.nodeValue;
			tag_object.title = element.textContent;

			// отмечаем нужное значение в списке меток
			updated_tags = updated_tags.map((tags) => {
				if (tags.name === tag_object.parent) {
					// флаг проверки удаления/добавления
					let data_checked = false;

					// проверяем текущий список меток
					for (let i = 0; i < tags.data.length; i++) {
						// если такое значение есть, нужно удалить
						if (tags.data[i].value === tag_object.value) {
							data_checked = true;

							tags.data.splice(i, 1);

							break;
						}
					}

					// если значения нет, добавить
					if (!data_checked) tags.data.push(tag_object);
				}

				return tags;
			});

			// изменяем открытый список значений (чтобы визуально отмечался флажок)
			if (updated_tag_list_data.data) {
				updated_tag_list_data.data = updated_tag_list_data.data
					.map((tag) => {
						if (tag.name === tag_object.parent && tag.id === tag_object.value) {
							if (action === 'add') tag.checked = true;
							else if (action === 'delete') tag.checked = false;
						}

						return tag;
					})
					.sort(filter_sort);
			}

			// изменяем отмеченные/убранные фильтры/значения фильтров в данных компонента
			updated_tags_data = updated_tags_data
				.map((parent_tag) => {
					if (parent_tag.name === tag_object.parent) {
						if (parent_tag.data && parent_tag.data.length > 0) {
							parent_tag.data = parent_tag.data
								.map((tag) => {
									if (tag.id === tag_object.value) {
										if (action === 'add') tag.checked = true;
										else if (action === 'delete') tag.checked = false;
									}

									return tag;
								})
								.sort(filter_sort);
						}
					}

					return parent_tag;
				})
				.sort(filter_sort);
			// } else if (type === 'value' && element.attributes.data_value.nodeValue === 'search_filter') {
			//     tag_object.value = this.search_input.current.value;
			//     tag_object.parent = element.attributes.data_parent.nodeValue;
			//     tag_object.title = element.textContent;

			//     setTimeout(() => {
			//         this.search_input.current.focus();
			//     }, 4)
		}

		// если изменился список значений, то отправляем все изменённые данные
		if (updated_tag_list_data.data.length > 0) {
			this.setState({
				tags: updated_tags,
				tag_list_data: updated_tag_list_data,
				tags_data: updated_tags_data.sort(filter_sort),
			});
			// если только список меток, то отправляем его
		} else {
			this.setState({
				tags: updated_tags,
			});
		}

		if (element.attributes.data_parent) {
			// ищем родительскую метку в списке, чтобы прокрутить до неё в контейнере меток
			let scroll_tag_element = document.querySelector(
				`.tag-container[data_name="${element.attributes.data_parent.nodeValue}"]`
			);
			if (scroll_tag_element) scroll_tag_element = scroll_tag_element.parentElement;

			// вызываем прокрутку списка
			this.scroll_filter(scroll_tag_element);
		}
	};

	/**
	 * Обратная функция отправки фильтров в `RA` для запроса к серверу
	 */
	update_app_filter = () => {
		const { tags, input_value } = this.state;
		const { update_filters } = this.props;

		// проверяем пустые метки
		this.check_empty_tags();

		// устанавливаем значение фильтров
		const app_filters = {
			filters: JSON.parse(JSON.stringify(tags)),
		};

		// устанавливаем значение поиска
		if (input_value) app_filters.search = encodeURIComponent(input_value);

		// отправляем значение
		update_filters(app_filters);

		// убираем внимание с поля поиска
		this.blur_search_input();

		// убираем активное состояние
		this.setState({
			active: false,
		});
	};

	/**
	 * Метод обработки изменения поля поиска
	 * @param {Event} event     вызывающее событие (изменение ввода поиска)
	 */
	input_change_listener = (event) => {
		const element = event.target;

		// отправляем значение поиска в локальное состояние
		this.setState({
			input_value: element.value,
		});

		// прокручиваем контейнер меток
		this.scroll_filter();

		// с небольшой задержкой вызываем высчитываем позицию выпадающего блока (чтобы он появился)
		setTimeout(() => {
			this.set_dropdown_position();
		}, 4);
	};

	/**
	 * Функция изменения поиска (скрытая отдельная функция обработки; пока не удаляю на случай включения кнопки "Искать в названии")
	 * @param {Event} event
	 */
	// change_search = () => {
	//     const { tags } = this.state

	//     let new_tags = JSON.parse(JSON.stringify(tags));

	//     const search_tag = {
	//         data: [
	//             {

	//             }
	//         ],
	//         name: 'search_filter',
	//         title: ''
	//     }
	// }

	/**
	 * Метод-слушатель обработки удаления меток при пустом значении поиска
	 * @param {Event} event     вызывающее событие
	 */
	focus_key_listener = (event) => {
		const { input_value, tags } = this.state;

		switch (+event.keyCode) {
			// backspace
			case 8:
				// проверяем на то, что поле пустое
				if (input_value !== null && input_value === '' && tags.length > 0) {
					event.preventDefault();

					// удаляем последнюю метку
					let new_tags = JSON.parse(JSON.stringify(tags));
					new_tags.splice(tags.length - 1, 1);

					this.setState({
						tags: new_tags,
					});

					// вызываем проверку меток
					this.check_tags();

					this.set_dropdown_position();
				}

				break;
			default:
				break;
		}
	};

	/**
	 * Метод-слушатель нажатия кнопок клавиатуры для работы с метками (переключения, применение других)
	 * @param {Event} event
	 */
	active_dropdown_key_listener = (event) => {
		const { active_dropdown_index, focus, active_input } = this.state;

		let dropdown_elements = [],
			container = null;

		// выбираем нужный контейнер
		if (this.sub_tags_container.current) container = this.sub_tags_container.current;
		else if (this.main_tags_container.current) container = this.main_tags_container.current;

		// если контейнер определён, выбираем сам список значений
		if (container) dropdown_elements = container.childNodes;

		let next_index = active_dropdown_index;

		// обрабатываем разные кнопки
		switch (+event.keyCode) {
			// enter, обновляем фильтр/значение
			case 13:
				if (focus) this.update_app_filter();
				else this.enter_listener();

				this.blur_search_input();

				break;
			// esc, закрываем список
			case 27:
				this.close_menu();
				this.blur_search_input();

				break;
			// arrow left, вызываем функцию обработки левой стрелки
			case 37:
				if (this.search_input.current.selectionStart === 0) {
					event.preventDefault();

					this.left_arrow_listener();
				}

				break;
			// arrow right, вызываем функцию обработки правой стрелки
			case 39:
				if (active_input !== 'search') {
					event.preventDefault();

					this.right_arrow_listener();
				}

				break;
			// arrow up, переключаем активное значение вверх (в самом начале и конце есть промежуточное значение `null`)
			case 38:
				event.preventDefault();

				this.blur_search_input();

				if (active_dropdown_index === null) next_index = dropdown_elements.length - 1;
				else if (+active_dropdown_index === 0) next_index = null;
				else next_index -= 1;

				this.setState({
					active_dropdown_index: next_index,
				});
				break;
			// arrow down, переключаем активное значение вниз (в самом начале и конце есть промежуточное значение `null`)
			case 40:
				event.preventDefault();

				this.blur_search_input();

				if (active_dropdown_index === null) next_index = 0;
				else if (+active_dropdown_index === dropdown_elements.length - 1) next_index = null;
				else next_index += 1;

				this.setState({
					active_dropdown_index: next_index,
				});
				break;
			default:
				break;
		}
	};

	/**
	 * Метод обработки левой стрелки
	 */
	left_arrow_listener = () => {
		const { active_input, tags_data } = this.state;

		// берём список элементов с метками
		const tag_elements = document.getElementsByClassName('tag-container');

		if (tag_elements.length > 0) {
			let needed_element = null,
				needed_tag = null;

			// в любом случае нужно будет уйти из поля поиска
			this.blur_search_input();

			// если текущий активный ввод "поиск", то смещаемся на последнюю метку
			if (active_input === 'search') needed_element = tag_elements[tag_elements.length - 1];
			else if (active_input !== null) {
				// ищем активную метку и берём предыдущее значение
				for (let i = 0; i < tag_elements.length; i++) {
					if (tag_elements[i].attributes.data_name.nodeValue === active_input && i > 0) {
						needed_element = tag_elements[i - 1];

						// прокручиваем список (для того, чтобы нужный элемент всегда был в зоне видимости)
						this.scroll_filter(needed_element.parentElement);
					}
				}
			}

			// если элемент найден, то ищем нужную метку в списке
			if (needed_element)
				needed_tag = tags_data.find(
					(item) => item.name === needed_element.attributes.data_name.nodeValue
				);

			// если метка найдена, открываем его список значений
			if (needed_tag) {
				// проверяем на пустые метки (на случай, если в текущей метке значения были удалены)
				this.check_empty_tags();

				this.setState({
					tag_list_data: {
						parent: needed_tag.name,
						data: needed_tag.data.sort(filter_sort),
					},
					active: true,
					active_input: needed_tag.name,
				});
			}
		}
	};

	/**
	 * Метод обработки правой стрелки
	 */
	right_arrow_listener = () => {
		const { active_input, tags_data } = this.state;

		// берём список элементов с метками
		const tag_elements = document.getElementsByClassName('tag-container');

		if (tag_elements.length > 0) {
			let needed_element = null,
				needed_tag = null;

			// в любом случае нужно будет уйти из поля поиска
			this.blur_search_input();

			if (active_input !== null) {
				// ищем активную метку и берём следующее значение
				for (let i = 0; i < tag_elements.length; i++) {
					if (
						tag_elements[i].attributes.data_name.nodeValue === active_input &&
						i < tag_elements.length
					) {
						needed_element = tag_elements[i + 1];

						// прокручиваем список (для того, чтобы нужный элемент всегда был в зоне видимости)
						if (needed_element) this.scroll_filter(needed_element.parentElement);
					}
				}
			}

			// если элемент найден, то ищем нужную метку в списке
			if (needed_element)
				needed_tag = tags_data.find(
					(item) => item.name === needed_element.attributes.data_name.nodeValue
				);

			// если метка найдена, открываем его список значений
			if (needed_tag) {
				// проверяем на пустые метки (на случай, если в текущей метке значения были удалены)
				this.check_empty_tags();

				this.setState({
					tag_list_data: {
						parent: needed_tag.name,
						data: needed_tag.data.sort(filter_sort),
					},
					active: true,
					active_input: needed_tag.name,
				});
				// если метка не найдена, значит это последняя метка и нужно перейти в поле поиска
			} else {
				// проверяем на пустые метки (на случай, если в текущей метке значения были удалены)
				this.check_empty_tags();

				this.setState({
					active_input: 'search',
				});

				this.close_menu();

				if (this.search_input.current) this.search_input.current.focus();
			}
		}
	};

	/**
	 * Метод очистки значений поля
	 */
	clear_input_value = () => {
		if (this.search_input.current) this.search_input.current.value = '';
	};

	/**
	 * Метод обработки `Enter`
	 */
	enter_listener = () => {
		const { active_dropdown_index } = this.state;

		// берём список элементов-значений
		const dropdown_elements = document.getElementsByClassName('filter-item');

		// из этого списка берём активный элемент
		const needed_element = dropdown_elements[active_dropdown_index];

		if (dropdown_elements && needed_element && active_dropdown_index !== null) {
			// флаг типа действия с элементом
			let event = 'add';

			// если тип элемента "значение"
			if (needed_element.attributes.data_type.nodeValue === 'value') {
				// если элемент был выбран
				if (needed_element.attributes.data_checked.nodeValue === 'true') {
					event = 'delete';
				}
			}

			// отправляем изменение метки
			this.toggle_tag(needed_element, needed_element.attributes.data_type.nodeValue, event);

			// убираем активное значение из списка
			this.setState({
				active_dropdown_index: null,
			});
		}
	};

	/**
	 * Метод убирания внимания с поля поиска
	 */
	blur_search_input = () => {
		if (this.search_input.current) this.search_input.current.blur();
	};

	/**
	 * Метод закрытия списка
	 */
	close_menu = () => {
		this.setState({
			active_dropdown_index: null,
			active: false,
			tag_list_data: {
				parent: null,
				data: [],
			},
		});

		// после этого всегда вызываем проверку на метки фильтров без значений
		this.check_empty_tags();
	};

	/**
	 * Метод открытия выпадающего списка со значениями при нажатии на метку
	 * @param {Event} event     вызывающее событие (кнопка мыши)
	 */
	show_tag_data = (event) => {
		let element = event.target;

		// вызываем проверку на метки фильтров без значений
		this.check_empty_tags();

		// двойная проверка, так как событие не всегда вызывается из нужного блока (иногда из его ребёнка), поэтому тут проверка на второй уровень вложенности
		if (!element.attributes.data_name) element = element.parentElement;
		if (!element.attributes.data_name) element = element.parentElement;

		const { tags_data } = this.state;

		// дополнительная проверка, чтобы обработка не вызывала ошибку
		if (element.attributes.data_name) {
			let data = [];

			// ищем нужный список значений
			for (let i = 0; i < tags_data.length; i++) {
				if (tags_data[i].name === element.attributes.data_name.nodeValue) {
					data = tags_data[i].data;
					break;
				}
			}

			// отправляем список для показа значений
			if (data && data.length > 0) {
				this.setState({
					tag_list_data: {
						parent: element.attributes.data_name.nodeValue,
						data: data.sort(filter_sort),
					},
					active: true,
					active_input: element.attributes.data_name.nodeValue,
				});
			}
		}

		// вызываем прокрутку списка
		this.scroll_filter(element.parentElement);
	};

	/**
	 * Проверка на то, активно ли выпадающее окно
	 * @param {Event} event     вызывающее событие (кнопка мыши)
	 */
	check_active = (event) => {
		const element = event.target;
		const { active } = this.state;

		const body = document.body;

		let main_container = null;

		// определяем проверяемый контейнер
		if (this.filter_container.current) main_container = this.filter_container.current;

		// проверяем на то, попадает ли вызывающий элемент внутрь проверяемого контейнера
		if (
			((element.attributes.data_type && element.attributes.data_type.nodeValue !== 'title') ||
				!element.attributes.data_type) &&
			!main_container.contains(element) &&
			active
		) {
			// убираем все активные значения
			this.disable_all_active_filter_items();

			// проверяем пустые метки
			this.check_empty_tags();

			// убираем активное значение
			this.setState({
				active: false,
				tag_list_data: {
					parent: null,
					data: [],
				},
			});

			// удаляем слушателя
			body.removeEventListener('click', this.check_active);
		}

		// удаляем слушателя на случай, если он не был удалён (чтобы не дублировать)
		if (!active) body.removeEventListener('click', this.check_active);
	};

	/**
	 * Метод выключения всех активных значений в списке
	 */
	disable_all_active_filter_items = () => {
		const dropdown_elements = document.getElementsByClassName('filter-item');

		if (dropdown_elements && dropdown_elements.length > 0) {
			// проходим по списку значений и убираем активное состояние
			for (let i = 0; i < dropdown_elements.length; i++) {
				dropdown_elements[i].style.backgroundColor = '';
			}
		}
	};

	/**
	 * Метод синхронизации меток и данных фильтров из `RA`
	 */
	sync_tags_with_store = () => {
		const { filter_values } = this.props;
		const { tags_data, filters_data } = this.state;

		let new_tags = [],
			new_tags_data = [],
			search = '';

		// проходим по массиву данных для меток
		tags_data.forEach((tag) => {
			let new_tag = JSON.parse(JSON.stringify(tag));

			// если нужных для работы данных нет, то достраиваем их
			if (
				filters_data[tag.name_in_filters] &&
				tag.data === undefined &&
				filters_data[tag.name_in_filters].length > 0
			) {
				new_tag.data = filters_data[tag.name_in_filters].map((value) => {
					value.value = value.title;
					value.checked = false;
					value.id = value.id + '';
					value.name = tag.name;

					// delete value.title;

					return value;
				});
			}

			// отправляем дополненные данные
			new_tags_data.push(new_tag);
		});

		// проходим по объекту фильтров из `RA`
		for (const key in filter_values) {
			let new_tag = Object.create(null);

			// если значение не поиск, то добавляем его как метку
			if (key !== 'search') {
				// проходим по данным меток и ищем нужную метку
				new_tags_data.forEach((tag) => {
					if (tag.name === key) {
						// составляем метку
						new_tag.name = tag.name;
						new_tag.title = tag.value;
						new_tag.data = [];

						// составляем значения для метки
						tag.data &&
							tag.data.forEach((value) => {
								for (let i = 0; i < filter_values[key].length; i++) {
									if (value.id === filter_values[key][i]) {
										new_tag.data.push({
											parent: tag.name,
											title: value.value,
											value: value.id,
										});
									}
								}
							});
					}
				});
				// если значение поиск, то расшифровываем значение
			} else search = decodeURIComponent(filter_values[key]);

			// отправляем метки в новое значение
			if (new_tag.data) new_tags.push(new_tag);
		}

		// обновляем состояние с метками
		if (new_tags.length > 0) {
			this.setState({
				tags: new_tags,
				tags_data: new_tags_data.sort(filter_sort),
				input_value: search,
			});

			// if (this.search_input.current) this.search_input.current.value = search

			setTimeout(() => {
				this.check_tags();
			}, 4);
		}

		this.setState({
			sync_init: true,
		});
	};

	/**
	 * Метод проверки на пустые метки (без значений)
	 */
	check_empty_tags = () => {
		const { tags, focus } = this.state;

		let new_tags = [];
		// проверяем метки на то, пустые они или нет
		tags.forEach((tag) => {
			if (tag.data.length > 0) new_tags.push(tag);
		});

		// отправляем только метки со значениями
		this.setState({
			tags: new_tags,
			tag_list_data: {
				parent: null,
				data: [],
			},
		});

		if (!focus) this.blur_search_input();

		// вызываем проверку данных меток
		this.check_tags();
	};

	/**
	 * Метод проверки данных тегов и их значений (на то, отмечены они или нет согласно реальным данным)
	 */
	check_tags = () => {
		const { tags_data, tags } = this.state;

		let new_tags_data = JSON.parse(JSON.stringify(tags_data));

		// проходим по массиву данных меток
		new_tags_data = new_tags_data.map((tag_data) => {
			// исключаем из проверки метки поиска
			if (tag_data.name !== 'search') {
				let checked = false;

				// проходим по массиву выбранных меток и проверяем
				tags.forEach((tag) => {
					if (tag.name === tag_data.name) checked = true;
				});

				// если значение фильтра не было выбрано, от отмечаем
				if (checked === false && tag_data.checked === true) {
					tag_data.data = tag_data.data.map((tag_value) => {
						tag_value.checked = false;

						return tag_value;
					});
					// если значение фильтра было выбрано, то убираем (и проходим по его значениям, чтобы убрать там отметки тоже)
				} else if (checked === true) {
					tag_data.data = tag_data.data.map((tag_value) => {
						tags.forEach((current_tag) => {
							if (current_tag.name === tag_data.name) {
								current_tag.data.forEach((current_value) => {
									if (current_value.value === tag_value.id)
										tag_value.checked = true;
								});
							}
						});

						return tag_value;
					});
				}

				tag_data.checked = checked;
			}

			return tag_data;
		});

		// отправляем новые значения меток
		this.setState({
			tags_data: new_tags_data.sort(filter_sort),
		});
	};

	render() {
		const { classes } = this.props;
		const {
			focus,
			tags,
			tag_list_data,
			tags_data,
			active,
			input_value,
			loading_filter_data,
			active_input,
		} = this.state;

		let filter_class = classes.filter,
			dropdown_class = `${classes.dropdownContainer} ${classes.dropdownContainer_active}`;

		// меняем значение класса для фильтра
		if (focus) filter_class += ` ${classes.filter_active}`;

		return (
			<div className={classes.filterContainer} ref={this.filter_container}>
				<div
					className={filter_class}
					ref={this.filter}
					onMouseEnter={() =>
						this.setState({
							search_hover: true,
						})
					}
					onMouseLeave={() =>
						this.setState({
							search_hover: false,
						})
					}
				>
					<ul
						className={classes.tagList}
						ref={this.tag_list_element}
						onWheel={(e) => this.mouse_scroll_listener(e)}
					>
						{tags.map((tag, tag_index) => (
							<li key={tag_index} className={classes.tagContainer}>
								<div
									role='button'
									className={`${classes.tag} tag-container`}
									onClick={(e) => {
										if (active && tag.name === tag_list_data.parent)
											this.close_menu();
										else this.show_tag_data(e);
									}}
									data_name={tag.name}
								>
									<div className={classes.tag__title}>{tag.title}</div>
									{tag.data
										? tag.data.map((tag_item, item_index) => {
												let tag_class = `${classes.tagItem} tag`;

												return (
													<div key={item_index} className={tag_class}>
														{tag_item.title}
													</div>
												);
										  })
										: null}
								</div>
							</li>
						))}
						<li className={`${classes.tagContainer} ${classes.tagContainer_input}`}>
							<input
								className={classes.textInput}
								name='search'
								type='text'
								value={input_value}
								ref={this.search_input}
								placeholder={'Поиск'}
								onClick={() => {
									this.check_empty_tags();
									this.setState({
										tag_list_data: {
											parent: null,
											data: [],
										},
										active_input: 'search',
									});
									setTimeout(() => {
										this.check_tags();
										this.set_dropdown_position();
									}, 4);
								}}
								onChange={(e) => this.input_change_listener(e)}
								onFocus={() => {
									this.setState({
										focus: true,
										active: true,
										active_input: 'search',
									});
									this.check_tags();
								}}
								onBlur={() => {
									this.setState({
										focus: false,
									});
								}}
								autoComplete={'off'}
							/>
						</li>
					</ul>
					<button
						type='button'
						className={classes.searchButton}
						onClick={() => this.update_app_filter()}
					>
						<svg
							width='24'
							height='24'
							viewBox='0 0 24 24'
							fill='none'
							xmlns='http://www.w3.org/2000/svg'
						>
							<path
								fillRule='evenodd'
								clipRule='evenodd'
								d='M17.2047 10.1012C17.2047 14.024 14.024 17.2025 10.1023 17.2025C6.17958 17.2025 3 14.024 3 10.1012C3 6.1785 6.17958 3 10.1023 3C14.024 3 17.2047 6.1785 17.2047 10.1012Z'
								strokeWidth='2'
							/>
							<path d='M15 15L20.4391 20.4398' strokeWidth='2' />
						</svg>
					</button>
				</div>
				{/* {
                    total ?
                    <p className={classes.filterTotal}>{total} товаров</p> : null
                } */}
				{active && (input_value.length === 0 || active_input !== 'search') ? (
					<div className={dropdown_class} ref={this.dropdown}>
						{loading_filter_data ? (
							<div className={classes.dropdownLoading}>
								<LoadingIco />
							</div>
						) : tag_list_data.parent === null &&
						  tags_data &&
						  tags_data.length > 0 &&
						  tags.length < tags_data.length &&
						  tag_list_data.data.length === 0 ? (
							<ul className={classes.dropdown} ref={this.main_tags_container}>
								{tags_data.map((filter, index) => {
									if (
										filter.checked === false &&
										((filter.name === 'search_filter' && input_value !== '') ||
											filter.name !== 'search_filter')
											// && filter.value.toLowerCase().includes(input_value.toLowerCase())
									) {
										return (
											<li key={index} className={classes.filterItemContainer}>
												<button
													data_value={filter.name}
													data_value_in_filters={
														filter.name_in_filters
															? filter.name_in_filters
															: null
													}
													data_type='title'
													className={`${classes.filterItem} filter-item`}
													onClick={(e) => {
														if (filter.name !== 'search_filter')
															this.add_tag(e, 'title');
														// else this.change_search();
													}}
												>
													{filter.value}
												</button>
											</li>
										);
									} else return null;
								})}
							</ul>
						) : tag_list_data.parent !== null &&
						  tag_list_data.data &&
						  tag_list_data.data.length > 0 ? (
							<ul className={classes.dropdown} ref={this.sub_tags_container}>
								{tag_list_data.data.map((filter, index) => (
									<li key={index} className={classes.filterItemContainer}>
										<button
											data_value={filter.id}
											data_parent={filter.name}
											data_type='value'
											className={`${classes.filterItem} ${classes.subFilterItem} filter-item`}
											data_checked={filter.checked + ''}
											onClick={(e) => {
												if (filter.checked === false)
													this.add_tag(e, 'value');
												else this.delete_tag(e, 'value');
											}}
										>
											{filter.checked ? (
												<img
													src={'/assets/icons/checkmark.svg'}
													alt='icon'
													className={classes.filterItem__image}
												/>
											) : filter.id !== 'search_filter' ? (
												<div className={classes.filterItem__image} />
											) : null}
											{filter.value}
										</button>
									</li>
								))}
							</ul>
						) : null}
					</div>
				) : null}
			</div>
		);
	}

	static defaultProps = {
		data: [],
		update_filters: () => null,
		filter_values: {},
	};
	static propTypes = {
		data: PropTypes.array,
		update_filters: PropTypes.func,
		filter_values: PropTypes.object,
	};
}

export default withStyles(styles)(Filter);
