// import { makeStyles } from "@material-ui/core/styles";

import { borderColor, borderDefault, borderRadius, color_map } from '../../../const/style';

export const useStyles = () => ({
	componentContainer: {
		position: 'fixed',
		left: 0,
		top: 0,
		right: 0,
		bottom: 0,
		zIndex: 4,
	},
	background: {
		position: 'absolute',
		left: 0,
		top: 0,
		right: 0,
		bottom: 0,
		zIndex: 1,
		backgroundColor: 'rgba(0, 0, 0, 0.6)',
		transition: '.2s ease',

		'&:is(:hover, :active)': {
			backgroundColor: 'rgba(0, 0, 0, 0.8)',
			cursor: 'pointer',
		},
	},
	container: {
		position: 'absolute',
		left: '50%',
		top: '50%',
		transform: 'translate(-50%, -50%)',
		zIndex: 2,
		backgroundColor: color_map.white,
		height: 'calc(100% - 32px)',
		maxHeight: 500,
		overflowY: 'auto',
		padding: '16px 16px 0 16px',
		border: borderDefault,
		borderRadius: borderRadius,
		width: '100%',
		maxWidth: 960,
		overflowX: 'hidden',
	},

	buttonContainer: {
		position: 'sticky',
		left: 0,
		right: 0,
		bottom: '-16px',
		backgroundColor: color_map.white,
		width: 'calc(100% + 34px)',
		marginLeft: '-18px',
		padding: 16,
		marginTop: 16,
		borderTop: borderDefault,
	},

	modalContainer: {
		maxWidth: 580,
		position: 'absolute',
		top: 37,
		left: 0,
		right: 0,
		fontSize: '0.75em',
		padding: '15px 10px',
		background: '#fff',
		boxShadow: '0 0.3em 1em #ccc',
		borderTop: '2px solid #888',
		borderRadius: 5,
		zIndex: 5,
		display: 'flex',
		flexWrap: 'nowrap',
	},
	input: {
		fontSize: 'inherit',
		fontWeight: 'normal',
		padding: '0.5em 0.8em',
		margin: '0 10px 0 0',
		borderRadius: 3,
		border: '1px solid #bbb',
		backgroundColor: '#fff',
		boxShadow: 'none',
		outline: 'none',
		'-webkit-appearance': 'none',
		'-moz-appearance': 'none',
		width: '100%',
	},
	submit: {
		position: 'relative',
		float: 'left',
		color: 'rgba(0,0,0,0.6)',
		fontWeight: 600,
		whiteSpace: 'nowrap',
		margin: 0,
		outline: 'none',
		background: 'transparent',
		fontSize: 'inherit',
		padding: '0.5em',
		border: '1px solid #bbb',
		borderBottomColor: '#888',
		borderRadius: 3,

		'&:hover': {
			cursor: 'pointer',
		},
	},

	buttonsContainer: {
		display: 'flex',
		marginBottom: 10,

		'& button': {
			display: 'flex',
			alignItems: 'center',
			backgroundColor: 'transparent',
			fontSize: 16,
			border: `1px solid ${borderColor}`,
			borderRadius: borderRadius,
			padding: '4px 8px',
			transition: '.2s ease',

			'& img': {
				width: 16,
			},

			'&:is(:hover, :active)': {
				backgroundColor: '#cbeefa',
				cursor: 'pointer',
			},
		},
	},

	showHTMLButton: {
		marginLeft: 'auto',
		fontWeight: 'bold',
	},
	showHTMLButton_active: {
		backgroundColor: '#75d2f2 !important',
	},

	htmlButton: {
		marginLeft: 16,
		backgroundColor: '#cbeefa !important',
		cursor: 'default !important',
		fontWeight: 'bold',
	},

	backModal: {
		position: 'absolute',
		left: 0,
		top: 35,
		border: `1px solid ${borderColor}`,
		padding: '4px 8px',
		borderRadius: borderRadius,
		backgroundColor: color_map.white,

		'& p': {
			margin: 0,
		},
	},

	backModalButtonsContainer: {
		marginTop: 8,
		display: 'flex',
	},

	backModal__option: {
		'&:nth-child(n+2)': {
			marginLeft: 8,
		},
	},

	customHTMLContainer: {
		'& > textarea': {
			height: 200,
			border: `1px solid ${borderColor}`,
			borderRadius: borderRadius,
			width: 'calc(100% - 16px)',
			resize: 'none',
			padding: `4px 8px`,
			outline: 'none',
			background: color_map.black,
			color: color_map.white,
		},
		'& .custom-HTML-show-container': {
			height: 200,
			border: `1px solid ${borderColor}`,
			borderRadius: borderRadius,
			width: 'calc(100% - 16px)',
			padding: `4px 8px`,
			background: color_map.white,
			color: color_map.black,
		},
	},
});
