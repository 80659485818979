import React from 'react';
import { Link, useListContext } from 'react-admin';

import { useStyles } from './style';

/**
 * Компонент списка пользователя
 *
 * @class
 */
const Currencies = () => {
	const classes = useStyles();
	const { data, ids } = useListContext();

	const [currencies_data, change_currencies_data] = React.useState(null);

	React.useEffect(() => {
		let new_currencies_data = [];

		if (ids.length > 0) {
			for (let key in data) {
				if (data[key].iso_code !== 'BYN') new_currencies_data.push(data[key]);
			}
		}

		change_currencies_data(new_currencies_data);
	}, [data, ids]);

	return (
		<div className={classes.listContainer}>
			<div className={classes.headerContainer}>
				<p className={`${classes.header} ${classes.header_iso}`}>Наименование</p>
				<p className={`${classes.header} ${classes.header_value}`}>Значение</p>
			</div>
			{currencies_data !== null && currencies_data.length > 0 ? (
				<ul className={classes.currenciesList}>
					{currencies_data.map((currency, index) => (
						<li key={index} className={classes.currenciesContainer}>
							<Link to={`/currencies/${currency.id}`} className={classes.currencies}>
								<p
									className={`${classes.currencies__text} ${classes.currencies__text_iso}`}
								>
									{currency.iso_code}
								</p>
								<p
									className={`${classes.currencies__text} ${classes.currencies__text_value}`}
								>
									{currency.value}
								</p>
							</Link>
						</li>
					))}
				</ul>
			) : null}
		</div>
	);
};

export default React.memo(Currencies);
