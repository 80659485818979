import * as React from 'react';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import { makeStyles } from '@material-ui/core/styles';
import { LoadingIndicator, Link, useLogout } from 'react-admin';
import ExitToAppIcon from '@material-ui/icons/ExitToApp';

const useStyles = makeStyles({
	logo: {
		'&:hover': {
			cursor: 'pointer',
		},
	},

	button_container: {
		marginLeft: 'auto',
		display: 'flex',
	},

	logout_button: {
		backgroundColor: 'transparent',
		border: 'none',
		padding: '13px 12px 11px',

		'&:is(:hover, :acitve)': {
			opacity: '.7',
			cursor: 'pointer',
		},
	},
});

const HeaderBar = (props) => {
	const classes = useStyles();
	const logout = useLogout();

	const handleClick = () => logout();

	return (
		<AppBar {...props}>
			<Toolbar>
				<Link className={classes.logo} to={'/'}>
					<img src={'/assets/temp/ru-logo_with-slogan.svg'} alt='logo' />
				</Link>
				{/* <Typography variant="h6" id="react-admin-title" /> */}
				<div className={classes.button_container}>
					<LoadingIndicator />
					<button
						className={classes.logout_button}
						onClick={handleClick}
						label='Выйти'
						title='Выйти'
					>
						<ExitToAppIcon />
					</button>
				</div>
			</Toolbar>
		</AppBar>
	);
};

export default HeaderBar;
