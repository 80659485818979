import { makeStyles } from '@material-ui/core/styles';

import { borderRadius, color_map } from '../../../const/style';

export const useStyles = makeStyles({
	paginationContainer: {
		marginTop: 12,
	},
	pageContainer: {
		display: 'flex',
	},
	pagesList: {
		display: 'flex',
		listStyle: 'none',
		padding: 0,
		margin: 0,
		border: `1px solid ${color_map.blue}`,
		borderRadius: borderRadius,

		'& > li:nth-last-child(-n+1) > button': {
			borderRight: 'none',
		},
	},
	page: {
		padding: '6px 12px',
		fontSize: 14,
		lineHeight: 1.15,
		color: color_map.black,
		backgroundColor: 'transparent',
		border: 'none',
		borderRight: `1px solid ${color_map.blue}`,
		transition: 'all .2s ease',

		'&:hover': {
			cursor: 'pointer',
			color: color_map.blue,
		},
	},
	page_prev: {
		marginRight: 6,
		borderRadius: borderRadius,
		border: 'none',
		backgroundColor: color_map.blue,
		display: 'flex',

		'&:hover': {
			backgroundColor: '#1A7AC7',
		},
		'& > img': {
			transform: 'rotate(180deg)',
		},
	},
	pagePlaceholder: {
		display: 'flex',
		width: 38,
	},
	page_next: {
		marginLeft: 6,
		borderRadius: borderRadius,
		border: 'none',
		backgroundColor: color_map.blue,
		display: 'flex',

		'&:hover': {
			backgroundColor: '#1A7AC7',
		},
	},
	page_active: {
		backgroundColor: color_map.blue,
		color: color_map.white,

		'&:hover': {
			backgroundColor: '#1A7AC7',
			color: color_map.white,
		},
	},
});
