import React from 'react';
import PropTypes from 'prop-types';
import { nanoid } from 'nanoid';

import Tooltip from '../Tooltip';

import { close_listener } from '../../../../utils/key_listener';

import { useStyles } from './style';

const tooltip_close_timeout = 2000;

/**
 * @param {Function} action action for select change
 *
 * @param {Array} list              options list
 * @param {String} name             select name
 * @param {Object} selected         selected option
 * @param {String} tooltip_text
 * @param {Boolean} disabled
 *
 * @param {Boolean} is_context_processed        context for select sync
 * @param {Function} context_toggle_processed   context for select sync
 *
 * @class
 */
const Select = ({
	action = () => null,

	list = [],
	name = '',
	selected = null,
	tooltip_text = null,
	disabled = false,

	is_context_processed = null,
	context_toggle_processed = () => null,
}) => {
	const classes = useStyles();

	const [is_open, toggle_open] = React.useState(false);
	const [selected_option, change_selected_option] = React.useState(null);
	const [tooltip_show, toggle_tooltip_show] = React.useState(false);

	const timer = React.useRef(null),
		button = React.useRef(null),
		dropdown_list = React.useRef(null);

	// clear event listener
	React.useEffect(() => {
		return () => {
			window.removeEventListener('keydown', key_listener_wrapper);
			window.removeEventListener('click', _CHECK_OUTBOX_CLICK);
		};
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	/**
	 * Event listener processing
	 */
	React.useEffect(() => {
		if (is_open) {
			window.addEventListener('keydown', key_listener_wrapper);
			window.addEventListener('click', _CHECK_OUTBOX_CLICK);
		} else {
			window.removeEventListener('keydown', key_listener_wrapper);
			window.removeEventListener('click', _CHECK_OUTBOX_CLICK);
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [is_open]);

	React.useEffect(() => {
		if (tooltip_show) {
			timer.current = setTimeout(() => {
				toggle_tooltip_show(false);
			}, tooltip_close_timeout);
		} else clearTimeout(timer.current);
	}, [tooltip_show]);

	React.useEffect(() => {
		if (
			list &&
			list.length > 0 &&
			selected &&
			(!selected_option || selected_option !== selected.title)
		) {
			change_selected_option(selected.title);
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [list, selected]);

	/**
	 * Sync for all select in current list
	 */
	React.useEffect(() => {
		if (is_context_processed === false) {
			toggle_open(false);
			context_toggle_processed(true);
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [is_context_processed]);

	/**
	 * Processing select
	 *
	 * @param {Object} option
	 */
	const _SELECT_ITEM = (option) => {
		change_selected_option(option.title);
		toggle_open(false);
		action(option.value);
	};

	const _TOGGLE_MENU = () => {
		if (is_open === false) {
			if (is_context_processed !== null) {
				context_toggle_processed(false);

				// add timeout for delay open (and closed other select)
				setTimeout(() => {
					toggle_open(true);
				}, 4);
			} else toggle_open(true);
		} else toggle_open(false);
	};

	/**
	 * Close sub menu when clicked outblox
	 */
	const _CHECK_OUTBOX_CLICK = React.useCallback((event) => {
		const element = event.target;

		if (!button.current.contains(element)) toggle_open(false);
	}, []);

	/**
	 * Wrapper for key listener
	 */
	const key_listener_wrapper = React.useCallback((event) => {
		// call func wrapper for key processing
		close_listener(event, () => {
			toggle_open(false);
			window.removeEventListener('keydown', key_listener_wrapper);
		});
	}, []);

	return (
		<div className={classes.container}>
			{list && list.length > 0 ? (
				<React.Fragment>
					<button
						type='button'
						aria-describedby={name}
						className={
							is_open
								? `${classes.button} ${classes.button_open} w-full`
								: `${classes.button} w-full`
						}
						onClick={_TOGGLE_MENU}
						ref={button}
						disabled={disabled}>
						<p className={classes.name} onMouseEnter={() => toggle_tooltip_show(true)}>
							{name}
						</p>
						{selected_option ? (
							<p className={classes.selected_value}>{selected_option}</p>
						) : null}
						<svg
							className={
								is_open
									? `${classes.arrow} ${classes.arrow_open}`
									: `${classes.arrow}`
							}
							width='12'
							height='7'
							viewBox='0 0 12 7'
							fill='none'
							xmlns='http://www.w3.org/2000/svg'>
							<path
								fillRule='evenodd'
								clipRule='evenodd'
								d='M11.8047 6.56659C11.5444 6.82694 11.1223 6.82694 10.8619 6.56659L5.99954 1.7042L1.13714 6.56659C0.87679 6.82694 0.45468 6.82694 0.194331 6.56659C-0.0660184 6.30624 -0.0660184 5.88414 0.194331 5.62379L5.52813 0.289988C5.78848 0.0296389 6.21059 0.0296389 6.47094 0.289988L11.8047 5.62379C12.0651 5.88414 12.0651 6.30624 11.8047 6.56659Z'
							/>
						</svg>
					</button>
					<div ref={dropdown_list}>
						{is_open ? (
							<ul className={`${classes.list} w-full`}>
								{list.map((item) => (
									<li
										key={nanoid()}
										className={
											item.title === selected_option
												? `${classes.listItem} ${classes.listItem_disabled}`
												: classes.listItem
										}>
										<button
											type='button'
											aria-labelledby='элемент выбора'
											onClick={() => _SELECT_ITEM(item)}
											className={classes.listItem__button}
											disabled={
												item.title === selected_option ? true : false
											}>
											{item.title}
										</button>
									</li>
								))}
							</ul>
						) : null}
					</div>
					{tooltip_text ? <Tooltip text={tooltip_text} is_show={tooltip_show} /> : null}
				</React.Fragment>
			) : null}
		</div>
	);
};

Select.propTypes = {
	action: PropTypes.func,

	list: PropTypes.array,
	name: PropTypes.string,
	selected: PropTypes.shape({
		title: PropTypes.string,
		value: PropTypes.string,
	}),
	tooltip_text: PropTypes.string,
	disabled: PropTypes.bool,

	is_context_processed: PropTypes.bool,
	context_toggle_processed: PropTypes.func,
};

export default Select;
