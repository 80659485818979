import { makeStyles } from '@material-ui/core/styles';

import { borderDefault, borderRadius, color_map } from '../../../const/style';

export const useStyles = makeStyles({
	listContainer: {
		display: 'flex',
		flexDirection: 'column',
		margin: '6px 0 0 0',
		border: borderDefault,
		borderRadius: borderRadius,
		overflow: 'hidden',
	},

	headerContainer: {
		display: 'flex',
		background: '#FFFFFF',
		padding: '6px 12px',
		borderBottom: `1px solid ${color_map.outline_gray}`,
	},
	header: {
		fontWeight: 700,
		fontSize: 12,
		lineHeight: 1.2,
		color: '#666666',
		margin: 0,
	},

	userList: {
		listStyle: 'none',
		display: 'flex',
		flexDirection: 'column',
		backgroundColor: color_map.white,
		padding: 0,
		margin: 0,
	},

	user: {
		display: 'flex',
		padding: 12,
		alignItems: 'center',
	},

	userContainer: {
		borderBottom: `1px solid ${color_map.outline_gray}`,
		transition: '.2s ease',

		'&:last-child': {
			borderBottom: `none`,
		},

		'&:is(:hover, :active)': {
			backgroundColor: color_map.light_gray,
		},
	},

	user__text: {
		fontSize: 14,
		color: color_map.black,
		margin: 0,
	},
});
