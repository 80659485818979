import { makeStyles } from '@material-ui/core/styles';

import { borderRadius, color_map } from '../../../../const/style';

export const useStyles = makeStyles({
	container: {
		position: 'relative',
	},

	button: {
		border: `1px solid ${color_map.control_gray}`,
		borderRadius: borderRadius,
		transition: '.2s ease',
		backgroundColor: 'transparent',
		minWidth: 150,
		marginRight: 12,
		padding: 12,
		display: 'flex',
		position: 'relative',
		alignItems: 'center',
		fontSize: 14,
		lineHeight: 1.2,
		height: '100%',

		'& p': {
			color: color_map.control_gray,
		},

		'&:is(:hover, :active)': {
			borderColor: color_map.blue,
			cursor: 'pointer',
		},
		'&:is(:hover, :active) svg path': {
			fill: color_map.blue,
		},
		'&:is(:hover, :active) p': {
			color: color_map.blue,
		},

		'&:disabled': {
			background: 'var(--outline-gray)',
			border: `1px solid var(--outline-gray)`,
		},
		'&:disabled p': {
			color: `${color_map.dark_gray} !important`,
			backgroundColor: `var(--outline-gray)`,
		},

		'&:disabled:is(:hover, :active)': {
			borderColor: color_map.control_gray,
		},
		'&:disabled:is(:hover, :active) p': {
			color: 'var(--gray)',
		},
		'&:disabled:is(:hover, :active) svg path': {
			fill: color_map.control_gray,
		},
	},

	button_open: {
		borderColor: color_map.blue,
		cursor: 'pointer',
		zIndex: 10,

		'& svg path': {
			fill: color_map.blue,
		},
		'& p': {
			color: color_map.blue,
		},
	},

	arrow: {
		marginLeft: 'auto',
		transform: 'rotate(180deg)',
		transition: '.2s ease',

		'& path': {
			fill: color_map.control_gray,
			transition: '.2s ease',
		},
	},
	arrow_open: {
		transform: 'rotate(0deg)',
	},

	name: {
		margin: 0,
		fontSize: 12,
		lineHeight: 1,
		position: 'absolute',
		transition: '.2s ease',
		left: 9,
		top: -7,
		backgroundColor: color_map.white,
		padding: '0 3px',
	},

	selected_value: {
		color: `${color_map.black} !important`,
		fontSize: 14,
		lineHeight: 1.2,
		margin: 0,
	},

	list: {
		position: 'absolute',
		left: 0,
		listStyle: 'none',
		margin: 0,
		padding: '6px 0',
		width: 'calc(100% - 12px)',
		background: color_map.white,
		boxShadow: '0px 6.4px 14.4px rgba(0, 0, 0, 0.13), 0px 1.2px 3.6px rgba(0, 0, 0, 0.1)',
		borderRadius: borderRadius,
		top: '100%',
		zIndex: 4,
	},

	listItem__button: {
		width: '100%',
		backgroundColor: 'transparent',
		borderRadius: 0,
		border: 'none',
		textAlign: 'left',
		padding: '6px 12px',
		transition: '.2s ease',
		fontSize: 14,
		lineHeight: 1.2,

		'&:is(:hover, :active)': {
			cursor: 'pointer',
			backgroundColor: color_map.blue,
			color: color_map.white,
		},

		'&:disabled:is(:hover, :active)': {
			cursor: 'default',
			backgroundColor: color_map.gray,
		},
	},
});
