import React from 'react';
import {
	DeleteButton,
	Edit,
	ReferenceInput,
	required,
	SaveButton,
	SelectInput,
	SimpleForm,
	TextInput,
	Toolbar,
	useNotify,
	useRedirect,
	useRefresh,
	useShowController,
} from 'react-admin';

import SEO from '../../Common/SEO';
import UndoButton from '../../Common/Interface/UndoButton';
import RichText from '../../Common/RichText';

import { store_state } from '../../../data/select/store';

import { createEditStyles } from '../../Common/createEditStyle';

export const BrandEdit = (props) => {
	const classes = createEditStyles();

	const image_ref = React.createRef();

	const [image_data, update_image_data] = React.useState(null);
	const [rich_data, update_rich_data] = React.useState(null);
	const [record_fetched, update_record_fetched] = React.useState(false);

	const redirect = useRedirect();
	const notify = useNotify();
	const refresh = useRefresh();

	const {
		record, // record fetched via dataProvider.getOne() based on the id from the location
	} = useShowController(props);

	React.useEffect(() => {
		if (record) {
			// обработка изображений
			if (record.image) update_image_data(record.image);

			// обработка описания
			if (record.description && (record.description.body || record.description.body === ''))
				update_rich_data(record.description.body);

			update_record_fetched(true);
		}

		refresh();
	}, [record]);

	const on_success = React.useCallback(() => {
		notify(`Бренд удалён`);
		redirect('/brands');
	}, []);

	const undo_edit = React.useCallback(() => {
		notify(`Редактирование отменено`);
		redirect('/brands');
	}, []);

	const PostCreateToolbar = (props) => (
		<Toolbar {...props}>
			<SaveButton
				transform={(data) => {
					const new_data = {
						...data,
						image: image_data,
						description: rich_data,
					};

					delete new_data.id;
					delete new_data.slug;
					delete new_data.sort_position;
					delete new_data.created_at;
					delete new_data.updated_at;
					if (typeof image_data === 'string') delete new_data.image;

					return new_data;
				}}
				submitOnEnter={true}
			/>
			<UndoButton action={undo_edit} />
			<DeleteButton
				style={{ marginLeft: 'auto' }}
				mutationMode='pessimistic'
				onSuccess={on_success}
			/>
		</Toolbar>
	);

	return (
		<Edit {...props} mutationMode={'pessimistic'}>
			<SimpleForm toolbar={<PostCreateToolbar />}>
				<SEO title={record && record.title} />
				<h1 className={classes.pageHeader}>{record && record.title}</h1>
				<TextInput source='title' validate={[required()]} />
				<TextInput source='short_description' validate={[required()]} />
				{record_fetched ? (
					<React.Fragment>
						<h2 className={`${classes.blockHeader}`}>Описание</h2>
						<RichText
							parent_value={rich_data}
							update_data={(new_data) => update_rich_data(new_data)}
						/>
					</React.Fragment>
				) : null}
				<SelectInput
					source='store'
					choices={store_state}
					allowEmpty={true}
					validate={[required()]}
				/>
				<h2 className={classes.blockHeader}>Изображение</h2>
				<div className={classes.dataBlock}>
					<input
						type='file'
						onChange={(event) => update_image_data(event.target.files[0])}
						ref={image_ref}
					/>
					{image_data ? (
						<div className={classes.imagesBlock}>
							<img
								src={
									image_data && typeof image_data !== 'string'
										? URL.createObjectURL(image_data)
										: image_data && typeof image_data === 'string'
										? image_data
										: null
								}
								alt='изображение'
							/>
						</div>
					) : null}
					{/* <button
                        type="button"
                        className='flex self-baseline btn btn-delete mt-sm'
                        onClick={reset_image_data}>
                        Сбросить
                    </button> */}
				</div>
			</SimpleForm>
		</Edit>
	);
};
