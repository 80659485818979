import { borderRadius, color_map } from '../../../../const/style';

export const styles = () => ({
	filterContainer: {
		display: 'flex',
		flexDirection: 'column',
		marginTop: 12,
	},

	filterTotal: {
		margin: '6px 0 0',
		color: color_map.gray,
		fontSize: 12,
		lineHeight: '1.2',
	},

	filter: {
		position: 'relative',
		border: `1px solid ${color_map.control_gray}`,
		borderRadius: 4,
		padding: '0 36px 0 6px',
		transition: '.2s ease',
		overflow: 'hidden',
		width: '100%',
	},
	filter_active: {
		borderColor: color_map.blue,
	},

	searchButton: {
		position: 'absolute',
		right: 0,
		background: 'transparent',
		border: 'none',
		top: 0,
		padding: '8px 6px',
		display: 'flex',

		'&:is(:hover, :active)': {
			cursor: 'pointer',
		},

		'& path': {
			stroke: '#999999',
			transition: '.2s ease',
		},
		'&:is(:hover, :active) path': {
			stroke: color_map.blue,
		},
	},

	tagList: {
		listStyle: 'none',
		padding: 0,
		margin: 0,
		display: 'flex',
		flexWrap: 'nowrap',
		overflow: 'hidden',
	},

	tagContainer: {
		display: 'flex',
		flexWrap: 'nowrap',
		position: 'relative',
		marginRight: 8,
	},

	tagContainer_input: {
		marginRight: 0,
		flex: 1,
	},

	tag: {
		margin: '6px 0',
		display: 'flex',
		backgroundColor: color_map.outline_gray,
		borderRadius: borderRadius,
		fontSize: 14,
		lineHeight: '1.2',
		position: 'relative',
		transition: '.2s ease',

		'&:is(:hover, :active)': {
			cursor: 'pointer',
			backgroundColor: '#DCDCDC',
		},
		'&:is(:hover, :active) .tag': {
			backgroundColor: '#CCCCCC',
		},
	},
	tag__title: {
		padding: '6px 7px',
		display: 'flex',
		color: color_map.dark_gray,
	},

	tagItem: {
		padding: '6px 9px',
		borderRadius: borderRadius,
		backgroundColor: '#DCDCDC',
		marginLeft: 4,
		color: color_map.black,
		transition: '.2s ease',
		whiteSpace: 'nowrap',

		'&:nth-child(2)': {
			marginLeft: 0,
		},
	},

	textInput: {
		border: 'none',
		outline: 'none',
		padding: '8px 0',
		height: 40,
		fontSize: 14,
		lineHeight: '1.2',
		flex: 1,
	},

	dropdownContainer: {
		position: 'absolute',
		top: 104,
		left: 20,
		display: 'none',
		backgroundColor: color_map.white,
		boxShadow: '0px 6.4px 14.4px rgba(0, 0, 0, 0.13), 0px 1.2px 3.6px rgba(0, 0, 0, 0.1)',
		borderRadius: 3,
		border: `1px solid ${color_map.control_gray}`,
		padding: '6px 0',
		width: 216,
		// width: '100%',
		maxHeight: 185,
		zIndex: 1000,
		overflow: 'auto',
	},
	dropdownContainer_active: {
		display: 'flex',
	},

	dropdown: {
		padding: 0,
		listStyle: 'none',
		display: 'flex',
		flexDirection: 'column',
		width: '100%',
		margin: 0,
	},

	filterItemContainer: {
		transition: '.2s ease',
		borderTop: `1px solid ${color_map.control_gray}`,

		'&:first-child': {
			borderTop: 'none',
		},
		'&:first-child button': {
			borderTop: '1px solid transparent',
		},
		'&:last-child button': {
			borderBottom: '1px solid transparent',
		},

		'&:is(:hover, active)': {
			backgroundColor: color_map.light_gray,
		},
		'&:is(:hover, active):first-child button': {
			borderTop: `1px solid ${color_map.control_gray}`,
		},
		'&:is(:hover, active):last-child button': {
			borderBottom: `1px solid ${color_map.control_gray}`,
		},

		'& .filter-item_active': {
			backgroundColor: color_map.light_gray,
		},
		'&:first-child .filter-item_active': {
			borderTop: `1px solid ${color_map.control_gray}`,
		},
		'&:last-child .filter-item_active': {
			borderBottom: `1px solid ${color_map.control_gray}`,
		},
	},

	filterItem: {
		backgroundColor: 'transparent',
		border: 'none',
		padding: '6px 12px',
		width: '100%',
		textAlign: 'start',

		'&:is(:hover, active)': {
			cursor: 'pointer',
		},
	},
	subFilterItem: {
		display: 'flex',
		alignItems: 'center',
	},

	filterItem__image: {
		display: 'flex',
		width: 14,
		height: 14,
		marginRight: 3,
	},

	dropdownLoading: {
		// display: 'flex',
		width: '100%',
	},
});
