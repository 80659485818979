import { makeStyles } from '@material-ui/core/styles';
import { borderRadius } from '../../../const/style';

export const useStyles = makeStyles({
	container: {
		position: 'absolute',
		top: -4,
		left: 20,
	},

	tooltip: {
		padding: '3px 6px',
		backgroundColor: '#666666E5',
		borderRadius: borderRadius,
		transform: 'translateX(-50%)',
		whiteSpace: 'nowrap',
		display: 'none',
		opacity: 0.01,
	},

	tooltip__text: {
		fontSize: 10,
		lineHeight: 1.2,
		color: '#FFFFFF',
		margin: 0,
	},
});
