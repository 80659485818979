export const color_map = {
	blue: '#1263AF',
	red: '#EC3D47',
	black: '#000000',
	dark_gray: '#666666',
	gray: '#999999',
	control_gray: '#DFDFDF',
	outline_gray: '#ECECEC',
	light_gray: '#F9F9F9',
	white: '#FFFFFF',
	background_light_blue: '#F2F7FB',
	light_red: '#FCF3F2',
	bright_blue: '#0070DA',
};

export const borderColor = color_map.outline_gray,
	borderDefault = `1px solid ${borderColor}`,
	containerBorder = borderDefault,
	borderRadius = 4;
