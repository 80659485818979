import React from 'react';
import PropTypes from 'prop-types';

import BackModal from './BackModal';

import { useStyles } from './style';

/**
 * @class
 */
const HTMLEditor = ({
	update_data = () => null,
	close_html_editor = () => null,

	custom_html = '',
	parent_value = null,
}) => {
	const classes = useStyles();

	// is check modal for the back button open
	const [is_back_modal_open, update_back_modal_open] = React.useState(false);
	// is html-template view show
	const [is_html_show, update_html_show] = React.useState(true);

	return (
		<React.Fragment>
			<div className={classes.buttonsContainer}>
				<button
					type='button'
					aria-labelledby='back'
					className={classes.backButton}
					onClick={() => update_back_modal_open(true)}
				>
					<img
						src={
							'data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAADIAAAAyCAYAAAAeP4ixAAAABmJLR0QA/wD/AP+gvaeTAAAAmUlEQVRoge3WOwrCQBRG4YMbmCzBrbsGS+1sfGDlAlxKLMLtBCEjucPlfJA2/AeGYUCSJEnaUAOOwCF7SI8G3IAZeCRvWa0BV5aIN7DPnbNOmYg4TkZkmjBiDBNwx4h8ZSKeLBGZ3+XX0F1/6ybmf/ykxNEKJa7dYMyoSryzQonnezBmVOVi4gJ4JW/p1oATcM4eIkmSJH31AQ6pXcqlTtSjAAAAAElFTkSuQmCC'
						}
						alt='back'
					/>
				</button>
				{is_back_modal_open ? (
					<BackModal
						is_open={is_back_modal_open}
						update_modal={update_back_modal_open}
						close_html={close_html_editor}
					/>
				) : null}
				<button
					type='button'
					aria-labelledby='show HTML'
					className={
						is_html_show
							? `${classes.showHTMLButton} ${classes.showHTMLButton_active}`
							: classes.showHTMLButton
					}
					onClick={() => update_html_show(!is_html_show)}
				>
					Показать
				</button>
				<button
					type='button'
					aria-labelledby='HTML'
					className={classes.htmlButton}
					disabled
				>
					HTML
				</button>
			</div>
			<div className={classes.customHTMLContainer}>
				{is_html_show ? (
					<div
						className={'custom-HTML-show-container'}
						dangerouslySetInnerHTML={{ __html: parent_value ? parent_value : '' }}
					/>
				) : (
					<textarea
						name='custom-html'
						onChange={update_data}
						defaultValue={parent_value}
					></textarea>
				)}
			</div>
		</React.Fragment>
	);
};

HTMLEditor.propTypes = {
	update_data: PropTypes.func,
	close_html_editor: PropTypes.func,

	custom_html: PropTypes.string,
	parent_value: PropTypes.string,
};

export default HTMLEditor;
