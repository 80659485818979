import React from 'react';
import PropTypes from 'prop-types';
import { DataProviderContext } from 'react-admin';
import { DragDropContext, Droppable } from 'react-beautiful-dnd';

import FilterItem from './FilterItem';

import { reorder, set_sort_index, sort_elements } from '../../../../utils/drag-n-drop';

export const FilterContext = React.createContext();

class UpdateWrapper extends React.Component {
	/**
	 * @param {Array} data              data for wrapped components
	 * @constructor
	 */

	shouldComponentUpdate(nextProps) {
		if (
			nextProps.data === this.props.data &&
			nextProps.change_action === this.props.change_action &&
			nextProps.delete_action === this.props.delete_action
		)
			return false;

		return true;
	}

	render() {
		const { data } = this.props;

		if (!data) return null;

		return data.map((filter, index) => (
			<FilterItem key={filter.id} data={filter} index={index} />
		));
	}

	static defaultProps = {
		data: [],
	};
	static propTypes = {
		data: PropTypes.array,
	};
}

/**
 * @class
 */
const Filters = ({
	record = {},

	parent_update_data = () => null,
}) => {
	const dataProvider = React.useContext(DataProviderContext);

	const [list, update_list] = React.useState([]);

	React.useEffect(() => {
		if (record.id) {
			// составляем список категорий
			dataProvider
				.getList('filters', {
					pagination: { page: 1, perPage: 200 },
					sort: { field: 'title', order: 'ASC' },
					filter: {
						category_id: [record.id],
					},
				})
				.then(({ data }) => {
					let new_list = Array.from(data);

					new_list.sort(sort_elements);

					update_list(new_list);
				});
		}
	}, []);

	React.useEffect(() => {
		parent_update_data(list);
	}, [list]);

	/**
	 * drag-end post-processing
	 *
	 * @param {Object} result react-beautiful-dnd data
	 * @returns reordered list
	 */
	const _on_drag_end = (result) => {
		// dropped outside the list
		if (!result.destination) {
			return;
		}

		// reorder list
		let items = reorder(list, result.source.index, result.destination.index);

		items = set_sort_index(items);

		update_list(items);
	};

	if (!list || list.length === 0) return null;

	return (
		<FilterContext.Provider value={{ filter_list: list, update_filter_list: update_list }}>
			<ul>
				<DragDropContext onDragEnd={_on_drag_end}>
					<Droppable droppableId='droppable'>
						{(provided) => (
							<div {...provided.droppableProps} ref={provided.innerRef}>
								<UpdateWrapper data={list} />
								{provided.placeholder}
							</div>
						)}
					</Droppable>
				</DragDropContext>
			</ul>
		</FilterContext.Provider>
	);
};

export default Filters;
