import React from 'react';
import {
	Create,
	required,
	SaveButton,
	SimpleForm,
	TextInput,
	Toolbar,
	useNotify,
	useRedirect,
} from 'react-admin';

import SEO from '../../Common/SEO';
import UndoButton from '../../Common/Interface/UndoButton';

import { createEditStyles } from '../../Common/createEditStyle';

export const SliderCreate = (props) => {
	// const [image_data, update_image_data] = React.useState(null);

	const classes = createEditStyles();
	const redirect = useRedirect();
	const notify = useNotify();

	const undo_edit = React.useCallback(() => {
		notify(`Создание отменено`);
		redirect('/sliders');
	}, []);

	const PostCreateToolbar = (props) => (
		<Toolbar {...props}>
			<SaveButton
				// transform={data => {
				//     const new_data = {
				//         ...data,
				//         image: image_data,
				//     };
				//     return(new_data)
				// }}
				submitOnEnter={true}
				label='Создать'
			/>
			<UndoButton action={undo_edit} />
		</Toolbar>
	);

	return (
		<Create {...props}>
			<SimpleForm toolbar={<PostCreateToolbar />}>
				<SEO title={'Создание карусели'} />
				<h1 className={classes.pageHeader}>Создание карусели</h1>
				<TextInput source='title' validate={[required()]} />
			</SimpleForm>
		</Create>
	);
};
