export const filter_sort = (a, b) => {
	// if (a.checked !== undefined && b.checked !== undefined) {
	//     if (b.checked === true && a.checked === false) return 1;
	//     else if (
	//         (a.checked === true && b.checked === true) ||
	//         (a.checked === false && b.checked === false)
	//     ) {
	//         if (a.value > b.value) return 1
	//         else return -1
	//     }
	//     else return -1
	// } else {
	//     if (a.value > b.value) return 1
	//     else return -1
	// }
	if (a.value > b.value) return 1;
	else return -1;
};
