import React from 'react';
import {
	Create,
	required,
	SaveButton,
	SimpleForm,
	TextInput,
	Toolbar,
	useNotify,
	useRedirect,
} from 'react-admin';

import SEO from '../../Common/SEO';
import UndoButton from '../../Common/Interface/UndoButton';

import { createEditStyles } from '../../Common/createEditStyle';

export const CurrencyCreate = (props) => {
	const classes = createEditStyles();

	const redirect = useRedirect();
	const notify = useNotify();

	const undo_edit = React.useCallback(() => {
		notify(`Создание отменено`);
		redirect('/currencies');
	}, []);

	const PostCreateToolbar = (props) => (
		<Toolbar {...props}>
			<SaveButton submitOnEnter={true} label='Создать' disabled />
			<UndoButton action={undo_edit} />
		</Toolbar>
	);

	return (
		<Create {...props}>
			<SimpleForm toolbar={<PostCreateToolbar />}>
				<SEO title={'Создание курса валюты'} />
				<h1 className={classes.pageHeader}>Создание курса валюты</h1>
				<TextInput source='iso_code' validate={[required()]} />
				<TextInput source='value' inputMode='decimal' validate={[required()]} />
			</SimpleForm>
		</Create>
	);
};
