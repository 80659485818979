import { makeStyles } from '@material-ui/core/styles';

import { borderRadius, color_map } from '../../../../const/style';

export const useStyles = makeStyles({
	button_undo: {
		color: 'rgba(0, 0, 0, 0.5)',
		border: '1px solid rgba(0, 0, 0, 0.2)',
		borderRadius: borderRadius,
		transition: '.2s ease',
		padding: '6px 12px',
		fontSize: 14,
		lineHeight: 1.15,
		fontFamily: `"Roboto", "Helvetica", "Arial", sans-serif`,

		'&:is(:hover, :active)': {
			color: color_map.white,
			backgroundColor: 'rgba(0, 0, 0, 0.5)',
		},
	},
});
