export const stock_status = [
	{
		id: 'in_stock',
		value: 'in_stock',
		name: 'В наличии',
		label: 'В наличии',
	},
	{
		id: 'out_of_stock',
		value: 'out_of_stock',
		name: 'Нет в наличии',
		label: 'Нет в наличии',
	},
	{
		id: 'from_supplier',
		value: 'from_supplier',
		name: 'На складе',
		label: 'На складе',
	},
];
