import React from 'react';
import Helmet from 'react-helmet';
import PropTypes from 'prop-types';

const SEO = ({ title = '' }) => (
	<Helmet>
		<title>{`${title} - Панель администратора ClimatProfi`}</title>
	</Helmet>
);

SEO.ropTypes = {
	title: PropTypes.string,
};

export default SEO;
