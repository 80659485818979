import { makeStyles } from '@material-ui/core/styles';

import { borderDefault, borderRadius, color_map } from '../../../const/style';

export const useStyles = makeStyles({
	mainContainer: {
		display: 'flex',
		flexDirection: 'column',
	},
	categoryList: {
		margin: 0,
		padding: 0,
		listStyle: 'none',

		'& > li:first-child': {
			marginTop: 0,
		},
	},
	subCategoryList: {
		padding: 0,
		listStyle: 'none',

		'& li': {
			marginTop: 0,
			borderRadius: 0,
			border: 'none',

			'& a': {
				borderBottom: borderDefault,
			},
		},
	},
	categoryItemContainer: {
		marginTop: 6,
		border: borderDefault,
		borderRadius: borderRadius,
		backgroundColor: color_map.white,
		transition: 'background-color .2s ease',

		'&:nth-child(1)': {
			borderTop: borderDefault,
			borderLeft: borderDefault,
			borderRight: borderDefault,
			borderTopLeftRadius: borderRadius,
			borderTopRightRadius: borderRadius,
		},
		'&:nth-child(2)': {
			borderLeft: borderDefault,
			borderRight: borderDefault,
		},
		'&:nth-child(3)': {
			borderBottom: borderDefault,
			borderLeft: borderDefault,
			borderRight: borderDefault,
			borderBottomLeftRadius: borderRadius,
			borderBottomRightRadius: borderRadius,
		},

		'&:hover': {
			backgroundColor: color_map.light_gray,
		},
	},
	categoryItem: {
		display: 'flex',
		padding: '6px 12px',
		alignItems: 'center',
	},
	categoryItem__imageContainer: {
		width: 60,
		height: 60,
		marginRight: 12,
		display: 'flex',
		alignItems: 'center',
	},
	mainCategoryItemContainer: {
		borderBottom: borderDefault,
		height: 72,
		display: 'flex',
	},
	mainCategoryItem: {
		display: 'flex',
		padding: '6px 12px',
		alignItems: 'center',
		transition: 'background-color .2s ease',
		width: '100%',

		'&:hover': {
			backgroundColor: color_map.light_gray,
		},
	},
	subCategoryItemContainer: {
		borderBottom: borderDefault,
		height: 72,
		display: 'flex',

		'&:nth-last-child(1)': {
			borderBottom: 'none',
		},
	},
	subCategoryItem: {
		display: 'flex',
		padding: '6px 12px',
		alignItems: 'center',
		transition: 'background-color .2s ease',
		width: '100%',

		'&:hover': {
			backgroundColor: color_map.light_gray,
		},
	},
	categoryItem__text: {
		margin: 0,
		color: color_map.black,
	},
	subCategoryListContainer: {
		marginTop: 6,
		backgroundColor: color_map.white,
		border: borderDefault,
		borderRadius: borderRadius,
	},
	subCategoryItem__imageContainer: {
		width: 60,
		height: 60,
		marginRight: 12,
		display: 'flex',
		alignItems: 'center',
	},
	productsList: {
		border: borderDefault,
		borderRadius: borderRadius,
		backgroundColor: color_map.white,
		display: 'flex',
		flexDirection: 'column',
	},
	tbody: {
		display: 'flex',
		flexDirection: 'column',

		'& > tr': {
			borderBottom: borderDefault,
			backgroundColor: color_map.white,
			transition: 'all .2s ease',

			'&:hover': {
				backgroundColor: color_map.light_gray,
			},

			'&:nth-last-child(-n+1)': {
				borderBottom: 'none',
			},
		},
	},
	row: {
		display: 'flex',
		padding: '6px 12px',
	},
	headerContainer: {
		display: 'flex',
		borderBottom: borderDefault,

		'& > th': {
			padding: 0,
			borderBottom: 'none',
			fontSize: 12,
			lineHeight: 1.15,
			color: 'rgba(0, 0, 0, 0.6)',
			fontWeight: 700,
		},

		'& > th:nth-child(1)': {
			flexBasis: 80,
			marginRight: 17,
		},

		'& > th:nth-child(2)': {
			flex: 2,
		},

		'& > th:nth-child(3)': {
			flex: 1,
		},

		'& > th:nth-child(4)': {
			flex: 2,
		},

		'& > th:nth-child(5)': {
			flexBasis: 80,
			padding: '0 !important',
		},
	},
	productContainer: {
		padding: 0,
		display: 'flex',
		borderBottom: 'none',
		alignItems: 'center',
	},
	product__nameContainer: {
		flex: 2,
	},
});
