import React from 'react';
import { List } from 'react-admin';

import HeaderBlock from '../../Common/HeaderBlock';
import SEO from '../../Common/SEO';
import Currencies from './Currencies';
import UpdatePricies from './UpdatePricies';

/**
 * @class
 */
export const CurrenciesList = (props) => {
	return (
		<List
			component='div'
			bulkActionButtons={false}
			{...props}
			perPage={300}
			actions={false}
			pagination={null}
		>
			<div>
				<SEO title={'Курсы валют'} />
				<HeaderBlock
					title={'Курсы валют'}
					link={'currencies'}
					is_new={false}
					additional_button={<UpdatePricies />}
				/>
				<Currencies />
			</div>
		</List>
	);
};
