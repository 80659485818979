import { makeStyles } from '@material-ui/core/styles';

import { borderRadius, color_map } from '../../../../const/style';

export const useStyles = makeStyles({
	deleteButton: {
		border: '1px solid rgba(0, 0, 0, 0.5)',
		padding: 0,
		borderRadius: borderRadius,
		backgroundColor: 'transparent',
		width: '100%',
		maxWidth: 36,
		height: 36,
		margin: 'auto 0',
		display: 'flex',
		alignItems: 'center',
		justifyContent: 'center',
		transition: '.2s ease',

		'& svg': {
			color: 'rgba(0, 0, 0, 0.5)',
			transition: '.2s ease',
		},

		'&:hover': {
			cursor: 'pointer',
			borderColor: color_map.red,
		},

		'&:hover svg': {
			color: color_map.red,
		},
	},
});
