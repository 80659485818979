export const store_state = [
	{
		id: 'climatprofi',
		value: 'climatprofi',
		name: 'КлиматПрофи',
		title: 'КлиматПрофи',
	},
	{
		id: 'climatwine',
		value: 'climatwine',
		name: 'КлиматБокс',
		title: 'КлиматБокс',
	},
];
