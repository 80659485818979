import { makeStyles } from '@material-ui/core/styles';

import { borderRadius, color_map, containerBorder } from '../../../../const/style';

export const useStyles = makeStyles({
	container: {
		position: 'absolute',
		left: 0,
		top: 'calc(100% + 2px)',
		backgroundColor: color_map.white,
		zIndex: 1000,
		border: containerBorder,
		borderRadius: borderRadius,
		maxHeight: 200,
		overflow: 'auto',
	},

	button: {
		color: color_map.black,
		transition: '.2s ease',

		'&:is(:hover, :active)': {
			backgroundColor: color_map.blue,
			color: color_map.white,
		},
	},
});
