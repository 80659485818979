import React from 'react';
import PropTypes from 'prop-types';

import SearchList from './SearchList';

import { check_input_for_only_numbers } from './utils/number-processing';

import { useStyles } from './style';

/**
 * @param {String} name     input name
 * @param {String} type     input type
 *
 * @param {String} parent_value         current value for this Input from parent
 *
 * @param {Array} dropdown_list
 * @param {Array} search_list
 * @param {String} disabled_value       which value user can't select
 * @param {String} dropdown_selected    current selected value from dropdown
 *
 * @param {String} placeholder
 * @param {Boolean} error  is input in error state
 *
 * @param {String} classNames  custom classes
 *
 * @param {Boolean} disabled  can user change the value
 *
 * @param {Boolean} only_numbers    flag that forbid a letters
 *
 * @param {Function} change                 parent change function
 * @param {Function} select_dropdown        change value from dropdown
 * @param {Function} search_select_action   change item from search
 *
 * @class
 */
const Input = ({
	name = '',
	type = 'text',

	parent_value = null,

	dropdown_list = null,
	search_list = null,
	disabled_value = null,
	dropdown_selected = null,

	placeholder = '',
	error = false,

	classNames = null,

	disabled = false,

	only_numbers = false,

	change = () => null,
	select_dropdown = () => null,
	search_select_action = () => null,
}) => {
	const input_ref = React.useRef();
	const is_listener_work = React.useRef();
	const classes = useStyles();
	const [value, update_value] = React.useState('');
	const [is_active, toggle_active] = React.useState(false);
	const [is_focus, toggle_focus] = React.useState(false);
	const [dropdown_open, toggle_dropdown_open] = React.useState(false);
	const [is_search_open, toggle_search_open] = React.useState(false);

	React.useEffect(() => {
		parent_value_update_processing();

		return () => {
			if (typeof window !== 'undefined') {
				remove_event_listener();
			}
		};
	}, []);

	React.useEffect(() => {
		parent_value_update_processing();
	}, [parent_value]);

	React.useEffect(() => {
		change(value);

		if ((value || value === 0) && !is_active) toggle_active(true);
		else if (!value && is_active && !is_focus) toggle_active(false);
	}, [value]);

	React.useEffect(() => {
		if (is_focus && !value) toggle_active(true);
		else if (!is_focus && !value && value !== 0) toggle_active(false);
	}, [is_focus]);

	React.useEffect(() => {
		if (typeof window !== 'undefined' && is_search_open && is_listener_work.current === false) {
			window.addEventListener('click', click_listener);
			is_listener_work.current = true;
		} else remove_event_listener();
	}, [is_search_open]);

	/**
	 * Window click event listener
	 *
	 * @param {Event} e
	 */
	const click_listener = (e) => {
		// check for input click
		if (e.target !== input_ref.current) {
			toggle_search_open(false);
			remove_event_listener();
		}
	};

	/**
	 * Remove event listener
	 */
	const remove_event_listener = () => {
		window.removeEventListener('click', click_listener);
		is_listener_work.current = false;
	};

	const parent_value_update_processing = () => {
		if ((!parent_value || +parent_value === 0) && is_active && !is_focus) toggle_active(false);

		if ((parent_value || parent_value === 0) && value !== parent_value)
			update_value(parent_value);
	};

	/**
	 * Input processing with input changing
	 *
	 * @param {Element} e
	 */
	const input_processing = (e) => {
		const element = e?.target;

		let finished_value = element.value;
		if (only_numbers) finished_value = check_input_for_only_numbers(element.value);

		update_value(finished_value);
	};

	let input_class = classes.inputComponent;
	if (is_focus) input_class += ` ${classes.inputComponent_focus}`;
	if (error) input_class += ` ${classes.inputComponent_error}`;
	if (dropdown_list || disabled_value) input_class += ` ${classes.inputComponent_value}`;
	if (classNames) input_class += ` ${classNames}`;

	return (
		<div className={`flex flex-col relative w-full ${classes.container}`}>
			<label className={input_class}>
				<p
					className={
						is_active
							? `${classes.inputComponent__text} ${classes.inputComponent__text_active}`
							: classes.inputComponent__text
					}
				>
					{placeholder}
				</p>
				<div className={classes.inputContainer}>
					<input
						name={name}
						type={type}
						className={classes.input}
						onChange={input_processing}
						onFocus={() => {
							toggle_focus(true);
							toggle_search_open(true);
						}}
						value={value}
						onBlur={() => {
							setTimeout(() => {
								toggle_focus(false);
							}, 150);
						}}
						disabled={disabled}
						ref={input_ref}
					/>
					{dropdown_list ? (
						<button
							type='button'
							className={`${classes.valueContainer} ${classes.valueContainer_dropdown}`}
							onClick={(e) => {
								e.preventDefault();

								toggle_dropdown_open(!dropdown_open);
							}}
						>
							<p>{dropdown_selected ? dropdown_selected : '...'}</p>
							<img src='/assets/icons/chevron-up.svg' alt='стрелка' />
						</button>
					) : disabled_value ? (
						<div className={classes.valueContainer}>
							<p>{disabled_value}</p>
						</div>
					) : null}
					{dropdown_open ? (
						<ul className={classes.dropdownList}>
							{dropdown_list.map((item) => (
								<li key={item.id}>
									<button
										onClick={(e) => {
											e.preventDefault();
											select_dropdown(item.id);
											toggle_dropdown_open(false);
										}}
										className={
											dropdown_selected === item.value
												? `${classes.dropdownItem} ${classes.dropdownItem_active}`
												: `${classes.dropdownItem}`
										}
									>
										{item.value}
									</button>
								</li>
							))}
						</ul>
					) : null}
				</div>
			</label>
			{is_search_open ? (
				<SearchList list={search_list} select_action={search_select_action} />
			) : null}
		</div>
	);
};

Input.propTypes = {
	name: PropTypes.string,
	type: PropTypes.string,

	parent_value: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),

	dropdown_list: PropTypes.array,
	search_list: PropTypes.array,
	disabled_value: PropTypes.string,
	dropdown_selected: PropTypes.string,

	placeholder: PropTypes.string,
	error: PropTypes.bool,

	classNames: PropTypes.string,

	disabled: PropTypes.bool,

	only_numbers: PropTypes.bool,

	change: PropTypes.func,
	select_dropdown: PropTypes.func,
	search_select_action: PropTypes.func,
};

export default Input;
