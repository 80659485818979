import { makeStyles } from '@material-ui/core/styles';

import { borderDefault } from '../../../../../const/style';

export const useStyles = makeStyles({
	checkboxList: {
		width: '100%',
	},

	checkedContainer: {
		paddingTop: 6,
		paddingRight: 6,
		paddingBottom: 6,
		borderBottom: borderDefault,
		display: 'flex',
	},
});
