import { makeStyles } from '@material-ui/core/styles';

import { color_map } from '../../../../../const/style';

export const useStyles = makeStyles({
	brandContainer: {
		borderBottom: `1px solid ${color_map.outline_gray}`,
		transition: '.2s ease',

		'&:last-child': {
			borderBottom: `none`,
		},

		'&:is(:hover, :active)': {
			backgroundColor: color_map.light_gray,
		},
	},

	brand__text: {
		fontSize: 14,
		color: color_map.black,
	},
});
