import { makeStyles } from '@material-ui/core/styles';

import { borderRadius, color_map } from '../../../../const/style';

export const useStyles = makeStyles({
	container: {
		background: 'rgba(102, 102, 102, 0.9)',
		borderRadius: borderRadius,
		padding: '3px 6px',
		color: color_map.white,
		fontSize: 10,
		lineHeight: 1.4,
		maxWidth: 180,
		position: 'absolute',
		left: '50%',
		top: '-100%',
		transform: 'translateX(-50%)',
		width: '100%',
		zIndex: 2,
		textAlign: 'center',
		display: 'none',
		opacity: '.01',
	},
});
