import React from 'react';
import ReactDOM from 'react-dom';

import App from './App';

import './styles/extracted_tailwind.css';
import './styles/animation.css';
import './styles/global.css';

ReactDOM.render(
	<React.StrictMode>
		<App />
	</React.StrictMode>,
	document.getElementById('root')
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
