import React from 'react';
import { Typography } from '@material-ui/core';
import { useListContext } from 'react-admin';
import PropTypes from 'prop-types';

import Filter from './Filter';
import Tooltip from '../Tooltip';
import AddButton from '../Interface/AddButton';

import { useStyles } from './style';

const HeaderBlock = ({
	title = null,
	link = null,

	additional_button = null,

	is_filter = false,
	is_new = null,
	counter_visible = false,
}) => {
	const classes = useStyles();
	const [counter_helper_text_visible, toggle_counter_helper_text_visible] = React.useState(false);

	const {
		filterValues,
		setFilters, // a callback to update the filters, e.g. setFilters(filters, displayedFilters)
		total,
	} = useListContext();

	const update_filters = (data) => {
		const new_filter_object = Object.create(null);

		data.filters.forEach((tag) => {
			// console.log(tag);
			let tag_data = [];

			if (tag.name && tag.data[0] && tag.data[0].value) {
				tag.data.forEach((tag_item) => {
					if (tag_item.value) tag_data.push(tag_item.value);
				});

				new_filter_object[tag.name] = tag_data;
			}

			if (tag_data.length > 0) {
				return new_filter_object;
			} else return null;
		});

		if (data.search) new_filter_object.search = data.search;

		setFilters(new_filter_object);
	};

	return (
		<div className={classes.headerContainer}>
			<section className={classes.textContainer}>
				{title ? (
					<div className={classes.textContainer__headerContainer}>
						<Typography className={classes.textContainer__header} variant='h1'>
							{title}
						</Typography>
						{counter_visible ? (
							<div className={classes.textContainer__counterContainer}>
								<p
									className={classes.textContainer__counter}
									onMouseOver={() => toggle_counter_helper_text_visible(true)}
									onFocus={() => toggle_counter_helper_text_visible(true)}
									onMouseOut={() => toggle_counter_helper_text_visible(false)}
									onBlur={() => toggle_counter_helper_text_visible(false)}
								>
									{total}
								</p>
								<Tooltip
									text='Количество товаров'
									show={counter_helper_text_visible}
								/>
							</div>
						) : null}
					</div>
				) : null}
				<div className={classes.btnContainer}>
					{additional_button ? additional_button : null}
					{is_new === false ? null : (
						<div className={classes.addButtonContainer}>
							<AddButton is_link={true} link={link ? `/${link}/create` : '/'} />
						</div>
					)}
				</div>
			</section>
			{is_filter ? (
				<Filter update_filters={update_filters} filter_values={filterValues} />
			) : null}
		</div>
	);
};

HeaderBlock.propTypes = {
	title: PropTypes.string,
	link: PropTypes.string,

	is_filter: PropTypes.bool,
	is_new: PropTypes.bool,
	counter_visible: PropTypes.bool,
};

export default HeaderBlock;
