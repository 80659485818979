export const resources = {
	categories: {
		name: 'Категория |||| Категории',
		fields: {
			title: 'Наименование',
		},
	},
	products: {
		name: 'Товар |||| Товары',
		fields: {
			title: 'Наименование',
			price: 'Цена',
			article: 'Артикул',
			main_image: 'Главное изображение',
			state: 'Наличие',
			status: 'Статус',
			store: 'Магазин',
			brand_id: 'Бренд',
		},
	},
	manufacturers: {
		name: 'Производитель |||| Производители',
		fields: {
			title: 'Наименование',
		},
	},
	brands: {
		name: 'Бренд |||| Бренды',
		fields: {
			title: 'Наименование',
			store: 'Магазин',
			short_description: 'Короткое описание',
		},
	},
	product_attributes: {
		name: 'Атрибут |||| Атрибуты',
		fields: {
			title: 'Наименование',
		},
	},
	currencies: {
		name: 'Курс валют |||| Курсы валют',
		fields: {
			iso_code: 'Наименование',
			value: 'Значение',
		},
	},
	sliders: {
		name: 'Карусель |||| Карусели',
		fields: {
			title: 'Наименование',
		},
	},
	services: {
		name: 'Услуга |||| Услуги',
		fields: {
			title: 'Наименование',
			status: 'Статус',
		},
	},
};
