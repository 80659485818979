import { makeStyles } from '@material-ui/core/styles';

import { borderDefault, borderRadius } from '../../../../../const/style';

export const useStyles = makeStyles({
	item: {
		borderBottom: borderDefault,
		borderRadius: borderRadius,
		margin: '0 -12px',
		width: 'calc(100% + 24px)',
		padding: '8px 12px',

		'&:last-child': {
			borderBottom: 'none',
		},
	},

	header: {
		fontSize: 20,
	},

	input: {
		'&:nth-child(n+2)': {
			marginTop: 10,
		},
	},
});
