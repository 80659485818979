import React from 'react';
import PropTypes from 'prop-types';

import Input from '../Input';

import { math_round } from '../../../utils/math';
import { currency_request } from '../../../utils/fetch';

import { useStyles } from './style';

/**
 * @param {Object} record                   record data
 * @param {Array} props_currency_data       currency data from server
 * @param {Boolean} need_currency_request   do currency need an update
 * @param {Function} update_data            update data
 *
 * @class
 */
const Currencies = ({
	record = {},
	props_currency_data = null,
	need_currency_request = true,

	update_data = () => null,
}) => {
	const classes = useStyles();

	const [currencies_data, update_currencies_data] = React.useState([]);
	const [current_currency, change_current_currency] = React.useState(null);
	const [exchange_price, change_exchange_price] = React.useState(null);
	const [calculated_price, change_calculated_price] = React.useState(null);
	const [current_price, change_current_price] = React.useState(null);
	const [markup, change_markup] = React.useState(null);
	const [discount, change_discount] = React.useState(null);

	React.useEffect(() => {
		if (need_currency_request) {
			currency_request().then((new_data) => update_currencies_data(new_data));
		} else update_currencies_data(props_currency_data);
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [props_currency_data]);

	React.useEffect(() => {
		if (currencies_data && currencies_data.length > 0 && !current_currency) {
			let currency_find = false;

			if (record.currency_id) {
				for (let i = 0; i < currencies_data.length; i++) {
					if (+currencies_data[i].id === +record.currency_id) {
						change_current_currency(currencies_data[i]);
						currency_find = true;
						break;
					}
				}

				change_current_price(record.initial_price);
			}

			if (currency_find === false) change_current_currency(currencies_data[0]);
			change_discount(record.discount);
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [currencies_data, record]);

	React.useEffect(() => {
		let new_calculated_price = 0,
			new_exchange_price = 0;

		if (current_price && isFinite(current_price) && current_currency && record) {
			new_exchange_price = +current_price * +current_currency.currency;
			new_calculated_price = +current_price * +current_currency.currency * +markup - discount;
		}

		const new_price_data = {
			initial_price: current_price,
			currency_id: current_currency ? +current_currency.id : 1,
			markup: markup ? +markup : 1,
			discount: discount ? +discount : 0,
		};

		change_calculated_price(new_calculated_price);
		change_exchange_price(new_exchange_price);

		update_data(new_price_data);
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [current_price, current_currency, markup, discount]);

	const change_currency = (id) => {
		if (currencies_data && currencies_data.length > 0) {
			for (let i = 0; i < currencies_data.length; i++) {
				if (+currencies_data[i].id === +id) {
					change_current_currency(currencies_data[i]);
					break;
				}
			}
		}
	};

	return (
		<div className={classes.container}>
			<Input
				name='initial_price'
				type='text'
				change={change_current_price}
				parent_value={current_price || current_price === 0 ? +current_price : null}
				placeholder='Исходная цена'
				dropdown_list={currencies_data}
				dropdown_selected={current_currency ? current_currency.value : null}
				select_dropdown={change_currency}
				only_numbers={true}
			/>
			{current_currency && current_currency.value !== 'BYN' ? (
				<Input
					name='calculated_price'
					type='text'
					change={() => null}
					parent_value={exchange_price ? math_round(exchange_price) : null}
					placeholder={
						current_currency
							? `1 ${current_currency.value} = ${current_currency.currency} BYN`
							: `1 ... = ... BYN`
					}
					disabled_value={'BYN'}
					disabled={true}
					only_numbers={true}
				/>
			) : null}
			<Input
				name='markup'
				type='text'
				change={change_markup}
				parent_value={record ? +record.markup : null}
				placeholder='Коэффициент наценки'
				only_numbers={true}
				// disabled={true}
			/>
			<Input
				name='discount'
				type='text'
				change={change_discount}
				parent_value={record ? +record.discount : null}
				placeholder='Скидка'
				only_numbers={true}
				disabled_value={'BYN'}
			/>
			<Input
				name='price'
				type='text'
				change={() => null}
				parent_value={
					calculated_price || calculated_price === 0 ? math_round(calculated_price) : null
				}
				placeholder='Цена на сайте'
				disabled_value={'BYN'}
				disabled={true}
				only_numbers={true}
			/>
		</div>
	);
};

Currencies.propTypes = {
	record: PropTypes.object,
	props_currency_data: PropTypes.array,
	need_currency_request: PropTypes.bool,
	update_data: PropTypes.func,
};

export default Currencies;
