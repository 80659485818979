import { makeStyles } from '@material-ui/core/styles';

import { borderDefault, borderRadius, color_map } from '../../../../const/style';

export const useStyles = makeStyles({
	brandList: {
		listStyle: 'none',
		border: borderDefault,
		borderRadius: borderRadius,
		backgroundColor: color_map.white,
		padding: 0,
		margin: '6px 0 0 0',
	},
});
