import React from 'react';
import PropTypes from 'prop-types';
import { Draggable } from 'react-beautiful-dnd';

import DragButton from '../../../../Common/Interface/DragButton';
import Input from '../../../../Common/Input';
// import DeleteButton from '../../../../Common/Interface/DeleteButton';
// import AddButton from '../../../../Common/Interface/AddButton';

import { FilterContext } from '..';

import { useStyles } from './style';

/**
 * @param {Object} data     filter data
 * @param {Number} index    index for react-beautiful-dnd (for draggable work)
 *
 * @class
 */
const FilterItem = ({ data = null, index = null }) => {
	const classes = useStyles();

	const { values } = data;

	const { filter_list, update_filter_list } = React.useContext(FilterContext);

	const [values_list, update_values_list] = React.useState([]);

	/**
	 * Update filter values to local state when component is mounting
	 */
	React.useEffect(() => {
		if (values && values.length > 0) {
			const new_values_list = [];

			values.forEach((value) => {
				for (let key in value) {
					new_values_list.push({
						id: key,
						title: value[key],
					});
				}
			});

			update_values_list(new_values_list);
		}
	}, []);

	const add_new_value = () => {};

	const change_value = () => {};

	/**
	 * @param {String} value
	 */
	const change_tip_value = (value) => {
		let new_filter_list = Array.from(filter_list);

		new_filter_list = new_filter_list.map((filter) => {
			if (filter.id === data.id) filter.tip = value;

			return filter;
		});

		update_filter_list(new_filter_list);
	};

	const delete_value = () => {};

	if (!data) return null;
	return (
		<Draggable draggableId={`filter-${data.id}`} index={index}>
			{(provided) => (
				<li
					className={`flex items-center ${classes.item}`}
					ref={provided.innerRef}
					{...provided.draggableProps}
				>
					<DragButton {...provided.dragHandleProps} />

					<div className={'flex flex-col w-full'}>
						<div className='w-full flex-wrap'>
							<Input
								name='title'
								type='text'
								placeholder='Название фильтра'
								parent_value={data.title}
								disabled={true}
								classNames={'w-full'}
							/>
							<Input
								name='tip'
								type='text'
								placeholder='Подсказка для фильтра'
								parent_value={data.tip}
								classNames={'w-full'}
								change={change_tip_value}
							/>
						</div>
						{values_list.length > 0 ? (
							<div className='w-full mt-sm flex-col'>
								<h3 className={classes.header}>Значения</h3>
								{values_list.map((value) => {
									return (
										<div key={value.id} className={`flex ${classes.input}`}>
											<Input
												name='value-title'
												type='text'
												placeholder='Название значения'
												parent_value={value.title}
												disabled={true}
												classNames={'w-full'}
											/>
											{/* <DeleteButton
                                                    action={delete_value}
                                                    parameter_for_action={null} /> */}
										</div>
									);
								})}
								{/* <AddButton action={add_new_value} /> */}
							</div>
						) : null}
					</div>
				</li>
			)}
		</Draggable>
	);
};

FilterItem.propTypes = {
	data: PropTypes.object,
	index: PropTypes.number,
};

export default FilterItem;
