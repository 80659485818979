import merge from 'lodash/merge';
import indigo from '@material-ui/core/colors/indigo';
import pink from '@material-ui/core/colors/pink';
import red from '@material-ui/core/colors/red';

import { defaultTheme } from 'react-admin';

import { borderRadius, color_map } from './const/style';

export const myTheme = merge({}, defaultTheme, {
	palette: {
		primary: indigo,
		secondary: pink,
		error: red,
		contrastThreshold: 3,
		tonalOffset: 0.2,
		background: {
			default: color_map.background_light_blue,
		},
	},
	overrides: {
		RaLayout: {
			// root: {
			//     minWidth: 'initial',
			// },
			content: {
				padding: '0 !important',
				marginLeft: 12,
				// maxWidth: 'calc(100% - 250px - 12px)',
			},
			// contentWithSidebar: {
			//     overflow: 'hidden',
			// },
		},
		RaMenuItemLink: {
			active: {
				backgroundColor: color_map.blue,
				color: color_map.white,

				'&:hover': {
					backgroundColor: color_map.blue,
					color: color_map.white,
				},

				'& svg': {
					color: color_map.white,
				},
			},
			root: {
				// borderLeft: '3px solid #fff', // invisible menu when not active, to avoid scrolling the text when selecting the menu
				borderLeft: '0',
				color: color_map.black,
				transition: 'all .2s ease',
				backgroundColor: color_map.white,
				padding: '5px 14px 6px 14px',

				'&:hover': {
					color: color_map.blue,
					backgroundColor: 'rgba(18, 99, 175, 0.1)',
				},
			},
		},
		MuiTableHead: {
			root: {
				display: 'flex',
			},
		},
		MuiListItemIcon: {
			root: {
				color: 'none',
			},
		},
		RaFormInput: {
			input: {
				width: '100%',
			},
		},
		MuiFormLabel: {
			root: {
				'&.Mui-focused': {
					color: color_map.blue,
				},
			},
		},
		MuiFilledInput: {
			root: {
				border: `1px solid ${color_map.control_gray}`,
				borderRadius: borderRadius,
				backgroundColor: color_map.white,
				color: color_map.black,
				display: 'flex',
				padding: 0,
				fontSize: 14,
				lineHeight: 1.15,
				transition: 'all .2s ease',

				'&::before': {
					borderBottom: 'none !important',
				},

				'&:hover': {
					backgroundColor: 'inherit',
					borderColor: color_map.blue,
				},
			},
			underline: {
				'&::after': {
					borderBottom: 'none',
				},
				'&.Mui-focused': {
					backgroundColor: 'inherit',
					borderColor: color_map.blue,
				},
			},
		},
		MuiInputBase: {
			root: {
				backgroundColor: color_map.white,
				borderRadius: borderRadius,
			},
			input: {
				padding: '12px !important',
			},
		},
		MuiSelect: {
			root: {
				backgroundColor: color_map.white,
			},
			select: {
				borderRadius: borderRadius,

				'&:focus': {
					backgroundColor: 'inherit',
				},
			},
		},
		MuiInputLabel: {
			filled: {
				color: 'rgba(0, 0, 0, 0.6)',
				fontSize: 14,
				lineHeight: 1.15,
				backgroundColor: color_map.white,

				'&.MuiInputLabel-marginDense': {
					transform: 'translate(12px, 14px) scale(1)',
				},
				'&.MuiInputLabel-shrink.MuiInputLabel-marginDense': {
					transform: 'translate(12px, -6px) scale(0.75) !important',
					paddingLeft: 2,
					paddingRight: 2,
				},
			},
		},
		MuiTableRow: {
			root: {
				width: 'calc(100% - 24px)',
			},
		},

		MuiAppBar: {
			colorPrimary: {
				backgroundColor: color_map.white,
				color: color_map.black,
			},
		},
		MuiToolbar: {
			regular: {
				minHeight: '48px !important',
				padding: '0 12px',
			},
		},
		MuiCardContent: {
			root: {
				padding: 12,
			},
		},
		MuiTypography: {
			displayBlock: {
				fontSize: '1rem',
			},
		},
		RaCardContentInner: {
			root: {
				'&:first-child': {
					paddingTop: 12,
					paddingBottom: 52,
				},
			},
		},

		editContainer: {
			height: 'calc(100% + 12px)',
			marginBottom: '-12px',
			borderLeftBottomRadius: 0,
			borderRightBottomRadius: 0,
			overflow: 'hidden',
		},

		RaCreate: {
			root: {
				minHeight: '100%',
			},
			main: {
				minHeight: '100%',
			},
			noActions: {
				marginTop: '0 !important',
			},
		},
		RaEdit: {
			root: {
				minHeight: '100%',
			},
			main: {
				minHeight: '100%',
			},
			noActions: {
				marginTop: '0 !important',
			},
		},

		MuiFormHelperText: {
			root: {
				display: 'none',
			},
		},
		MuiDrawer: {
			root: {
				width: 250,
				maxWidth: 250,
				flexGrow: '1 !important',
			},
		},

		RaToolbar: {
			desktopToolbar: {
				position: 'fixed',
				width: 'calc(100% - 32px - 250px - 4px)',
				bottom: 0,
				right: 12,
				zIndex: 3,
				height: 52,
				padding: 12,
				background: color_map.white,
				border: `1px solid ${color_map.outline_gray}`,
				boxShadow: '0px 1px 5px rgba(0, 0, 0, 0.2)',
			},
		},

		RaSaveButton: {
			button: {
				fontSize: 14,
				lineHeight: 1.15,
				padding: '6px 12px',
				backgroundColor: '#44CB3A',
				color: color_map.white,
				boxShadow: 'none',
				textTransform: 'none',
				border: '1px solid #44CB3A',

				'&:is(:hover, :active)': {
					boxShadow: 'none !important',
					backgroundColor: color_map.white,
					color: '#44CB3A',
				},

				'& svg': {
					display: 'none',
				},
			},
		},
		RaDeleteWithConfirmButton: {
			deleteButton: {
				fontSize: 14,
				lineHeight: 1.15,
				padding: '6px 12px',
				backgroundColor: color_map.red,
				color: color_map.white,
				boxShadow: 'none',
				textTransform: 'none',
				border: `1px solid ${color_map.red}`,

				'&:is(:hover, :active)': {
					boxShadow: 'none !important',
					backgroundColor: color_map.white,
					color: color_map.red,
				},

				'& svg': {
					display: 'none',
				},

				'& span': {
					padding: 0,
				},
			},
		},
	},
	// typography: {
	//     // Use the system font instead of the default Roboto font.
	//     fontFamily: ['-apple-system', 'BlinkMacSystemFont', '"Segoe UI"', 'Arial', 'sans-serif'].join(','),
	// },
	// overrides: {
	//     MuiButton: { // override the styles of all instances of this component
	//         root: { // Name of the rule
	//             color: 'white', // Some CSS
	//         },
	//     },
	// },
});
