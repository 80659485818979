import { makeStyles } from '@material-ui/core/styles';

import { borderColor, borderRadius, color_map } from '../../../../../const/style';

export const useStyles = makeStyles({
	backModal: {
		position: 'absolute',
		left: 0,
		top: 35,
		border: `1px solid ${borderColor}`,
		padding: '4px 8px',
		borderRadius: borderRadius,
		backgroundColor: color_map.white,

		'& p': {
			margin: 0,
		},
	},

	backModalButtonsContainer: {
		marginTop: 8,
		display: 'flex',
	},

	backModal__option: {
		'&:nth-child(n+2)': {
			marginLeft: 8,
		},
	},
});
