export const attributes_trumbs = [
	{
		title: 'Виден',
		value: 'true',
	},
	{
		title: 'Скрыт',
		value: 'false',
	},
];
