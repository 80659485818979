export const attributes_data = [
	'Хладагент',
	'Холодопроизводительность',
	'Воздухопроизводительность',
	'Оттаивание горячим газом',
	'Рекомендуемый объем фургона',
	'Топливо',
	'Теплопроизводительность (мин)',
	'Теплопроизводительность (макс)',
	'Расход топлива (мин)',
	'Расход топлива (макс)',
	'Кол-во нагреваемого воздуха (мин)',
	'Кол-во нагреваемого воздуха (макс)',
	'Теплоноситель',
	'Отопитель',
	'Документы',
	'Кол-во режимов вентилятора',
	'Электронная панель управления',
	'Таймер',
	'Автоматический контроль температуры',
	'Пульт дист.управления',
	'Объём подаваемого воздуха (макс)',
	'Производительность вентилятора конденсатора (макс)',
	'Вес с упаковкой',
	'Гарантия производителя',
	'Холодопроизводительность (мин)',
	'Максимальная мощность вентиляции',
	'Конденсат',
	'Холодопроизводительность (макс)',
	'Температура нагревающей поверхности',
	'Поддерживаемая температура (мин)',
	'Поддерживаемая температура (макс)',
	'Режим работы',
	'Управление',
	'Расход дизельного топлива через подогреватель (макс)',
	'Температура нагревательного элемента (макс)',
	'Маркировка взрывозащиты',
	'Область применения',
	'Удельная мощность',
	'Материал корпуса',
	'Марка авто',
	'Oe№',
	'Кол-во ручейков',
	'Производитель компрессора',
	'Подходит для',
	'Компрессор',
	'Также для',
	'Установочная точка, часы',
	'Тип монтажа',
	'Производитель эквивалента',
	'Серия компрессора',
	'Тип клапана',
	'Число контактов',
	'Тип резьбы',
	'Резьба',
	'Объём холодильной камеры',
	'Изоляция',
	'Уровень шума',
	'Кол-во камер',
	'Замок в двери',
	'Атрибуты',
	'Оригинальный номер',
	'Вес конденсатора',
	'Вес компрессора',
	'Вес накрышного блока',
	'Привод компрессора',
	'Тип',
	'Способ монтажа конденсора',
	'Мощность испарителя',
	'Тип шкива',
	'Расположение подключения',
	'Количество канавок шкива',
	'Расположение патрубков',
	'Вентилятор накрышного блока',
	'Ресивер',
	'Тип отопителя',
	'Автохолодильник',
	'Автокондиционер',
	'Способ монтажа испарителя',
	'Назначение',
	'Аналог',
	'Электромагнитная муфта',
	'Прижимная пластина',
	'Страна производства',
	'Рекомендуемое применение',
	'Температура (мин)',
	'Температура (макс)',
	'Максимальная относительная влажность воздуха',
	'Система охлаждения',
	'Модель авто',
	'Поддерживаемая температура',
	'Тип запчасти',
	'Диапазон давления (мин)',
	'Диапазон давления (макс)',
	'OEM',
	'Код EAN',
	'Количество масла',
	'Тип масла',
	'Тип компрессора',
	'Материал',
	'Номер фитинга',
	'Соединение',
	'Материал фитинга',
	'Материал гайки',
	'Вход / Выход',
	'Датчик давления',
	'Крепёжные отверстия',
	'Выход(-ы) под фитинги',
	'Заправочный порт',
	'Направление потока воздуха',
	'Производительность',
	'Скорость вращения',
	'Применение',
	'Количество шлицев',
	'Посадка',
	'Цвет',
	'Тип корпуса',
	'Кол-во скоростей',
	'Наружная резьба',
	'Низкое давление',
	'Высокое давление',
	'Внутренняя резьба',
	'Разъём',
	'Количество цилиндров',
	'Сливная пробка',
	'Режим теплового потока',
	'Тип батареи',
	'Работа в режиме охлаждения',
	'Работа в режиме заморозки',
	'Температурный тип компрессора',
	'Тип двигателя',
	'Рабочий объём',
	'Тип вентилятора',
	'Форма лопастей',
	'Водонепроницаемые коннекторы',
	'Микроконтроллер плавного старта',
	'Скорость',
	'Скорость (макс)',
	'Монтажное крепление',
	'Контроллер(-ы)',
	'Класс защиты от пыли и влаги',
	'Вид резьбы',
	'Тип конденсора',
	'Вход(-ы) под фитинги',
	'Вид ресивера',
	'Вход для газа',
	'Выход для газа',
	'Выход под датчик давления',
	'Диапазон регулировки температуры',
	'Тип термостата',
	'Вид соединения',
	'Допустимая температура окружающей среды',
	'Вес без упаковки',
	'Тип установки',
	'Крышка',
	'Подключение через прикуриватель',
	'Защита от разряда аккумулятора',
	'Подсветка',
	'Подстаканники',
	'Электромагнитная совместимость',
	'Полезный объём',
	'Диапазон температур',
	'Встроенный аккумулятор холода',
	'EAN код',
	'Плечевой ремень',
	'Защита испарителя',
	'Максимальный угол наклона',
	'Работа от солнечных батарей',
	'Предохранитель',
	'Датчик опрокидывания',
	'Специальные режимы',
	'Регулировка 2-й камеры',
	'Управление через смартфон',
	'USB-разъём',
	'Память настроек',
	'Класс энергоэффективности',
	'Корзина для продуктов',
	'Ручки для переноски',
	'Среднее давление',
	'Серия',
	'Тип ячейки',
	'Каркас',
	'Максимальная нагрузка',
	'Эффективность трансформации',
	'Угол наклона',
	'Диапазон рабочих температур (мин)',
	'Диапазон рабочих температур горючего (мин)',
	'Материал внутреннего слоя',
	'Материал наружного слоя',
	'Армирование',
	'Фактура',
	'Рабочее давление (макс)',
	'Усиление',
	'Минимальный радиус изгиба',
	'Вес',
	'Диапазон рабочих температур горючего (макс)',
	'Диапазон рабочих температур (макс)',
	'Способ монтажа автокондиционера',
	'Кол-во контактов',
	'Кол-во хладагента',
	'Работа в режиме вентилятора',
	'Регулировка потока воздуха',
	'Функция обогрева',
	'Мощность обогрева',
	'Возможность покраски корпуса',
	'Защита от опрокидывания кабины',
	'Функция автоматического выключения',
	'Работа при наклоне',
	'Термостат',
	'Телескопическая ручка',
	'Индикация ошибок',
	'Защита компрессора от перегрева',
	'Установочный комплект',
	'Мощность охлаждения',
	'Реверсивная дверь',
	'Колёса для перевозки',
	'Блок управления',
	'Напряжение',
	'Постоянный ток',
	'Мощность',
	'Потребляемая мощность (мин)',
	'Потребляемая мощность (макс)',
	'Потребление тока (мин)',
	'Потребление тока (макс)',
	'Защитное отключение',
	'Входное электрическое сопротивление',
	'Входное электрическое сопротивление (мин)',
	'Входное электрическое сопротивление (макс)',
	'Мощность (мин)',
	'Мощность (макс)',
	'Энергопотребление',
	'Напряжение DC(постоянный ток)',
	'Напряжение AC(переменный ток)',
	'Ёмкость батареи',
	'Коэф.энергоэффективности',
	'Сопротивление',
	'Сила тока',
	'Напряжение заряда',
	'Габариты автохолодильника (В × Ш × Г)',
	'Габариты холодильной камеры (В × Ш × Г)',
	'Габариты в упаковке (В × Ш × Г)',
	'Длина шлангов',
	'Высота нагревающей поверхности',
	'Диаметр штуцера для забора и отвода дизтоплива (внутренний)',
	'Диаметр штуцера для забора и отвода дизтоплива (наружный)',
	'Размеры стержня (длина)',
	'Размеры стержня (диаметр)',
	'Диаметр отверстия для установки топливозаборника (мин)',
	'Диаметр топливозаборной трубки',
	'Монтажный диаметр',
	'Диаметр кольцевой прокладки',
	'Диаметр центрального резьбового отверстия фильтра',
	'Диаметр шкива',
	'Внутренний диаметр',
	'Наружный диаметр',
	'Высота подшипника',
	'Наружный диаметр колеса',
	'Размеры катушек',
	'Размеры муфты',
	'Диаметр посадочного отверстия',
	'Высота муфты',
	'Глубина посадки',
	'Диаметр',
	'Высота',
	'Посадочная высота',
	'Наружный диаметр сальника',
	'Внутренний диаметр сальника',
	'Толщина',
	'Высота конденсатора',
	'Ширина конденсатора',
	'Глубина конденсатора',
	'Высота компрессора',
	'Ширина компрессора',
	'Глубина компрессора',
	'Длина',
	'Ширина',
	'Размеры накрышного блока',
	'Размеры теплообменника MFC',
	'Посадочный диаметр (мин)',
	'Посадочный диаметр (макс)',
	'Диаметр (мин)',
	'Диаметр (макс)',
	'Габариты испарителя',
	'Габариты автокондиционера',
	'Глубина',
	'Габариты морозильной камеры',
	'Диаметр сечения',
	'Диаметр резьбы',
	'Шаг резьбы',
	'Диаметр трубки',
	'Диаметр ёлки',
	'Диаметр резьбы гайки',
	'Угол',
	'Высота баллона',
	'Объём',
	'Диаметр посадки',
	'Диаметр отверстия под фитинг',
	'Диаметр корпуса',
	'Диаметр крыльчатки',
	'Высота вентилятора',
	'Общая высота',
	'Диаметр отверстия',
	'Диаметр шлицев',
	'Высота втулки',
	'Диаметр втулки',
	'Длина шлицев',
	'Глубина посадки шлица',
	'Посадочный диаметр',
	'Длина с клеммами',
	'Объём в упаковке',
	'Толщина кольца',
	'Объём цилиндров',
	'Размеры',
	'Размер резьбы',
	'Габариты автохолодильника с компрессором',
	'Толщина стенки',
	'Длина кабеля',
	'Габариты в развёрнутом виде',
	'Габариты в сложенном виде',
	'1-е положение (глубина)',
	'2-е положение (глубина)',
	'Длина бухты (макс)',
	'Габариты вентилятора',
	'Габариты отопителя',
	'Высота с винтами',
	'Высота профиля листа',
	'Габариты крепления',
	'Вместимость (бутылок)',
	// 'Номинальное значение входного напряжения',
	'Входное напряжение',
	'Повышенное входное напряжение выключения преобразователя',
	'«Медленное» выключение преобразователя в течение 1 мин при входном напряжении ниже',
	'«Быстрое» выключение преобразователя при входном напряжении ниже',
	'Низкое входное напряжение мгновенного выключения преобразователя',
	'Входное напряжение включения преобразователя, не менее',
	'Напряжение включения преобразователя после выключения по повышенному напряжению',
	'Напряжение включения преобразователя после выключения по низкому напряжению',
	'Номинальный ток потребления инвертора при номинальном напряжении питания',
	'Ток холостого хода: в активном режиме',
	'Ток холостого хода: в режиме энергосбережения «спящий»',
	'Выходное напряжение',
	'Номинальный выходной ток',
	'Максимальный «пусковой» ток для двигательной нагрузки не более',
	'Крест-фактор, не более',
	'Частота выходного напряжения',
	'Форма выходного напряжения',
	'Коэффициент искажения синусоидальности, при номинальном напряжении на входе',
	'Номинальная выходная мощность',
	'Максимальная выходная мощность',
	'Время работы на максимальной выходной мощности',
	'КПД, не менее',
	'Защита от короткого замыкания',
	'Защита от перегрузки',
	'Защита от переполюсовки',
	'Защита от повышения напряжения питания',
	'Защита от повышения входного напряжения',
	'Защита от встречного напряжения',
	'Тепловая защита',
	'Гальваническая развязка',
	'Режим энергосбережения',
	'Защита от импульсных помех',
	'Масса',
	`Габариты инвертора`,
	'Выключение преобразователя при входном напряжении выше',
	'Включение преобразователя при входном напряжении выше',
];
