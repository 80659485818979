import React from 'react';
import {
	DeleteButton,
	Edit,
	ReferenceInput,
	required,
	SaveButton,
	SimpleForm,
	TextInput,
	Toolbar,
	useNotify,
	useRedirect,
	useShowController,
} from 'react-admin';

import SEO from '../../Common/SEO';
import ImagesBlock from '../../Common/ImagesBlock';
import UndoButton from '../../Common/Interface/UndoButton';

import { createEditStyles } from '../../Common/createEditStyle';

export const SliderEdit = (props) => {
	const classes = createEditStyles();

	const [images_data, update_images_data] = React.useState(null);

	const redirect = useRedirect();
	const notify = useNotify();

	const {
		record, // record fetched via dataProvider.getOne() based on the id from the location
	} = useShowController(props);

	React.useEffect(() => {
		// if (record && record.image && record.image.url) update_images_data(record.image.url);
	}, [record]);

	/**
	 * Метод обновления данных изображения
	 */
	const _update_images = (data) => {
		update_images_data(data);
	};

	const on_success = React.useCallback(() => {
		notify(`Карусель удалена`);
		redirect('/sliders');
	}, []);

	const undo_edit = React.useCallback(() => {
		notify(`Редактирование отменено`);
		redirect('/sliders');
	}, []);

	const PostCreateToolbar = (props) => (
		<Toolbar {...props}>
			<SaveButton
				transform={(data) => {
					const new_data = {
						...data,
					};

					delete new_data.id;
					delete new_data.created_at;
					delete new_data.updated_at;

					// добавляем поля изображений для отправки
					if (images_data.add && images_data.add.length > 0)
						new_data.images = [...images_data.add];
					if (images_data.delete && images_data.delete.length > 0)
						new_data.attachment_ids = [...images_data.delete];

					return new_data;
				}}
				submitOnEnter={true}
			/>
			<UndoButton action={undo_edit} />
			<DeleteButton
				style={{ marginLeft: 'auto' }}
				mutationMode='pessimistic'
				onSuccess={on_success}
			/>
		</Toolbar>
	);

	return (
		<Edit {...props} mutationMode={'pessimistic'}>
			<SimpleForm toolbar={<PostCreateToolbar />}>
				<SEO title={record && record.title} />
				<h1 className={classes.pageHeader}>{record && record.title}</h1>
				<TextInput source='title' validate={[required()]} />

				<h2 className={classes.blockHeader}>Изображения</h2>
				<ImagesBlock data={record} action={_update_images} />
			</SimpleForm>
		</Edit>
	);
};
