import React from 'react';
import {
	Create,
	required,
	SaveButton,
	SelectInput,
	SimpleForm,
	TextInput,
	Toolbar,
	useNotify,
	useRedirect,
} from 'react-admin';

import SEO from '../../Common/SEO';
import UndoButton from '../../Common/Interface/UndoButton';

import { item_state } from '../../../data/select/item_state';

import { createEditStyles } from '../../Common/createEditStyle';
import RichText from '../../Common/RichText';
import Services from '../Common/ServiceItems';

export const ServiceCreate = (props) => {
	const classes = createEditStyles();

	const [services_data, update_services_data] = React.useState([]);
	const [rich_data, update_rich_data] = React.useState(null);

	const redirect = useRedirect();
	const notify = useNotify();

	const undo_edit = React.useCallback(() => {
		notify(`Создание отменено`);
		redirect('/services');
	}, []);

	/**
	 * Method to update services list data
	 *
	 * @param {Array} new_data
	 */
	const _update_services = (new_data) => {
		update_services_data(
			new_data.filter(
				(item) => item.title !== null && item.estimated_time !== null && item.price !== null
			)
		);
	};

	const PostCreateToolbar = (props) => (
		<Toolbar {...props}>
			<SaveButton
				transform={(data) => {
					const server_services_data = Object.create(null);

					services_data.forEach((service, index) => {
						if (!service._destroy) {
							server_services_data[index] = {
								title: service.title,
								estimated_time: service.estimated_time,
								price: service.price,
								sort_position: service.sort_position,
							};

							if (service.new) server_services_data[index].new = true;
							if (service.id) server_services_data[index].id = service.id;
						} else {
							server_services_data[index] = {
								id: service.id,
								_destroy: '1',
							};
						}
					});

					const new_data = {
						...data,
						description: rich_data,
						service_items: [],
						service_items_attributes: server_services_data,
					};
					return new_data;
				}}
				submitOnEnter={true}
				label='Создать'
			/>
			<UndoButton action={undo_edit} />
		</Toolbar>
	);

	return (
		<Create {...props}>
			<SimpleForm toolbar={<PostCreateToolbar />}>
				<SEO title={'Создание услуги'} />
				<h1 className={classes.pageHeader}>Создание услуги</h1>
				<TextInput source='title' validate={[required()]} />
				<h2 className={classes.blockHeader}>Описание</h2>
				<RichText update_data={(new_data) => update_rich_data(new_data)} />
				<SelectInput source='status' choices={item_state} />

				<h2 className={classes.blockHeader}>Услуги</h2>
				<Services update_data={_update_services} edit={true} />
			</SimpleForm>
		</Create>
	);
};
