import React from 'react';
import PropTypes from 'prop-types';
import { Draggable } from 'react-beautiful-dnd';

import Input from './Input';
import DeleteButton from '../../../../Common/Interface/DeleteButton';
import DragButton from '../../../../Common/Interface/DragButton';

import { useStyles } from './style';

/**
 * @param {Number} index            element index
 * @param {Number} id               service id
 * @param {Number} temp_id          service temp id (for the new service)
 * @param {String} title            service name
 * @param {String} estimated_time   service estimated_time
 * @param {String} price            service price
 *
 * @param {Boolean} deleted     is element deleted
 *
 * @param {Function} change_action
 * @param {Function} delete_action
 *
 * @class
 */
const Service = ({
	index = null,
	id = null,
	temp_id = null,
	title = '',
	estimated_time = '',
	price = '',

	deleted = false,

	change_action = () => null,
	delete_action = () => null,
}) => {
	const classes = useStyles();

	if (!deleted) {
		return (
			<Draggable draggableId={`service-${id ? id + '' : temp_id + ''}`} index={index}>
				{(provided) => (
					<section
						className={classes.inputsContainer}
						ref={provided.innerRef}
						{...provided.draggableProps}
					>
						<DragButton {...provided.dragHandleProps} />
						<Input
							id={'service-title-' + id}
							value={title ? title : null}
							placeholder={'Название'}
							change_value={(value) =>
								change_action(id ? id : temp_id, value, 'title')
							}
						/>
						<Input
							id={'service-estimated-time-' + id}
							value={estimated_time ? estimated_time : null}
							placeholder={'Время выполнения (в минутах)'}
							change_value={(value) =>
								change_action(id ? id : temp_id, value, 'estimated_time')
							}
						/>
						<Input
							id={'service-price-' + id}
							value={price ? price : null}
							placeholder={'Цена (в рублях)'}
							change_value={(value) =>
								change_action(id ? id : temp_id, value, 'price')
							}
						/>
						<DeleteButton
							action={delete_action}
							parameter_for_action={id ? id : temp_id}
						/>
					</section>
				)}
			</Draggable>
		);
	} else return null;
};

Service.propTypes = {
	index: PropTypes.number,
	id: PropTypes.number,
	temp_id: PropTypes.number,
	title: PropTypes.string,
	estimated_time: PropTypes.string,
	price: PropTypes.string,

	deleted: PropTypes.bool,

	change_action: PropTypes.func,
	delete_action: PropTypes.func,
};

export default React.memo(Service);
