import React from 'react';
import { nanoid } from 'nanoid';

import { value_list } from './const/list';

import { QuantityComponent } from './style';

/**
 * @class
 */
const Quantity = ({
	number = 1,

	action = () => null,
}) => {
	const classes = QuantityComponent();
	const list_ref = React.useRef(null),
		main_button_ref = React.useRef(null);

	const [is_open, toggle_open] = React.useState(false);

	React.useEffect(() => {
		return () => {
			window.removeEventListener('click', window_click_listener);
		};
	}, []);

	React.useEffect(() => {
		if (typeof window !== 'undefined') {
			if (is_open) window.addEventListener('click', window_click_listener, { passive: true });
			else window.removeEventListener('click', window_click_listener);
		}
	}, [is_open]);

	const window_click_listener = (e) => {
		if (e && list_ref?.current) {
			const elem = e.target;

			if (
				elem !== list_ref.current &&
				!list_ref.current.contains(elem) &&
				elem !== main_button_ref.current &&
				!main_button_ref.current.contains(elem)
			)
				toggle_open(false);
		}
	};

	return (
		<div className={`relative`}>
			<button
				type='button'
				aria-labelledby='open/close quantity list'
				className={
					is_open
						? `flex font-bold py-xxs px-xs ${classes.quantityBtn} ${classes.quantityBtn_active}`
						: `flex font-bold py-xxs px-xs ${classes.quantityBtn}`
				}
				onClick={() => toggle_open(!is_open)}
				ref={main_button_ref}
			>
				{number} шт.
			</button>
			{is_open ? (
				<ul className={`flex flex-col py-xs ${classes.list}`} ref={list_ref}>
					{value_list.map((item) => (
						<li className='flex' key={nanoid()}>
							<button
								type='button'
								aria-labelledby='change quantity'
								className={
									item + '' === number + ''
										? `flex w-full py-xxs px-xs items-center h8 ${classes.list__item} ${classes.list__item_active}`
										: `flex w-full py-xxs px-xs items-center h8 ${classes.list__item}`
								}
								onClick={() => action(item)}
							>
								<div
									className={`mr-xxs w-[14px] h-[14px] ${classes.checkContainer}`}
								>
									{item + '' === number + '' ? (
										<svg
											width='14'
											height='14'
											viewBox='0 0 14 14'
											fill='none'
											xmlns='http://www.w3.org/2000/svg'
										>
											<path
												d='M3 6.30769L6.42857 10L11 4'
												stroke='#191919'
												stroke-width='2'
												stroke-linecap='square'
											/>
										</svg>
									) : null}
								</div>
								<span className={`min-w-[16px]`}>{item}</span>
							</button>
						</li>
					))}
				</ul>
			) : null}
		</div>
	);
};

export default Quantity;
