export const pay_method_data = [
	{
		title: 'Наличными',
		value: 'cash',
	},
	{
		title: 'Онлайн',
		value: 'card',
	},
];

export const pay_status_data = [
	{
		title: 'Оплачено',
		value: 'paid',
	},
	{
		title: 'Не оплачено',
		value: 'unpaid',
	},
	{
		title: 'Ошибка оплаты',
		value: 'failed',
	},
];
