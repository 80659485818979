import React from 'react';
import PropTypes from 'prop-types';

import { fade_in, fade_out } from '../../../utils/fade';
import { useStyles } from './style';

/**
 * Компонент подсказки
 *
 * @param {String} text     текст подсказки
 * @param {Boolean} show    значение показа подсказки
 */
const Tooltip = ({ text = '', show = false }) => {
	const classes = useStyles();
	const tooltip = React.useRef();
	const [init, update_init] = React.useState(false);

	React.useEffect(() => {
		update_init(true);
	}, []);

	React.useEffect(() => {
		if (init) {
			const element = tooltip.current;

			if (show && element.attributes.data_show.nodeValue === 'false')
				fade_in(element, 10, 'flex');
			else if (!show && element.attributes.data_show.nodeValue === 'true')
				fade_out(element, 10, 'flex');
		}
	}, [show, init]);

	return (
		<div className={classes.container}>
			<div className={classes.tooltip} ref={tooltip} data_show='false'>
				<p className={classes.tooltip__text}>{text}</p>
			</div>
		</div>
	);
};

Tooltip.propTypes = {
	text: PropTypes.string,
	show: PropTypes.bool,
};

export default Tooltip;
