export const delivery_method_data = {
	// 'pickup-sophia': 'Самовывоз: пер. Софьи Ковалевской',
	// 'pickup-ring': 'Самовывоз: «Кольцо»',
	// 'courier': 'Доставка курьером',
	// 'post': 'Доставка почтой'
	pickup: 'Самовывоз',
	courier: 'Доставка курьером по Минску',
};

export const pay_method_data = {
	card: 'Онлайн',
	cash: 'Наличными при получении',
};

export const payment_state_data = {
	paid: 'Оплачено',
	unpaid: 'Не оплачено',
	failed: 'Ошибка оплаты',
};

export const state_data = {
	pending: 'В обработке',
	delivery: 'Доставка',
	completed: 'Завершён',
	canceled: 'Отменён',
};
