import React from 'react';
import {
	DataProviderContext,
	DeleteButton,
	Edit,
	ReferenceInput,
	required,
	SaveButton,
	SelectInput,
	SimpleForm,
	TextInput,
	Toolbar,
	useNotify,
	useRedirect,
	useRefresh,
	useShowController,
} from 'react-admin';

import SEO from '../../Common/SEO';
import Filters from '../Common/Filters';
import Dropdown from '../../Common/Interface/Dropdown';
import UndoButton from '../../Common/Interface/UndoButton';

import {
	categories_sorter,
	change_sub_categories,
	find_parent_category,
} from '../../../utils/categories_sort';

import { createEditStyles } from '../../Common/createEditStyle';

/**
 * @class
 */
export const CategoryEdit = (props) => {
	const classes = createEditStyles();

	const image_ref = React.createRef();

	const [image_data, update_image_data] = React.useState(null);
	const [parent_category_id, update_parent_category_id] = React.useState(null);
	const [sorted_categories, update_sorted_categories] = React.useState(null);
	const [active_categories, update_active_categories] = React.useState(null);

	const [filter_data, update_filter_data] = React.useState([]);

	const dataProvider = React.useContext(DataProviderContext);

	const redirect = useRedirect();
	const notify = useNotify();
	const refresh = useRefresh();

	const {
		record, // record fetched via dataProvider.getOne() based on the id from the location
	} = useShowController(props);

	/**
	 * Categories processing for Dropdown component
	 */
	React.useEffect(() => {
		if (record) {
			// prepare a categories list
			dataProvider
				.getList('categories', {
					pagination: { page: 1, perPage: 200 },
					sort: { field: 'title', order: 'ASC' },
					filter: {},
				})
				.then(({ data }) => {
					// sort categories list (with subcategories sort)
					let new_categories_data = categories_sorter(data);

					// formatting data for Dropdown component
					new_categories_data = change_sub_categories(new_categories_data);

					// search for parent category
					const new_active_category = find_parent_category(
						new_categories_data,
						record.parent_category_id
					);
					if (new_active_category) {
						update_active_categories([
							{
								id: new_active_category.id,
								title: new_active_category.title,
							},
						]);
					}

					// send new data to the `state`
					update_sorted_categories(new_categories_data);
				});
		}

		refresh();
	}, [record]);

	React.useEffect(() => {
		if (record && record.image && record.image.url) update_image_data(record.image.url);
	}, [record]);

	/**
	 * Dropdown processing
	 *
	 * @param {Array} new_data  dropdown selected array
	 */
	const _dropdown_processing = (new_data) => {
		if (new_data.length > 0) update_parent_category_id(new_data[0].id);
		else update_parent_category_id(null);
	};

	const on_success = React.useCallback(() => {
		notify(`Категория удалена`);
		redirect('/categories');
	}, []);

	const undo_edit = React.useCallback(() => {
		notify(`Редактирование отменено`);
		redirect('/categories');
	}, []);

	const reset_image_data = () => {
		const element = image_ref.current;

		element.value = '';

		update_image_data(null);
	};

	const PostCreateToolbar = (props) => (
		<Toolbar {...props}>
			<SaveButton
				transform={(data) => {
					const new_data = {
						...data,
						image: image_data,
						filters_attributes: filter_data,
						parent_category_id: parent_category_id,
					};

					delete new_data.filters;
					delete new_data.description;
					if (typeof image_data === 'string') delete new_data.image;

					return new_data;
				}}
				submitOnEnter={true}
			/>
			<UndoButton action={undo_edit} />
			<DeleteButton
				style={{ marginLeft: 'auto' }}
				mutationMode='pessimistic'
				onSuccess={on_success}
			/>
		</Toolbar>
	);

	return (
		<Edit {...props} mutationMode={'pessimistic'}>
			<SimpleForm toolbar={<PostCreateToolbar />}>
				<SEO title={record && record.title} />
				<h1 className={classes.pageHeader}>{record && record.title}</h1>
				<TextInput source='title' validate={[required()]} />
				<h2 className={classes.blockHeader}>Родительская категория</h2>
				<Dropdown
					name='Название'
					list_data={sorted_categories}
					active_data={active_categories}
					action={_dropdown_processing}
					max_items={1}
					current_id={record && record.id}
				/>
				<h2 className={classes.blockHeader}>Фильтры</h2>
				<Filters parent_update_data={update_filter_data} />
				<h2 className={classes.blockHeader}>Изображение</h2>
				<div className={classes.dataBlock}>
					<input
						type='file'
						onChange={(event) => update_image_data(event.target.files[0])}
						ref={image_ref}
					/>
					{image_data ? (
						<div className={classes.imagesBlock}>
							<img
								src={
									image_data && typeof image_data !== 'string'
										? URL.createObjectURL(image_data)
										: image_data && typeof image_data === 'string'
										? image_data
										: null
								}
								alt='изображение'
							/>
						</div>
					) : null}
					{/* <button
                        type="button"
                        className='flex self-baseline btn btn-delete mt-sm'
                        onClick={reset_image_data}>
                        Сбросить
                    </button> */}
				</div>
			</SimpleForm>
		</Edit>
	);
};
