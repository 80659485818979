/* eslint-disable import/no-anonymous-default-export */
import { fetchUtils } from 'react-admin';
import { stringify } from 'query-string';

import authProvider from './authProvider';

import { build_form_data } from './utils/form_data';
import { API_URL } from './const/params';

const fetchJson = (url, options = {}) => {
	if (!options.headers) options.headers = new Headers({ Accept: 'application/json' });

	// add your own headers here
	if (localStorage.getItem('auth_token'))
		options.headers.set('Authorization', localStorage.getItem('auth_token'));
	return fetchUtils.fetchJson(url, options);
};

const transform_to_single = {
	categories: 'category',
	products: 'product',
	users: 'user',
	filters: 'filter',
	sliders: 'slider',
	brands: 'brand',
	services: 'service',
	orders: 'order',
};

export default {
	getList: (resource, params) => {
		const { page, perPage } = params.pagination;
		const { field, order } = params.sort;

		const query = {
			sort: JSON.stringify([field, order]),
			page: page,
			per_page: perPage,
		};

		let url = `${API_URL}/${resource}?${stringify(query)}`;

		if (params.filter) {
			// разбираем фильтр на составляющие (поиск должен обрабатываться отдельно)
			if (Object.keys(params.filter).length > 0) {
				const filter_copy = JSON.parse(JSON.stringify(params.filter));

				let search = '',
					main_url = `${API_URL}/${resource}`;

				let filter_url = '',
					prefix = '&';

				// проходим по массиву фильтров и собираем нужные фильтры
				for (let key in filter_copy) {
					if (key !== 'search') {
						if (filter_url === '') prefix = '';
						else prefix = '&';

						if (filter_copy[key].length > 1) {
							const value_arr = filter_copy[key];

							// eslint-disable-next-line no-loop-func
							value_arr.forEach((filter_value, index) => {
								if (index === 0 && filter_url === '') prefix = '';
								else prefix = '&';

								filter_url += `${prefix}${key}[]=${encodeURIComponent(
									filter_value
								)}`;
							});
						} else
							filter_url += `${prefix}${key}=${encodeURIComponent(
								filter_copy[key][0]
							)}`;
					}
				}

				if (filter_copy.search) {
					search = filter_copy.search;

					if (stringify(filter_copy) !== '')
						url = `${main_url}/search?q=${search + ''}&${
							filter_url ? `${filter_url}&` : ''
						}${stringify(query)}`;
					else url = `${main_url}/search?q=${search + ''}&${stringify(query)}`;
				} else {
					url = `${main_url}?${filter_url ? `${filter_url}&` : ''}${stringify(query)}`;
				}
			}
		}

		return fetchJson(url).then(({ headers, json }) => {
			let total = 4500;

			if (headers.get('content-range'))
				total = parseInt(headers.get('content-range').split('*/').pop(), 10);

			return {
				data: json,
				total: total,
			};
		});
	},

	getOne: (resource, params) => {
		return fetchJson(`${API_URL}/${resource}/${params.id}`).then(({ status, json }) => {
			if (status >= 200 && status < 300) return { data: json };
		});
	},

	getMany: (resource, params) => {
		// console.log(params);
		const { page, perPage } = params.pagination;
		let url = `${API_URL}/${resource}?page=${page}&per_page=${perPage}`;

		// if (params && params.filter && params.filters.q) url = `${API_URL}/${resource}/search?q=${params.filters.q}&page=${page}&perPage=100`;

		return fetchJson(url).then(({ status, json }) => {
			if (status >= 200 && status < 300) return { data: json };
		});
	},

	getManyReference: (resource, params) => {
		const { page, perPage } = params.pagination;
		const { field, order } = params.sort;
		const query = {
			sort: JSON.stringify([field, order]),
			range: JSON.stringify([(page - 1) * perPage, page * perPage - 1]),
			// filter: JSON.stringify({
			//     ...params.filter,
			//     [params.target]: params.id,
			// }),
		};
		const url = `${API_URL}/${resource}?${stringify(query)}`;

		return fetchJson(url).then(({ status, headers, json }) => {
			if (status >= 200 && status < 300)
				return {
					data: json,
					total: parseInt(
						headers.get('content-range')
							? parseInt(headers.get('content-range').split('/').pop(), 10)
							: json[resource].length
					),
				};
		});
	},

	update: (resource, params) => {
		let resource_name = null;
		if (transform_to_single[resource]) resource_name = transform_to_single[resource];
		else resource_name = resource;

		let data = params.data;
		if (resource_name) {
			data = {};
			data[resource_name] = params.data;
		}

		let form_data = new FormData();
		build_form_data(form_data, data, resource_name);

		return fetchJson(`${API_URL}/${resource}/${params.id}`, {
			method: 'PATCH',
			body: form_data,
		}).then((res) => {
			authProvider.checkError(res);

			if (199 < res.status < 300) {
				return { data: res.json };
			}
		});
	},

	updateMany: (resource, params) => {
		let resource_name = null;
		if (transform_to_single[resource]) resource_name = transform_to_single[resource];
		else resource_name = resource;

		const filter_params = new URLSearchParams(params.filter);

		let data = params.data;
		if (resource_name) {
			data = {};
			data[resource_name] = params.data;
		}

		let form_data = new FormData();
		build_form_data(form_data, data);

		return fetchJson(`${API_URL}/${resource}?${filter_params}`, {
			method: 'PUT',
			body: form_data,
		}).then((res) => {
			authProvider.checkError(res);

			if (199 < res.status < 300) {
				return { data: res.json };
			}
		});
	},

	create: (resource, params) => {
		let resource_name = null;
		if (transform_to_single[resource]) resource_name = transform_to_single[resource];

		let data = params.data;
		if (resource_name) {
			data = {};
			data[resource_name] = params.data;
		}

		let form_data = new FormData();
		build_form_data(form_data, data);

		return fetchJson(`${API_URL}/${resource}`, {
			method: 'POST',
			// body: `{${resource}:`+JSON.stringify(params.data)+`}`,
			// body: JSON.stringify(params.data)
			body: form_data,
		}).then((res) => {
			authProvider.checkError(res);

			if (199 < res.status < 300) {
				return { data: { ...params.data, id: res.json.id } };
			}
		});
	},

	delete: (resource, params) => {
		return fetchJson(`${API_URL}/${resource}/${params.id}`, {
			method: 'DELETE',
		}).then((res) => {
			authProvider.checkError(res);

			if (199 < res.status < 300) {
				return { data: res.json };
			}
		});
	},

	// deleteMany: (resource, params) => {
	//     const query = {
	//         filter: JSON.stringify({ id: params.ids}),
	//     };
	//     return httpClient(`${API_URL}/${resource}?${stringify(query)}`, {
	//         method: 'DELETE',
	//         body: JSON.stringify(params.data),
	//     }).then(({ json }) => ({ data: json }));
	// },
};
