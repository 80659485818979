/**
 * Плавное появление блока.
 */
export const fade_in = (element, timeout = 10, block_type = 'block') => {
	let op = 0.1; // initial opacity
	element.style.display = block_type;

	let timer = setInterval(function () {
		if (op >= 1) {
			clearInterval(timer);
			if (element.attributes.data_show) element.attributes.data_show.nodeValue = 'true';
		}
		element.style.opacity = op;
		element.style.filter = 'alpha(opacity=' + op * 100 + ')';
		op += op * 0.1;
	}, timeout);
};

/**
 * Плавное исчезание блока.
 */
export const fade_out = (element, timeout = 10, block_type = 'block') => {
	let op = 1; // initial opacity
	element.style.display = block_type;

	let timer = setInterval(function () {
		if (op <= 0.1) {
			clearInterval(timer);
			element.style.display = 'none';
			if (element.attributes.data_show) element.attributes.data_show.nodeValue = 'false';
		}
		element.style.opacity = op;
		element.style.filter = 'alpha(opacity=' + op * 100 + ')';
		op -= op * 0.1;
	}, timeout);
};
