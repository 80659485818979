import { makeStyles } from '@material-ui/core/styles';
import { borderDefault, borderRadius, color_map } from '../../../const/style';

export const useStyles = makeStyles({
	headerContainer: {
		display: 'flex',
		flexDirection: 'column',
		padding: 12,
		backgroundColor: color_map.white,
		borderRadius: borderRadius,
		border: borderDefault,
		marginBottom: 6,
	},
	textContainer: {
		display: 'flex',
		justifyContent: 'space-between',
	},
	textContainer__headerContainer: {
		display: 'flex',
	},
	textContainer__header: {
		fontSize: 30,
		lineHeight: 1.2,
		fontWeight: 600,
	},
	textContainer__counterContainer: {
		display: 'flex',
		position: 'relative',
	},
	textContainer__counter: {
		color: color_map.gray,
		fontSize: 12,
		lineHeight: 1.15,
		margin: '0 0 5px 6px',
		alignSelf: 'end',
		cursor: 'pointer',
	},
	textContainer__createLink: {
		backgroundColor: '#44CB3A',
		borderRadius: borderRadius,
		padding: 6,
		width: 24,
		height: 24,
		transition: 'all .2s ease',

		'&:hover': {
			backgroundColor: '#319B29',
		},
	},
	filterContainer: {
		marginTop: 12,
	},
	searchContainer: {
		display: 'flex',
		position: 'relative',
	},
	searchContainer__input: {
		width: '100%',
		border: `1px solid ${color_map.control_gray}`,
		borderRadius: borderRadius,
		padding: '12px 12px 12px 34px',
		transition: 'all .2s ease',

		'&::placeholder': {
			fontSize: 14,
			lineHeight: 1.2,
			color: 'rgba(0, 0, 0, 0.6);',
		},

		'&:focus': {
			outline: 'none',
			borderColor: color_map.blue,
		},
	},
	searchContainer__searchSubmit: {
		position: 'absolute',
		left: 0,
		height: 'calc(100% - 2px)',
		width: 28,
		backgroundColor: 'transparent',
		border: 'none',
		display: 'flex',
		justifyContent: 'right',
		alignContent: 'center',
		paddingRight: 6,
		paddingTop: 2,

		// '&:hover': {
		//     cursor: 'pointer',
		// },

		'& > img': {
			width: 16,
		},
	},
	hidden: {
		display: 'none',
	},

	btnContainer: {
		display: 'flex',
		alignItems: 'center',
	},

	addButtonContainer: {
		display: 'flex',
		alignItems: 'center',
		marginLeft: 16,
	},
});
