export const TOOLBAR_ACTION_OPTS = {
	bold: {
		type: 'button',
		classNames: 'trix-button trix-button--icon trix-button--icon-bold',
		languageKey: 'bold',
		tabIndex: '-1',
		trixButtonGroup: 'text-tools',
		data: {
			trixAttribute: 'bold',
			trixKey: 'b',
		},
		elementProps: {
			title: 'Жирный',
		},
	},
	italic: {
		type: 'button',
		classNames: 'trix-button trix-button--icon trix-button--icon-italic',
		languageKey: 'italic',
		tabIndex: '-1',
		trixButtonGroup: 'text-tools',
		data: {
			trixAttribute: 'italic',
			trixKey: 'i',
		},
		elementProps: {
			title: 'Наклонный',
		},
	},
	strike: {
		type: 'button',
		classNames: 'trix-button trix-button--icon trix-button--icon-strike',
		languageKey: 'strike',
		tabIndex: '-1',
		trixButtonGroup: 'text-tools',
		data: {
			trixAttribute: 'strike',
		},
		elementProps: {
			title: 'Зачёркнутый',
		},
	},
	link: {
		type: 'button',
		classNames: 'trix-button trix-button--icon trix-button--icon-link',
		languageKey: 'link',
		tabIndex: '-1',
		trixButtonGroup: 'text-tools',
		data: {
			trixAttribute: 'href',
			trixKey: 'k',
			trixAction: 'link',
		},
		elementProps: {
			title: 'Ссылка',
		},
	},
	heading1: {
		type: 'button',
		classNames: 'trix-button trix-button--icon trix-button--icon-heading-1',
		languageKey: 'heading1',
		tabIndex: '-1',
		trixButtonGroup: 'block-tools',
		data: {
			trixAttribute: 'heading1',
		},
		elementProps: {
			title: 'Заголовок 1',
		},
	},
	quote: {
		type: 'button',
		classNames: 'trix-button trix-button--icon trix-button--icon-quote',
		languageKey: 'quote',
		tabIndex: '-1',
		trixButtonGroup: 'block-tools',
		data: {
			trixAttribute: 'quote',
		},
		elementProps: {
			title: 'Цитата',
		},
	},
	code: {
		type: 'button',
		classNames: 'trix-button trix-button--icon trix-button--icon-code',
		languageKey: 'code',
		tabIndex: '-1',
		trixButtonGroup: 'block-tools',
		data: {
			trixAttribute: 'code',
		},
		elementProps: {
			title: 'Код',
		},
	},
	bullet: {
		type: 'button',
		classNames: 'trix-button trix-button--icon trix-button--icon-bullet-list',
		languageKey: 'bullets',
		tabIndex: '-1',
		trixButtonGroup: 'block-tools',
		data: {
			trixAttribute: 'bullet',
		},
		elementProps: {
			title: 'Список без нумерации',
		},
	},
	number: {
		type: 'button',
		classNames: 'trix-button trix-button--icon trix-button--icon-number-list',
		languageKey: 'numbers',
		tabIndex: '-1',
		trixButtonGroup: 'block-tools',
		data: {
			trixAttribute: 'number',
		},
		elementProps: {
			title: 'Список с нумерацией',
		},
	},
	outdent: {
		type: 'button',
		classNames: 'trix-button trix-button--icon trix-button--icon-decrease-nesting-level',
		languageKey: 'outdent',
		tabIndex: '-1',
		trixButtonGroup: 'block-tools',
		data: {
			trixAction: 'decreaseNestingLevel',
		},
		elementProps: {
			title: 'Убрать отступ',
		},
	},
	indent: {
		type: 'button',
		classNames: 'trix-button trix-button--icon trix-button--icon-increase-nesting-level',
		languageKey: 'indent',
		tabIndex: '-1',
		trixButtonGroup: 'block-tools',
		data: {
			trixAction: 'increaseNestingLevel',
		},
		elementProps: {
			title: 'Добавить отступ',
		},
	},
	attachFiles: {
		type: 'button',
		classNames: 'trix-button trix-button--icon trix-button--icon-attach',
		languageKey: 'attachFiles',
		tabIndex: '-1',
		trixButtonGroup: 'file-tools',
		data: {
			trixAction: 'attachFiles',
		},
		elementProps: {
			title: 'Приложить файл',
		},
	},
	undo: {
		type: 'button',
		classNames: 'trix-button trix-button--icon trix-button--icon-undo',
		languageKey: 'undo',
		tabIndex: '-1',
		trixButtonGroup: 'history-tools',
		data: {
			trixAction: 'undo',
			trixKey: 'z',
		},
		elementProps: {
			title: 'Отменить',
		},
	},
	redo: {
		type: 'button',
		classNames: 'trix-button trix-button--icon trix-button--icon-redo',
		languageKey: 'redo',
		tabIndex: '-1',
		trixButtonGroup: 'history-tools',
		data: {
			trixAction: 'redo',
			trixKey: 'shift+z',
		},
		elementProps: {
			title: 'Вернуть1',
		},
	},
};
