import * as React from 'react';
import { DataProviderContext, MenuItemLink, useLogout } from 'react-admin';

import SubMenuContainer from './SubMenuContainer';

import { sub_menu } from '../../../data/menu';

import { useStyles } from './style';

export const Menu = (props) => {
	const classes = useStyles();
	const logout = useLogout();

	const dataProvider = React.useContext(DataProviderContext);

	React.useEffect(() => {
		dataProvider
			.getList('users', {
				pagination: { page: 1, perPage: 200 },
				sort: { field: 'title', order: 'ASC' },
				filter: {},
			})
			.catch((res) => {
				if (+res.status === 401) logout();
			});
	}, []);

	return (
		<nav className={classes.navigationListContainer}>
			<ul className={classes.navigationList}>
				<li className={classes.navigationList__item}>
					<MenuItemLink
						to='/'
						primaryText='Панель управления'
						leftIcon={
							<svg
								width='24'
								height='24'
								viewBox='0 0 24 24'
								xmlns='http://www.w3.org/2000/svg'
							>
								<g fill='currentColor'>
									<path
										fillRule='evenodd'
										clipRule='evenodd'
										d='M7 3C8.86384 3 10.4299 4.27477 10.874 6H19V8H10.874C10.4299 9.72523 8.86384 11 7 11C4.79086 11 3 9.20914 3 7C3 4.79086 4.79086 3 7 3ZM7 9C8.10457 9 9 8.10457 9 7C9 5.89543 8.10457 5 7 5C5.89543 5 5 5.89543 5 7C5 8.10457 5.89543 9 7 9Z'
									/>
									<path
										fillRule='evenodd'
										clipRule='evenodd'
										d='M17 20C15.1362 20 13.5701 18.7252 13.126 17H5V15H13.126C13.5701 13.2748 15.1362 12 17 12C19.2091 12 21 13.7909 21 16C21 18.2091 19.2091 20 17 20ZM17 18C18.1046 18 19 17.1046 19 16C19 14.8954 18.1046 14 17 14C15.8954 14 15 14.8954 15 16C15 17.1046 15.8954 18 17 18Z'
									/>
								</g>
							</svg>
						} // pass the icon here
						exact
						{...props}
					/>
				</li>
				<li className={classes.navigationList__item}>
					<MenuItemLink
						to='/categories'
						primaryText='Категории'
						leftIcon={
							<svg
								width='24'
								height='24'
								viewBox='0 0 24 24'
								xmlns='http://www.w3.org/2000/svg'
							>
								<g fill='currentColor'>
									<path
										fillRule='evenodd'
										clipRule='evenodd'
										d='M4 21H6V11H12V13H20V5H13V3H4V21ZM12 5H6V9H13V11H18V7H12V5Z'
									/>
								</g>
							</svg>
						}
						{...props}
					/>
				</li>
				<li className={classes.navigationList__item}>
					<SubMenuContainer
						icon={
							<svg
								width='24'
								height='24'
								viewBox='0 0 24 24'
								xmlns='http://www.w3.org/2000/svg'
							>
								<g fill='currentColor'>
									<path
										fillRule='evenodd'
										clipRule='evenodd'
										d='M19 4H5C4.44772 4 4 4.44771 4 5V19C4 19.5523 4.44772 20 5 20H19C19.5523 20 20 19.5523 20 19V5C20 4.44772 19.5523 4 19 4ZM22 5C22 3.34315 20.6569 2 19 2H5C3.34315 2 2 3.34315 2 5V19C2 20.6569 3.34315 22 5 22H19C20.6569 22 22 20.6569 22 19V5ZM12 12C14.7614 12 17 9.31371 17 6H15C15 8.56606 13.3309 10 12 10C10.6691 10 9 8.56606 9 6H7C7 9.31371 9.23858 12 12 12Z'
									/>
								</g>
							</svg>
						}
						title='Каталог'
						sub_menu={sub_menu.catalog}
					/>
				</li>
				<li className={classes.navigationList__item}>
					<MenuItemLink
						to='/brands'
						primaryText='Бренды'
						leftIcon={
							<svg
								width='24'
								height='24'
								viewBox='0 0 24 24'
								fill='none'
								xmlns='http://www.w3.org/2000/svg'
							>
								<g fill='currentColor'>
									<path
										fillRule='evenodd'
										clipRule='evenodd'
										d='M6 4C3.79086 4 2 5.79086 2 8V16C2 18.2091 3.79086 20 6 20H18C20.2091 20 22 18.2091 22 16V8C22 5.79086 20.2091 4 18 4H6ZM14 6H10V18H14V6ZM16 6V18H18C19.1046 18 20 17.1046 20 16V8C20 6.89543 19.1046 6 18 6H16ZM6 18H8V6H6C4.89543 6 4 6.89543 4 8V16C4 17.1046 4.89543 18 6 18Z'
									/>
								</g>
							</svg>
						}
						{...props}
					/>
				</li>
				<li className={classes.navigationList__item}>
					<MenuItemLink
						to='/users'
						primaryText='Пользователи'
						leftIcon={
							<svg
								width='24'
								height='24'
								viewBox='0 0 24 24'
								fill='none'
								xmlns='http://www.w3.org/2000/svg'
							>
								<g fill='currentColor'>
									<path d='M14.8283 11.9995L16.2426 13.4137L19.071 10.5853C20.6331 9.02317 20.6331 6.49051 19.071 4.92841C17.5089 3.36631 14.9762 3.36631 13.4141 4.92841L10.5857 7.75684L11.9999 9.17105L14.8283 6.34262C15.6094 5.56157 16.8757 5.56157 17.6568 6.34262C18.4378 7.12367 18.4378 8.39 17.6568 9.17105L14.8283 11.9995Z' />
									<path d='M12 14.828L13.4142 16.2422L10.5858 19.0706C9.02365 20.6327 6.49099 20.6327 4.9289 19.0706C3.3668 17.5085 3.3668 14.9759 4.9289 13.4138L7.75732 10.5853L9.17154 11.9996L6.34311 14.828C5.56206 15.609 5.56206 16.8754 6.34311 17.6564C7.12416 18.4375 8.39049 18.4375 9.17154 17.6564L12 14.828Z' />
									<path d='M14.8284 10.5852C15.2189 10.1947 15.2189 9.5615 14.8284 9.17098C14.4379 8.78046 13.8047 8.78046 13.4142 9.17098L9.17154 13.4136C8.78101 13.8041 8.78101 14.4373 9.17154 14.8278C9.56206 15.2184 10.1952 15.2184 10.5857 14.8278L14.8284 10.5852Z' />
								</g>
							</svg>
						}
						{...props}
					/>
				</li>
				<li className={classes.navigationList__item}>
					<MenuItemLink
						to='/currencies'
						primaryText='Курсы валют'
						leftIcon={
							<svg
								width='24'
								height='24'
								viewBox='0 0 24 24'
								fill='none'
								xmlns='http://www.w3.org/2000/svg'
							>
								<g fill='currentColor'>
									<path
										fill-rule='evenodd'
										clip-rule='evenodd'
										d='M11 19V22H13V19H14C16.2091 19 18 17.2091 18 15C18 12.7909 16.2091 11 14 11H13V7H15V9H17V5H13V2H11V5H10C7.79086 5 6 6.79086 6 9C6 11.2091 7.79086 13 10 13H11V17H9V15H7V19H11ZM13 17H14C15.1046 17 16 16.1046 16 15C16 13.8954 15.1046 13 14 13H13V17ZM11 11V7H10C8.89543 7 8 7.89543 8 9C8 10.1046 8.89543 11 10 11H11Z'
									/>
								</g>
							</svg>
						}
						{...props}
					/>
				</li>
				<li className={classes.navigationList__item}>
					<MenuItemLink
						to='/sliders'
						primaryText='Карусели'
						// leftIcon={
						//     <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
						//         <g fill='currentColor'>
						//             <path fill-rule="evenodd" clip-rule="evenodd" d="M11 19V22H13V19H14C16.2091 19 18 17.2091 18 15C18 12.7909 16.2091 11 14 11H13V7H15V9H17V5H13V2H11V5H10C7.79086 5 6 6.79086 6 9C6 11.2091 7.79086 13 10 13H11V17H9V15H7V19H11ZM13 17H14C15.1046 17 16 16.1046 16 15C16 13.8954 15.1046 13 14 13H13V17ZM11 11V7H10C8.89543 7 8 7.89543 8 9C8 10.1046 8.89543 11 10 11H11Z" />
						//         </g>
						//     </svg>
						//     }
						{...props}
					/>
				</li>
				<li className={classes.navigationList__item}>
					<MenuItemLink
						to='/services'
						primaryText='Услуги'
						// leftIcon={
						//     <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
						//         <g fill='currentColor'>
						//             <path fill-rule="evenodd" clip-rule="evenodd" d="M11 19V22H13V19H14C16.2091 19 18 17.2091 18 15C18 12.7909 16.2091 11 14 11H13V7H15V9H17V5H13V2H11V5H10C7.79086 5 6 6.79086 6 9C6 11.2091 7.79086 13 10 13H11V17H9V15H7V19H11ZM13 17H14C15.1046 17 16 16.1046 16 15C16 13.8954 15.1046 13 14 13H13V17ZM11 11V7H10C8.89543 7 8 7.89543 8 9C8 10.1046 8.89543 11 10 11H11Z" />
						//         </g>
						//     </svg>
						//     }
						{...props}
					/>
				</li>
				<li className={classes.navigationList__item}>
					<MenuItemLink
						to='/orders'
						primaryText='Заказы'
						// leftIcon={
						//     <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
						//         <g fill='currentColor'>
						//             <path fill-rule="evenodd" clip-rule="evenodd" d="M11 19V22H13V19H14C16.2091 19 18 17.2091 18 15C18 12.7909 16.2091 11 14 11H13V7H15V9H17V5H13V2H11V5H10C7.79086 5 6 6.79086 6 9C6 11.2091 7.79086 13 10 13H11V17H9V15H7V19H11ZM13 17H14C15.1046 17 16 16.1046 16 15C16 13.8954 15.1046 13 14 13H13V17ZM11 11V7H10C8.89543 7 8 7.89543 8 9C8 10.1046 8.89543 11 10 11H11Z" />
						//         </g>
						//     </svg>
						//     }
						{...props}
					/>
				</li>
				<li className={classes.navigationList__item}>
					<MenuItemLink
						to='/exports'
						primaryText='Выгрузки'
						// leftIcon={
						//     <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
						//         <g fill='currentColor'>
						//             <path fill-rule="evenodd" clip-rule="evenodd" d="M11 19V22H13V19H14C16.2091 19 18 17.2091 18 15C18 12.7909 16.2091 11 14 11H13V7H15V9H17V5H13V2H11V5H10C7.79086 5 6 6.79086 6 9C6 11.2091 7.79086 13 10 13H11V17H9V15H7V19H11ZM13 17H14C15.1046 17 16 16.1046 16 15C16 13.8954 15.1046 13 14 13H13V17ZM11 11V7H10C8.89543 7 8 7.89543 8 9C8 10.1046 8.89543 11 10 11H11Z" />
						//         </g>
						//     </svg>
						//     }
						{...props}
					/>
				</li>

				{/* <li className={classes.navigationList__item}>
                    <MenuItemLink to="/product_attributes" primaryText="Атрибуты"
                        leftIcon={
                        <svg width="24" height="24" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
                            <g fill='currentColor'>
                                <path d="M8 11C7.44772 11 7 11.4477 7 12C7 12.5523 7.44772 13 8 13H15.9595C16.5118 13 16.9595 12.5523 16.9595 12C16.9595 11.4477 16.5118 11 15.9595 11H8Z" />
                                <path d="M8.04053 15.0665C7.48824 15.0665 7.04053 15.5142 7.04053 16.0665C7.04053 16.6188 7.48824 17.0665 8.04053 17.0665H16C16.5523 17.0665 17 16.6188 17 16.0665C17 15.5142 16.5523 15.0665 16 15.0665H8.04053Z" />
                                <path fillRule="evenodd" clipRule="evenodd" d="M5 3C3.89543 3 3 3.89543 3 5V19C3 20.1046 3.89543 21 5 21H19C20.1046 21 21 20.1046 21 19V5C21 3.89543 20.1046 3 19 3H5ZM7 5H5L5 19H19V5H17V6C17 7.65685 15.6569 9 14 9H10C8.34315 9 7 7.65685 7 6V5ZM9 5V6C9 6.55228 9.44772 7 10 7H14C14.5523 7 15 6.55228 15 6V5H9Z" />
                            </g>
                        </svg>}
                        {...props} />
                </li> */}
				{/* <li className={classes.navigationList__item}>
                    <MenuItemLink to="/manufacturers" primaryText="Производители"
                        leftIcon={
                        <svg width="24" height="24" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
                            <g fill='currentColor'>
                                <path fillRule="evenodd" clipRule="evenodd" d="M6 4C3.79086 4 2 5.79086 2 8V16C2 18.2091 3.79086 20 6 20H18C20.2091 20 22 18.2091 22 16V8C22 5.79086 20.2091 4 18 4H6ZM14 6H10V18H14V6ZM16 6V18H18C19.1046 18 20 17.1046 20 16V8C20 6.89543 19.1046 6 18 6H16ZM6 18H8V6H6C4.89543 6 4 6.89543 4 8V16C4 17.1046 4.89543 18 6 18Z" />
                            </g>
                        </svg>}
                        {...props} />
                </li> */}
			</ul>
		</nav>
	);
};
