import React from 'react';
import { useRecordContext } from 'react-admin';
import PropTypes from 'prop-types';
import { DragDropContext, Droppable } from 'react-beautiful-dnd';

import ServiceItem from './ServiceItem';
import AddButton from '../../../Common/Interface/AddButton';

import { reorder, set_sort_index, sort_elements } from '../../../../utils/drag-n-drop';

import { useStyles } from './style';

export const SelectListContext = React.createContext();

class UpdateWrapper extends React.Component {
	/**
	 * @param {Array} data              data for wrapped components
	 * @param {Function} change_action
	 * @param {Function} delete_action
	 * @constructor
	 */

	shouldComponentUpdate(nextProps) {
		if (
			nextProps.data === this.props.data &&
			nextProps.change_action === this.props.change_action &&
			nextProps.delete_action === this.props.delete_action
		)
			return false;

		return true;
	}

	render() {
		const { data, change_action, delete_action } = this.props;

		if (!data) return null;

		return data.map((service, index) => (
			<ServiceItem
				key={`service-${service.id ? service.id + '' : service.temp_id + ''}`}
				id={service.id}
				title={service.title}
				temp_id={service.temp_id}
				index={index}
				estimated_time={service.estimated_time}
				price={service.price}
				deleted={service._destroy ? true : false}
				change_action={change_action}
				delete_action={delete_action}
			/>
		));
	}

	static defaultProps = {
		data: [],

		change_action: () => null,
		delete_action: () => null,
	};
	static propTypes = {
		data: PropTypes.array,

		change_action: PropTypes.func.isRequired,
		delete_action: PropTypes.func.isRequired,
	};
}

/**
 * Компонент атрибутов при редактировании карточки товара
 *
 * @param {Function} props.update_data  обновление данных атрибутов родителя
 *
 * @class
 */
const Services = (props) => {
	const classes = useStyles();

	//данные продукта
	const record = useRecordContext();

	//внутреннее состояние данных атрибутов (потом передаются родителю)
	const [services_data, update_services_data] = React.useState([]);

	// select menu sync open state (for close other select menu)
	const [was_selects_processed, toggle_selects_processed] = React.useState(true);

	/**
	 * Чтение и обработка данных записи (уже существующих атрибутов)
	 */
	React.useEffect(() => {
		_update_services();
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	/**
	 * Чтение и обработка данных записи (уже существующих атрибутов)
	 */
	React.useEffect(() => {
		_update_services();
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [record]);

	/**
	 * Передача данных родителю при изменении внутренних данных
	 */
	React.useEffect(() => {
		props.update_data(services_data);
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [services_data]);

	/**
	 * Update services list
	 */
	const _update_services = () => {
		if (record) {
			if (record.service_items && record.service_items.length > 0) {
				const new_attrs = record.service_items
					.map((item1) => {
						return {
							id: item1.id,
							title: item1.title,
							sort_position: +item1.sort_position,
							estimated_time: +item1.estimated_time,
							price: +item1.price,
						};
					})
					.sort(sort_elements);

				update_services_data(new_attrs);
			}
		}
	};

	/**
	 * Метод добавления нового атрибута в данные
	 */
	const _add_new_service = () => {
		const temp_arr = Array.from(services_data);

		//создаём новый объект и отправляем его в массив
		let new_attr_object = Object.create({
			temp_id: services_data.length + 100000,
			title: null,
			sort_position: services_data.length,
			estimated_time: null,
			price: null,
			new: true,
		});

		temp_arr.push(new_attr_object);

		update_services_data(temp_arr);
	};

	/**
	 * Метод добавления атрибута из данных
	 */
	const _delete_service = React.useCallback(
		(id) => {
			//делаем копию массива данных
			let temp_data = Array.from(services_data);

			//находим нужный идекс объекта и удаляем его из массива
			const needed_index = services_data.findIndex(
				(elem) => id === elem.id || id === elem.temp_id
			);

			if (temp_data[needed_index].id) {
				temp_data[needed_index] = {
					id: temp_data[needed_index].id,
					_destroy: '1',
				};
			} else temp_data.splice(needed_index, 1);

			update_services_data(temp_data);
			// eslint-disable-next-line react-hooks/exhaustive-deps
		},
		[services_data]
	);

	/**
	 * Изменение внутренних данных при изменении данных в полях
	 *
	 * @param {Number} id    `id` атрибута для изменения
	 * @param {String} value    изменённое значение
	 * @param {String} type     тип поля (имя атрибута или его значение)
	 */
	const _change_services = React.useCallback(
		(id, value, type) => {
			//делаем копию массива данных
			let temp_data = Array.from(services_data);

			//находим индекс атрибута у которого были изменения
			const needed_index = services_data.findIndex(
				(elem) => id === elem.id || id === elem.temp_id
			);

			//в зависимости от типа меняем данные
			if (type === 'title') {
				if (!value) temp_data[needed_index].title = null;
				else if (value) temp_data[needed_index].title = value;
			} else if (type === 'estimated_time') {
				if (!value) temp_data[needed_index].estimated_time = null;
				else if (value) temp_data[needed_index].estimated_time = value;
			} else if (type === 'price') {
				if (!value) temp_data[needed_index].price = null;
				else if (value) temp_data[needed_index].price = value;
			}

			//отправляем новые данные
			update_services_data(temp_data);
			// eslint-disable-next-line react-hooks/exhaustive-deps
		},
		[services_data]
	);

	/**
	 * drag-end post-processing
	 *
	 * @param {Object} result react-beautiful-dnd data
	 * @returns reordered list
	 */
	const _on_drag_end = (result) => {
		// dropped outside the list
		if (!result.destination) {
			return;
		}

		// reorder list
		let items = reorder(services_data, result.source.index, result.destination.index);

		items = set_sort_index(items);

		update_services_data(items);
	};

	return (
		<div className={classes.mainContainer}>
			<SelectListContext.Provider value={{ was_selects_processed, toggle_selects_processed }}>
				<DragDropContext onDragEnd={_on_drag_end}>
					<Droppable droppableId='droppable'>
						{(provided) => (
							<div {...provided.droppableProps} ref={provided.innerRef}>
								<UpdateWrapper
									data={services_data}
									change_action={_change_services}
									delete_action={_delete_service}
								/>
								{provided.placeholder}
							</div>
						)}
					</Droppable>
				</DragDropContext>
			</SelectListContext.Provider>
			<AddButton action={_add_new_service} />
		</div>
	);
};

Services.defaultProps = {
	update_data: null,
};

Services.propTypes = {
	update_data: PropTypes.func,
};

export default Services;
