import React from 'react';

import { useStyles } from './style';

/**
 * @class
 */
const DragButton = (props) => {
	const classes = useStyles();

	return (
		<div {...props} className={classes.btnContainer}>
			<button className={classes.btn} type='button' aria-labelledby='drag'>
				<svg
					width='8'
					height='10'
					viewBox='0 0 8 10'
					fill='none'
					xmlns='http://www.w3.org/2000/svg'
				>
					<rect y='0.5' width='8' height='1' fill='black' fillOpacity='0.3' />
					<rect y='4.5' width='8' height='1' fill='black' fillOpacity='0.3' />
					<rect y='8.5' width='8' height='1' fill='black' fillOpacity='0.3' />
				</svg>
			</button>
		</div>
	);
};

export default React.memo(DragButton);
