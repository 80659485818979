import { makeStyles } from '@material-ui/core/styles';

export const useStyles = makeStyles({
	root: {
		padding: 12,
		height: `100%`,
	},
	header: {
		padding: 0,
		marginTop: 0,
		marginBottom: 18,
		fontWeight: 600,
		fontSize: 30,
		lineHeight: 1.2,
	},
	text: {
		fontSize: 16,
		lineHeight: 1.5,
		marginBottom: 12,
	},
	mainText: {
		marginBottom: 18,
	},
	tirer: {
		color: '#D12D2C',
		marginLeft: 6,
		marginRight: 6,
	},
	disableMargin: {
		margin: 0,
	},
	disablePadding: {
		padding: 0,
	},
	listItem: {
		marginBottom: 12,
		display: 'flex',
		alignItems: 'baseline',
	},
});
