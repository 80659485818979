/**
 * Сортировка по названию
 */
const name_sorter = (a, b) => {
	if (a.title > b.title) return 1;
	else return -1;
};

/**
 * Функция проверки для сортировки категорий по названию
 *
 * @param {Array} sub_categories    массив для проверки
 * @returns []
 */
const sort_subcategories = (sub_categories = []) => {
	let new_categories = JSON.parse(JSON.stringify(sub_categories));

	// если есть вложенные подкатегории, то создаём ещё один рекурсивный вызов функции для подкатегорий
	new_categories = new_categories.map((item) => {
		if (item.sub_categories && item.sub_categories.length > 0)
			item.sub_categories = sort_subcategories(item.sub_categories);

		return item;
	});

	// отправялем массив в сортировку по названию
	new_categories = new_categories.sort(name_sorter);

	return new_categories;
};

//флаг проверки подкатегорий
let sub_push_checker = false;

/**
 * Функция проверки подкатегорий
 *
 * @param {Object} object                главная категория
 * @param {String} needed_id             `id` для подкатегорий
 * @param {Object} sub_category_object   подкатегории
 * @param {Number} category_level        уровень категории
 * @returns {}
 */
const check_subcategory = (
	object = null,
	needed_id = null,
	sub_category_object = null,
	category_level = null
) => {
	let new_object = object;

	if (new_object.id === needed_id) {
		if (!new_object.sub_categories) new_object.sub_categories = [];

		const sub_category_in_parent = new_object.sub_categories.find(
			(sub_category) => sub_category.id === sub_category_object.id
		);

		if (!sub_category_in_parent) {
			sub_push_checker = true;
			new_object.sub_categories.push(
				(sub_category_object = {
					...sub_category_object,
					category_level: category_level + 1,
				})
			);
		}
	} else {
		if (new_object.sub_categories) {
			new_object.sub_categories = new_object.sub_categories.map((sub_category) => {
				sub_category = check_subcategory(
					sub_category,
					sub_category_object.parent_category_id,
					sub_category_object,
					category_level + 1
				);

				return sub_category;
			});
		}
	}

	return new_object;
};

/**
 * Функция сортировки категорий
 *
 * @param {Array} categories    массив несортированных категорий
 * @returns []
 */
export const categories_sorter = (categories = []) => {
	// после того, как данные пришли, формируем данные для работы
	let temp_new_data = [], // временные новые данные
		temp_sub_data = [], // временные данные подкатегорий
		losted_subcategories = []; // временные данные "потерянных" подкатегорий (некоторые подкатегории не отправляются к своим "родителям" и они напрявляются сюда, откуда потом ещё раз идёт проходка в поиске их родителя)

	// сортируем данные на подкатегории и главные категории
	for (const prop in categories) {
		if (categories[prop].parent_category_id === null) temp_new_data.push(categories[prop]);
		else temp_sub_data.push(categories[prop]);
	}

	while (temp_sub_data.length > 0) {
		const init_obj = temp_sub_data[temp_sub_data.length - 1];

		temp_new_data = temp_new_data.map((index) => {
			index = check_subcategory(index, init_obj.parent_category_id, init_obj, 1);

			return index;
		});

		//  если родитель не нашёлся, то отправляем в массив потерянных подкатегорий
		if (sub_push_checker === false) losted_subcategories.push(init_obj);

		//  удаляем элемент из массива
		temp_sub_data.pop();

		sub_push_checker = false;
	}

	//  дополнительно проходим по массиву потерянных подкатегорий и делаем то же самое, что и с обычными
	while (losted_subcategories.length > 0) {
		const init_obj = losted_subcategories[losted_subcategories.length - 1];

		temp_new_data = temp_new_data.map((data) => {
			data = check_subcategory(data, init_obj.parent_category_id, init_obj, 1);

			return data;
		});

		losted_subcategories.pop();
	}

	// вызываем функцию сортировки
	temp_new_data = sort_subcategories(temp_new_data);

	//отправлялем данные в `state`
	return temp_new_data;
};

/**
 *
 *
 * @param {Array} categories
 * @returns categories data array for dropdown
 */
export const change_sub_categories = (categories = null) => {
	if (categories && categories.length > 0) {
		// проходим по массиву данных категорий и форматируем их
		const new_data = categories.map((category) => {
			let new_sub_data = [];

			// если есть подкатегории, то отправляем их в рекурсивную функцию
			if (category.sub_categories && category.sub_categories.length > 0) {
				new_sub_data = change_sub_categories(category.sub_categories);
			}

			// отправляем данные в нужном формате
			return {
				id: category.id,
				title: category.title,
				sub_data: new_sub_data,
			};
		});

		return new_data;
	}
};

/**
 *
 * @param {Object} data
 * @param {*} parent_category_id
 * @returns
 */
export const check_subcategories_for_parent = (data = {}, parent_category_id = null) => {
	let needed_item = null;

	if (parent_category_id && data.id === parent_category_id) needed_item = data;
	else {
		data.sub_data.forEach((item) => {
			let temp_item;

			temp_item = check_subcategories_for_parent(item, parent_category_id);

			if (temp_item) needed_item = temp_item;
		});
	}

	if (needed_item) return needed_item;
};

export const find_parent_category = (data = [], parent_category_id = null) => {
	let needed_item = null;

	if (data.length > 0) {
		data.forEach((item) => {
			let temp_item;

			temp_item = check_subcategories_for_parent(item, parent_category_id);

			if (temp_item) needed_item = temp_item;
		});
	}

	return needed_item;
};
