import React from 'react';
import {
	DeleteButton,
	Edit,
	ReferenceInput,
	required,
	SaveButton,
	SelectInput,
	SimpleForm,
	TextInput,
	Toolbar,
	useNotify,
	useRedirect,
	useRefresh,
	useShowController,
} from 'react-admin';

import SEO from '../../Common/SEO';
import UndoButton from '../../Common/Interface/UndoButton';
import RichText from '../../Common/RichText';
import Services from '../Common/ServiceItems';

import { item_state } from '../../../data/select/item_state';

import { createEditStyles } from '../../Common/createEditStyle';

export const ServiceEdit = (props) => {
	const classes = createEditStyles();

	const [services_data, update_services_data] = React.useState([]);
	const [rich_data, update_rich_data] = React.useState(null);
	const [record_fetched, update_record_fetched] = React.useState(false);

	const refresh = useRefresh();
	const redirect = useRedirect();
	const notify = useNotify();

	const {
		record, // record fetched via dataProvider.getOne() based on the id from the location
	} = useShowController(props);

	React.useEffect(() => {
		if (record) {
			// обработка описания
			if (record.description && (record.description.body || record.description.body === ''))
				update_rich_data(record.description.body);

			update_record_fetched(true);
		}

		refresh();
	}, [record]);

	const on_success = React.useCallback(() => {
		notify(`Услуга удалена`);
		redirect('/services');
	}, []);

	const undo_edit = React.useCallback(() => {
		notify(`Редактирование отменено`);
		redirect('/services');
	}, []);

	/**
	 * Method to update services list data
	 *
	 * @param {Array} new_data
	 */
	const _update_services = (new_data) => {
		update_services_data(
			new_data.filter(
				(item) => item.title !== null && item.estimated_time !== null && item.price !== null
			)
		);
	};

	const PostCreateToolbar = (props) => (
		<Toolbar {...props}>
			<SaveButton
				transform={(data) => {
					const server_services_data = Object.create(null);

					services_data.forEach((service, index) => {
						if (!service._destroy) {
							server_services_data[index] = {
								title: service.title,
								estimated_time: service.estimated_time,
								price: service.price,
								sort_position: service.sort_position,
							};

							if (service.new) server_services_data[index].new = true;
							if (service.id) server_services_data[index].id = service.id;
						} else {
							server_services_data[index] = {
								id: service.id,
								_destroy: '1',
							};
						}
					});

					const new_data = {
						...data,
						// image: image_data,
						description: rich_data,
						service_items: [],
						service_items_attributes: server_services_data,
					};

					delete new_data.id;
					delete new_data.slug;
					delete new_data.sort_position;
					delete new_data.images;
					// if (typeof image_data === 'string') delete new_data.image;

					return new_data;
				}}
				submitOnEnter={true}
			/>
			<UndoButton action={undo_edit} />
			<DeleteButton
				style={{ marginLeft: 'auto' }}
				mutationMode='pessimistic'
				onSuccess={on_success}
			/>
		</Toolbar>
	);

	return (
		<Edit {...props} mutationMode={'pessimistic'}>
			<SimpleForm toolbar={<PostCreateToolbar />}>
				<SEO title={record && record.title} />
				<h1 className={classes.pageHeader}>{record && record.title}</h1>
				<TextInput source='title' validate={[required()]} />

				{record_fetched ? (
					<React.Fragment>
						<h2 className={`${classes.blockHeader}`}>Описание</h2>
						<RichText
							parent_value={rich_data}
							update_data={(new_data) => update_rich_data(new_data)}
						/>
					</React.Fragment>
				) : null}

				<SelectInput source='status' choices={item_state} />

				<h2 className={classes.blockHeader}>Услуги</h2>
				<Services update_data={_update_services} edit={true} />
			</SimpleForm>
		</Edit>
	);
};
