import React from 'react';
import { nanoid } from 'nanoid';
import PropTypes from 'prop-types';
import { DataProviderContext } from 'react-admin';

import DeleteButton from '../../../Common/Interface/DeleteButton';
import AddButton from '../../../Common/Interface/AddButton';
import Input from '../../../Common/Input';
import Quantity from './Quantity';

import { fetch_wrapper } from '../../../../wrappers/fetch_wrapper';

import { API_URL } from '../../../../const/params';

import { createProductListStyles } from './style';

/**
 * @param {Array} list              product list
 * @param {Boolean} can_change      can user change list
 *
 * @param {Function} update_list    update list func
 *
 * @class
 */
const ProductList = ({
	list = [],
	can_change = false,

	update_list = () => null,
}) => {
	const classes = createProductListStyles();
	const dataProvider = React.useContext(DataProviderContext);

	const [is_new_view, toggle_new_view] = React.useState(false);
	const [search_state, update_search_state] = React.useState('');
	const [search_list, update_search_list] = React.useState([]);
	const [selected_product, update_selected_product] = React.useState(null);

	React.useEffect(() => {
		// dataProvider.getList('customers/delivery_methods')
		fetch_wrapper(`${API_URL}/customers/delivery_methods`)
			.then((res) => {
				if (res && res.ok) return res.json();
			})
			.then((res) => console.log(res));
	}, []);

	React.useEffect(() => {
		if (search_state) {
			dataProvider
				.getList('products', {
					pagination: { page: 1, perPage: 20 },
					sort: { field: 'title', order: 'ASC' },
					filter: {
						search: encodeURIComponent(search_state).toLocaleLowerCase(),
					},
				})
				.then(({ data }) => {
					// data processing for the `Input` component search work
					const new_data = data.map((item) => {
						return {
							id: item.id,
							name: item.title,
							price: item.price,
						};
					});

					if (new_data.length > 20) new_data.length = 20;

					// send data to the state
					update_search_list(new_data);
				});
		} else update_search_list([]);
	}, [search_state]);

	const ADD_PRODUCT = () => {
		toggle_new_view(true);
	};

	const SUBMIT_NEW_PRODUCT = () => {
		const new_list = Array.from(list);

		new_list.push({
			...selected_product,
			quantity: 1,
		});

		update_list(new_list);
		toggle_new_view(false);
		_RESET_STATE();
	};

	const CHANGE_QUANTITY = (id = null, number = null) => {
		if (id !== null && number !== null) {
			const new_list = list.map((item) => {
				if (item.id === id) {
					return {
						...item,
						quantity: number,
					};
				} else return item;
			});

			update_list(new_list);
		}
	};

	const DELETE_PRODUCT = (id = null) => {
		if (id) {
			const new_list = list.filter((item) => item.id !== id);

			update_list(new_list);
		}
	};

	const CANCEL_PRODUCT_SEARCH = () => {
		_RESET_STATE();
	};

	const _RESET_STATE = () => {
		toggle_new_view(false);
		update_search_state('');
		update_search_list([]);
		update_selected_product(null);
	};

	return (
		<div className='mt-md max-w-[700px]'>
			<h2 className='h7 font-bold'>Товары</h2>
			<div className={`mt-2sm ${classes.productList}`}>
				<div className='flex py-xs px-2sm'>
					<p className='font-bold dark-gray h9 w-7/12'>Наименование</p>
					<p className='font-bold dark-gray h9 pl-2sm w-2/12'>Количество</p>
					<p className='font-bold dark-gray h9 pl-2sm w-2/12'>Стоимость</p>
					<p className='font-bold dark-gray h9 pl-2sm w-1/12' />
				</div>
				{list && list.length > 0 ? (
					<ul className='flex flex-col'>
						{list.map((product) => (
							<li
								key={nanoid()}
								className={`flex items-center p-2sm ${classes.product}`}
							>
								<div className='flex items-center w-7/12'>
									{/* <img
                                            className={classes.image}
                                            src={product.image}
                                            alt={`${product.title}`} /> */}
									{/* <p className='ml-2sm h8'>{product.name}</p> */}
									<p className='h8'>{product.name}</p>
								</div>
								<div className='pl-2sm w-2/12 items-center'>
									{can_change ? (
										<Quantity
											number={product.quantity}
											action={(number) => CHANGE_QUANTITY(product.id, number)}
										/>
									) : (
										<p className='h8'>{product.quantity} шт.</p>
									)}
								</div>
								<div className='pl-2sm w-2/12 items-center'>
									<p className='h8'>{+product.price * +product.quantity} руб.</p>
								</div>
								{can_change ? (
									<div className='flex items-center pl-2sm w-1/12'>
										<DeleteButton
											action={DELETE_PRODUCT}
											parameter_for_action={product.id}
										/>
									</div>
								) : null}
							</li>
						))}
					</ul>
				) : (
					<p className='py-xs px-2sm'>
						<i>Список пуст...</i>
					</p>
				)}
				{can_change === true ? (
					<div className='ml-2sm mt-2sm'>
						{is_new_view ? (
							<div className='flex flex-col w-full'>
								<p>Выберите товар для добавления в список</p>
								<div className='flex flex-col w-full max-w-[500px] mt-sm'>
									<Input
										name='order_product_search'
										type='text'
										placeholder={'Поиск товара'}
										search_list={search_list}
										change={update_search_state}
										search_select_action={update_selected_product}
									/>
								</div>
								<div className='flex py-sm'>
									{selected_product ? (
										<React.Fragment>
											<p>
												Выбранный товар: <br />
												<b>
													{selected_product.name} -{' '}
													{+selected_product.price} руб.
												</b>
											</p>
										</React.Fragment>
									) : (
										<p className='mt-sm'>Ничего не выбрано</p>
									)}
								</div>
								<div className='flex mt-sm'>
									<button
										type='button'
										aria-labelledby='confirm new product to the order'
										className='btn btn-add'
										disabled={selected_product ? false : true}
										onClick={() => SUBMIT_NEW_PRODUCT()}
									>
										Добавить
									</button>
									<button
										type='button'
										aria-labelledby='cancel new product to the order'
										className='btn btn-delete ml-sm'
										onClick={() => CANCEL_PRODUCT_SEARCH()}
									>
										Отменить
									</button>
								</div>
							</div>
						) : (
							<AddButton action={ADD_PRODUCT} />
						)}
					</div>
				) : null}
			</div>
		</div>
	);
};

ProductList.propTypes = {
	list: PropTypes.array,
	can_change: PropTypes.bool,

	update_list: PropTypes.func,
};

export default ProductList;
