import React from 'react';
import PropTypes from 'prop-types';
import Trix from 'trix';
import { ReactTrixRTEInput, ReactTrixRTEToolbar } from 'react-trix-rte';
import { withStyles } from '@material-ui/core/styles';

import HTMLEditor from './HTMLEditor';

import { TOOLBAR_ACTION_OPTS } from './const/trix-buttons';

import { useStyles } from './style';

/**
 * Компонент описания
 */
class RichText extends React.Component {
	default_value;

	/**
	 * @param {String} parent_value     данные с сервера
	 * @param {Function} update_data    функция обновления данных в родителя (для отправки на сервер)
	 *
	 * @param {String} is_html_first       do app need open the html-editor first
	 * @param {Function} update_html_only   update HTML-only param
	 *
	 * @constructor
	 */
	constructor(props) {
		super(props);

		/**
		 * @param {String} value                    данные описания в состоянии компонента
		 * @param {Boolean} is_youtube_input_view   открыто ли меню вставки ссылки на `youtube`
		 * @param {String} youtube_href             вставленная ссылка на `youtube`
		 *
		 * @param {Boolean} is_ready     is all operations were processed for the Trix editor
		 *
		 * @param {Boolean} is_custom_html_view
		 * @param {String} custom_html              variable for store custom html string
		 */
		this.state = {
			value: null,
			is_youtube_input_view: false,
			youtube_href: null,

			is_ready: false,
			is_editor_open: false,

			is_custom_html_view: false,
			custom_html: '',
		};

		this.handle_change = this.handle_change.bind(this);
		this.handle_click = this.handle_click.bind(this);
		this.handle_custom_html_change = this.handle_custom_html_change.bind(this);
		this.close_html_editor = this.close_html_editor.bind(this);
		this.add_headers_to_trix = this.add_headers_to_trix.bind(this);

		this._set_default_value = this._set_default_value.bind(this);

		this._youtube_click_action = this._youtube_click_action.bind(this);
		this._html_click_action = this._html_click_action.bind(this);

		this.default_value = React.createRef();
	}

	componentDidMount() {
		const { is_html_first } = this.props;

		if (is_html_first === 'true') this.setState({ is_custom_html_view: true });

		// modify a Trix config for correct work inside the Trix
		Trix.config.blockAttributes = {
			...Trix.config.blockAttributes,
			heading2: {
				tagName: 'h2',
				terminal: true,
				breakOnReturn: true,
				group: false,
			},
			heading3: {
				tagName: 'h3',
				terminal: true,
				breakOnReturn: true,
				group: false,
			},
			heading4: {
				tagName: 'h4',
				terminal: true,
				breakOnReturn: true,
				group: false,
			},
			heading5: {
				tagName: 'h5',
				terminal: true,
				breakOnReturn: true,
				group: false,
			},
			heading6: {
				tagName: 'h6',
				terminal: true,
				breakOnReturn: true,
				group: false,
			},
		};

		this._set_default_value();

		document.addEventListener('trix-initialize', this.add_headers_to_trix);
	}

	componentDidUpdate(prevProps, prevState) {
		const { update_data, is_html_first, update_html_only } = this.props;
		const { value, custom_html, is_custom_html_view, is_editor_open } = this.state;

		// обновление данных
		if (prevState.value !== value) update_data(value ? value : null);

		if (custom_html !== '' && is_custom_html_view === false) {
			this.setState({
				custom_html: '',
			});
		}

		if (
			is_custom_html_view === false &&
			prevState.is_custom_html_view === true &&
			is_html_first === 'true'
		)
			update_html_only('false');

		if (
			is_html_first === 'true' &&
			prevProps.is_html_first === 'false' &&
			is_custom_html_view === false
		)
			this.setState({ is_custom_html_view: true });

		if (is_editor_open) {
			const body = document.getElementsByTagName('body')[0];
			if (body) body.style.overflow = 'hidden';
		} else {
			const body = document.getElementsByTagName('body')[0];
			if (body) body.style.overflow = '';
		}
	}

	componentWillUnmount() {
		document.removeEventListener('trix-initialize', this.add_headers_to_trix);
	}

	add_headers_to_trix = (event) => {
		if (event) {
			const { toolbarElement } = event.target;

			const h1Button = toolbarElement.querySelector('[data-trix-attribute=heading1]');

			const h2_button = `<button type="button" class="trix-button" data-trix-attribute="heading2" title="Заголовок 2" tabindex="-1" data-trix-active="">H2</button>`;
			const h3_button = `<button type="button" class="trix-button" data-trix-attribute="heading3" title="Заголовок 3" tabindex="-1" data-trix-active="">H3</button>`;
			const h4_button = `<button type="button" class="trix-button" data-trix-attribute="heading4" title="Заголовок 4" tabindex="-1" data-trix-active="">H4</button>`;

			// checking the buttons and insert them if we don't have it in the DOM tree
			if (!toolbarElement.querySelector('[data-trix-attribute=heading4]'))
				h1Button.insertAdjacentHTML('afterend', h4_button);
			if (!toolbarElement.querySelector('[data-trix-attribute=heading3]'))
				h1Button.insertAdjacentHTML('afterend', h3_button);
			if (!toolbarElement.querySelector('[data-trix-attribute=heading2]'))
				h1Button.insertAdjacentHTML('afterend', h2_button);
		}
	};

	/**
	 * Set default value for the Trix editor (for correct work of custom headers)
	 */
	_set_default_value = () => {
		const { parent_value } = this.props;

		if (parent_value)
			this.default_value.current = `<div bis_skin_checked="1">${parent_value}</div>`;
		else this.default_value.current = '';

		this.setState({
			is_ready: true,
		});
	};

	/**
	 * Метод обновления данных
	 *
	 * @param {Event} event         вызывающее событие
	 * @param {String} new_value    новые данные
	 */
	handle_change = (event, new_value) => {
		this.setState({
			value: new_value,
		});
	};

	/**
	 * Метод обработки нажатия на кнопку добавления ссылки на `youtube`
	 */
	handle_click = () => {
		const { youtube_href } = this.state;

		if (youtube_href && youtube_href.includes('youtube.com/watch?v=')) {
			let youtube_id = youtube_href.split('youtube.com/watch?v=');
			youtube_id = youtube_id[youtube_id.length - 1];
			const embed =
				'<iframe width="420" height="315" src="https://www.youtube.com/embed/' +
				youtube_id +
				'" frameborder="0" allowfullscreen></iframe>';
			const attachment = new Trix.Attachment({ content: embed });
			const editor = document.getElementsByTagName('trix-editor')[0].editorController.editor;
			editor.insertAttachment(attachment);

			this.setState({
				is_youtube_input_view: false,
			});
		}
	};

	/**
	 * Trix HTML processing after work for sending to the server data in the correct form
	 *
	 * @param {Event} event
	 */
	handle_custom_html_change = (event) => {
		const element = event.target;

		// replace all of new string code
		this.setState({
			value: element.value.replaceAll('\n', ''),
		});
	};

	close_html_editor = () => {
		this.setState({
			is_custom_html_view: false,
		});
	};

	/**
	 * @param {ClickEvent} e
	 */
	_youtube_click_action = (e) => {
		if (e.target.classList.value.includes('trix-active')) {
			this.setState({
				is_youtube_input_view: true,
			});
		} else if (!e.target.classList.value.includes('trix-active')) {
			this.setState({
				is_youtube_input_view: false,
			});
		}
	};

	/**
	 * @param {ClickEvent} e
	 */
	_html_click_action = (e) => {
		if (e.target.classList.value.includes('trix-active')) {
			this.setState({
				is_custom_html_view: true,
			});
		} else if (!e.target.classList.value.includes('trix-active')) {
			this.setState({
				is_custom_html_view: false,
			});
		}
	};

	render() {
		const { classes, parent_value } = this.props;
		const {
			is_youtube_input_view,
			is_custom_html_view,
			custom_html,
			is_ready,
			is_editor_open,
		} = this.state;

		const custom_option = {
			...TOOLBAR_ACTION_OPTS,
			youtubeVideo: {
				type: 'button',
				classNames: 'trix-button trix-button--icon trix-button--icon-youtube',
				languageKey: 'youtubeVideo',
				tabIndex: '-1',
				trixButtonGroup: 'file-tools',
				elementProps: {
					onClick: this._youtube_click_action,
					title: 'YouTube',
				},
				data: {
					trixAttribute: 'youtubeVideo',
				},
			},
			customHTML: {
				type: 'button',
				classNames: 'trix-button trix-button--icon trix-button--icon-html',
				languageKey: 'customHTML',
				tabIndex: '-1',
				trixButtonGroup: 'html',
				elementProps: {
					onClick: this._html_click_action,
					title: 'HTML',
				},
				data: {
					trixAttribute: 'customHTML',
				},
			},
		};

		return (
			<React.Fragment>
				{is_editor_open ? (
					<div className={classes.componentContainer}>
						<div className={classes.container}>
							{is_custom_html_view && is_ready ? (
								<HTMLEditor
									update_data={this.handle_custom_html_change}
									close_html_editor={this.close_html_editor}
									custom_html={custom_html}
									parent_value={parent_value}
								/>
							) : is_ready ? (
								<React.Fragment>
									<ReactTrixRTEToolbar
										toolbarId='custom-rich-text-toolbar'
										disableGroupingAction={false}
										customToolbarActions={custom_option}
									/>
									<ReactTrixRTEInput
										toolbarId='custom-rich-text-toolbar'
										placeholder='Просто начните писать...'
										onChange={this.handle_change}
										defaultValue={this.default_value.current}
										isRailsDirectUpload={true}
										onFileAccepted={(e) => e.preventDefault()}
									/>
								</React.Fragment>
							) : null}

							<div className={`flex ${classes.buttonContainer}`}>
								<button
									type='button'
									className={`btn ml-auto`}
									onClick={() => this.setState({ is_editor_open: false })}
								>
									Закрыть
								</button>
							</div>

							{is_youtube_input_view ? (
								<div className={classes.modalContainer}>
									<input
										className={classes.input}
										type='url'
										name='y-href'
										placeholder='Введите адрес видео вида "https://www.youtube.com/watch?v=..."'
										onChange={(e) =>
											this.setState({ youtube_href: e.target.value })
										}
									/>
									<div className={classes.submitContainer}>
										<input
											className={classes.submit}
											type='button'
											data-trix-method='setAttribute'
											value='Добавить'
											onClick={() => this.handle_click()}
										/>
									</div>
								</div>
							) : null}
						</div>
						<div
							className={classes.background}
							onClick={() => this.setState({ is_editor_open: false })}
						/>
					</div>
				) : null}
				<button
					className={`btn mb-md`}
					type='button'
					aria-labelledby='toggle description'
					onClick={() => this.setState({ is_editor_open: true })}
				>
					Открыть редактор
				</button>
			</React.Fragment>
		);
	}

	static defaultProps = {
		parent_value: null,
		update_data: () => null,

		is_html_first: 'false',
		update_html_only: () => null,
	};
	static propTypes = {
		parent_value: PropTypes.string,
		update_data: PropTypes.func,

		is_html_first: PropTypes.string,
		update_html_only: PropTypes.func,
	};
}

export default withStyles(useStyles)(RichText);
