import React from 'react';
import { List, useListContext } from 'react-admin';

import HeaderBlock from '../../Common/HeaderBlock';
import SEO from '../../Common/SEO';

import { export_to_translate, headers_translate, state_translate } from '../const/translate';

import { useStyles } from './style';

/**
 * Exports list component
 */
const Exports = () => {
	const classes = useStyles();
	const { data, ids } = useListContext();
	const [exports_data, update_exports_data] = React.useState(null);

	React.useEffect(() => {
		let new_exports_data = [];

		if (ids.length > 0) {
			for (let key in data) {
				new_exports_data.push(data[key]);
			}
		}

		update_exports_data(new_exports_data);
	}, [data, ids]);

	return (
		<div className={`flex flex-col mt-xs ${classes.listContainer}`}>
			<div className={`flex py-xs px-2sm ${classes.headerContainer}`}>
				<p className={`m-0 w-5/12 ${classes.header}`}>{headers_translate.export_to}</p>
				<p className={`m-0 w-3/12 pl-sm ${classes.header}`}>{headers_translate.amount}</p>
				<p className={`m-0 w-4/12 pl-sm ${classes.header}`}>{headers_translate.state}</p>
				<p className={`m-0 w-4/12 pl-sm ${classes.header}`}>
					{headers_translate.created_at}
				</p>
			</div>
			{exports_data !== null && exports_data.length > 0 ? (
				<ul className={`flex flex-col m-0 p-0 ${classes.exportList}`}>
					{exports_data.map((item) => (
						<li
							key={`${item.id}-${item.export_to}-${item.amount}`}
							className={classes.itemContainer}
						>
							<div className={`flex p-2sm items-center ${classes.item}`}>
								<p className={`m-0 w-5/12 ${classes.item__text}`}>
									{export_to_translate[item.export_to]}
								</p>
								<p className={`m-0 w-3/12 pl-sm ${classes.item__text}`}>
									{item.amount}
								</p>
								<p className={`m-0 w-4/12 pl-sm ${classes.item__text}`}>
									{state_translate[item.state]}
								</p>
								<p className={`m-0 w-4/12 pl-sm ${classes.item__text}`}>
									{new Date(item.created_at).toLocaleString()}
								</p>
							</div>
						</li>
					))}
				</ul>
			) : null}
		</div>
	);
};

export const ExportsList = (props) => {
	const classes = useStyles();

	return (
		<List
			component='div'
			bulkActionButtons={false}
			{...props}
			perPage={300}
			actions={false}
			pagination={null}
		>
			<div className={classes.mainContainer}>
				<SEO title={'Выгрузки'} />
				<HeaderBlock title={'Выгрузки'} link={'exports'} is_new={false} />
				<Exports />
			</div>
		</List>
	);
};
