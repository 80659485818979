import React from 'react';
import PropTypes from 'prop-types';
import { Draggable } from 'react-beautiful-dnd';

import Input from './Input';
import Select from '../../../../Common/Interface/Select';
import DeleteButton from '../../../../Common/Interface/DeleteButton';
import DragButton from '../../../../Common/Interface/DragButton';

import { attributes_trumbs } from './data/select';

import { SelectListContext } from '..';

import { useStyles } from './style';

/**
 * @param {Number} index        element index
 * @param {Number} id           attribute id
 * @param {Number} temp_id      attribute temp id (for the new attribute)
 * @param {String} title        attribute name
 * @param {String} description  attribute description
 *
 * @param {Boolean} deleted     is element deleted
 *
 * @param {Function} change_action
 * @param {Function} delete_action
 *
 * @class
 */
const Attribute = ({
	index = null,
	id = null,
	temp_id = null,
	title = '',
	description = '',
	essential = false,

	deleted = false,

	change_action = () => null,
	delete_action = () => null,
}) => {
	const classes = useStyles();

	const { was_selects_processed, toggle_selects_processed } = React.useContext(SelectListContext);

	// eslint-disable-next-line react-hooks/exhaustive-deps
	const current_select = React.useMemo(
		() => attributes_trumbs.find((attribute) => attribute.value === essential + ''),
		[attributes_trumbs]
	);

	if (deleted) return null;

	return (
		<Draggable draggableId={`attribute-${id ? id + '' : temp_id + ''}`} index={index}>
			{(provided) => (
				<section
					className={classes.inputsContainer}
					ref={provided.innerRef}
					{...provided.draggableProps}>
					<DragButton {...provided.dragHandleProps} />
					<Select
						name='В миниатюре, вверху'
						list={attributes_trumbs}
						selected={current_select}
						tooltip_text='Атрибут виден в миниатюре товара и в карточке товара вверху'
						action={(value) => change_action(id ? id : temp_id, value, 'essential')}
						is_context_processed={was_selects_processed}
						context_toggle_processed={toggle_selects_processed}
					/>
					<Input
						id={'attribute-name-' + id}
						value={title ? title : null}
						placeholder={'Атрибут'}
						attribute={true}
						change_value={(value) => change_action(id ? id : temp_id, value, 'title')}
						attribute_name={true}
					/>
					<Input
						id={'attribute-value-' + id}
						value={description ? description : null}
						placeholder={'Значение'}
						change_value={(value) =>
							change_action(id ? id : temp_id, value, 'description')
						}
					/>
					<DeleteButton action={delete_action} parameter_for_action={id ? id : temp_id} />
				</section>
			)}
		</Draggable>
	);
};

Attribute.propTypes = {
	index: PropTypes.number,
	id: PropTypes.number,
	temp_id: PropTypes.number,
	title: PropTypes.string,
	description: PropTypes.string,

	deleted: PropTypes.bool,

	change_action: PropTypes.func,
	delete_action: PropTypes.func,
};

export default React.memo(Attribute);
