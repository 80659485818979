import React from 'react';
import { useListContext, DataProviderContext } from 'react-admin';
import PropTypes from 'prop-types';
import { DragDropContext, Droppable } from 'react-beautiful-dnd';

import BrandItem from './BrandItem';

import { reorder, set_sort_index, sort_elements } from '../../../../utils/drag-n-drop';

import { useStyles } from './style';

class UpdateWrapper extends React.Component {
	/**
	 * @param {Array} data              data for wrapped components
	 * @constructor
	 */

	shouldComponentUpdate(nextProps) {
		if (nextProps.data === this.props.data) return false;

		return true;
	}

	render() {
		const { data } = this.props;

		if (!data) return null;

		return data.map((brand, index) => (
			<BrandItem
				key={`${brand.title}_${brand.id}`}
				index={index}
				id={brand.id}
				image={brand.image}
				title={brand.title}
			/>
		));
	}

	static defaultProps = {
		data: [],
	};
	static propTypes = {
		data: PropTypes.array,
	};
}

/**
 * Brand list component
 *
 * @class
 */
export const BrandList = () => {
	const classes = useStyles();
	const { data, ids } = useListContext();

	const dataProvider = React.useContext(DataProviderContext);

	const [brands_data, update_brands_data] = React.useState(null);

	React.useEffect(() => {
		let new_brands_data = [];

		if (ids.length > 0) {
			for (let key in data) {
				new_brands_data.push(data[key]);
			}
		}

		update_brands_data(new_brands_data.sort(sort_elements));
	}, [data, ids]);

	/**
	 * drag-end post-processing
	 *
	 * @param {Object} result react-beautiful-dnd data
	 * @returns reordered list
	 */
	const _on_drag_end = (result) => {
		// dropped outside the list
		if (!result.destination) {
			return;
		}

		// reorder list
		let list_with_new_sort_order = reorder(
			brands_data,
			result.source.index,
			result.destination.index
		);

		list_with_new_sort_order = set_sort_index(list_with_new_sort_order);

		update_brands_data(list_with_new_sort_order);

		// dataProvider.updateMany('brands');
	};

	if (!brands_data || brands_data.length === 0) return null;

	return (
		<ul className={`flex flex-col ${classes.brandList}`}>
			<DragDropContext onDragEnd={_on_drag_end}>
				<Droppable droppableId='droppable'>
					{(provided) => (
						<div {...provided.droppableProps} ref={provided.innerRef}>
							<UpdateWrapper data={brands_data} />
							{provided.placeholder}
						</div>
					)}
				</Droppable>
			</DragDropContext>
		</ul>
	);
};

export default BrandList;
