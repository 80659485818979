import React from 'react';
import PropTypes from 'prop-types';

import { fade_in, fade_out } from '../../../../utils/fade';

import { useStyles } from './style';

/**
 * @param {String} text         tooltip text
 * @param {Boolean} is_show
 *
 * @class
 */
const Tooltip = ({ text = '', is_show = false }) => {
	const classes = useStyles();

	const container = React.useRef(null);
	const prev_show = React.useRef(null);

	React.useEffect(() => {
		prev_show.current = false;

		return () => {
			prev_show.current = false;
		};
	}, []);

	React.useEffect(() => {
		const element = container.current;

		if (is_show && prev_show.current === false) fade_in(element);
		else if (!is_show && prev_show.current === true) fade_out(element);

		prev_show.current = is_show;
	}, [is_show]);

	return (
		<div className={classes.container} ref={container}>
			{text}
		</div>
	);
};

Tooltip.propTypes = {
	text: PropTypes.string,
	is_show: PropTypes.bool,
};

export default React.memo(Tooltip);
