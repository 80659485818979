import React from 'react';
import { Link, List, useListContext } from 'react-admin';

import HeaderBlock from '../../Common/HeaderBlock';
// import ImageComponent from '../../Common/ImageComponent';
import SEO from '../../Common/SEO';

import { useStyles } from './style';

/**
 * Seervices list component
 */
const Services = () => {
	const classes = useStyles();
	const { data, ids } = useListContext();
	const [services_data, change_services_data] = React.useState(null);

	React.useEffect(() => {
		let new_services_data = [];

		if (ids.length > 0) {
			for (let key in data) {
				new_services_data.push(data[key]);
			}
		}

		change_services_data(new_services_data);
	}, [data, ids]);

	return (
		<div className={classes.listContainer}>
			{services_data !== null && services_data.length > 0 ? (
				<ul className={classes.serviceList}>
					{services_data.map((service, index) => (
						<li key={index} className={classes.serviceContainer}>
							<Link to={`/services/${service.id}`} className={classes.service}>
								{/* <ImageComponent data={service && service.image && service.images.url ? service.image.url : null} /> */}
								<p className={`${classes.service__text}`}>{service.title}</p>
							</Link>
						</li>
					))}
				</ul>
			) : null}
		</div>
	);
};

export const ServiceList = (props) => {
	const classes = useStyles();

	return (
		<List
			component='div'
			bulkActionButtons={false}
			{...props}
			perPage={300}
			actions={false}
			pagination={null}
		>
			<div className={classes.mainContainer}>
				<SEO title={'Список услуг'} />
				<HeaderBlock title={'Услуги'} link={'services'} />
				<Services />
			</div>
		</List>
	);
};
