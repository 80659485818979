import { makeStyles } from '@material-ui/core/styles';

import { borderColor, borderRadius, color_map } from '../../../../const/style';

export const useStyles = makeStyles({
	buttonsContainer: {
		display: 'flex',
		marginBottom: 10,

		'& button': {
			display: 'flex',
			alignItems: 'center',
			backgroundColor: 'transparent',
			fontSize: 16,
			border: `1px solid ${borderColor}`,
			borderRadius: borderRadius,
			padding: '4px 8px',
			transition: '.2s ease',

			'& img': {
				width: 16,
			},

			'&:is(:hover, :active)': {
				backgroundColor: '#cbeefa',
				cursor: 'pointer',
			},
		},
	},

	showHTMLButton: {
		marginLeft: 'auto',
		fontWeight: 'bold',
	},
	showHTMLButton_active: {
		backgroundColor: '#75d2f2 !important',
	},

	htmlButton: {
		marginLeft: 16,
		backgroundColor: '#cbeefa !important',
		cursor: 'default !important',
		fontWeight: 'bold',
	},

	customHTMLContainer: {
		'& > textarea': {
			height: 200,
			border: `1px solid ${borderColor}`,
			borderRadius: borderRadius,
			width: '100%',
			resize: 'none',
			padding: `4px 8px`,
			outline: 'none',
			background: color_map.black,
			color: color_map.white,
		},
		'& .custom-HTML-show-container': {
			minHeight: 200,
			maxHeight: 500,
			border: `1px solid ${borderColor}`,
			borderRadius: borderRadius,
			width: '100%',
			padding: `4px 8px`,
			background: color_map.white,
			color: color_map.black,
			overflow: 'auto',
		},
	},
});
