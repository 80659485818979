import { makeStyles } from '@material-ui/core/styles';

import { borderRadius, color_map, borderDefault } from '../../../../const/style';

export const useStyles = makeStyles({
	dropdownContainer: {
		position: 'relative',
		margin: '8px 0 4px',
	},

	mainBlock: {
		border: `1px solid ${color_map.control_gray}`,
		borderRadius: borderRadius,
		padding: 12,
		position: 'relative',
		display: 'flex',
		alignItems: 'center',
		transition: '.2s ease',
		zIndex: 2,

		'&:is(:hover, :active)': {
			borderColor: color_map.blue,
			cursor: 'pointer',
		},
	},
	mainBlock_focus: {
		borderColor: color_map.blue,
	},

	dropdownName: {
		fontSize: 14,
		lineHeight: 1.15,
		color: color_map.dark_gray,
		margin: 0,
		position: 'absolute',
		left: 9,
		top: 14,
		padding: '0 2px',
		backgroundColor: color_map.white,
		pointerEvents: 'none',
		transition: '.2s ease',
	},
	dropdownName_active: {
		transform: 'translate(-8px, -23px) scale(0.75) !important',
	},
	dropdownName_focus: {
		color: color_map.blue,
	},

	buttons: {
		display: 'flex',
		marginLeft: 'auto',
	},

	button: {
		padding: 0,
		border: 'none',
		backgroundColor: 'transparent',
		display: 'flex',

		'&:is(:hover, :active)': {
			cursor: 'pointer',
		},

		'& path': {
			transition: '.2s ease',
			fill: '#DFDFDF',
		},
		'&:is(:hover, :active) path': {
			fill: '#1263AF',
		},
	},
	button_clear: {
		marginRight: 6,
	},
	button_open: {
		marginLeft: 6,
	},
	line: {
		borderRight: borderDefault,
	},

	dropdown: {
		position: 'absolute',
		top: '100%',
		zIndex: 5,
		display: 'none',
		background: color_map.white,
		boxShadow: '0px 6.4px 14.4px rgba(0, 0, 0, 0.13), 0px 1.2px 3.6px rgba(0, 0, 0, 0.1)',
		borderRadius: borderRadius,
		width: '100%',
		padding: '6px 0',
		maxHeight: 214,
		overflowY: 'auto',
	},
	dropdown_open: {
		display: 'flex',
	},

	tagList: {
		display: 'flex',
		flexWrap: 'wrap',
		width: '100%',
		minHeight: 20,
	},

	tag: {
		padding: '4px 6px 4px 9px',
		backgroundColor: color_map.blue,
		border: 'none',
		display: 'flex',
		alignItems: 'center',
		color: color_map.white,
		fontSize: 12,
		lineHeight: 1,
		textDecoration: '',
		borderRadius: borderRadius,
		transition: '.2s ease',

		'&:is(:hover, :active)': {
			textDecoration: 'line-through',
			backgroundColor: color_map.bright_blue,
			cursor: 'pointer',
		},

		'&:nth-child(n+2)': {
			marginLeft: 6,
		},
	},

	tag__close: {
		marginLeft: 3,
	},
});
