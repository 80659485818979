import { API_URL } from './const/params';

/* eslint-disable import/no-anonymous-default-export */
export default {
	// called when the user attempts to log in
	login: ({ username, password }) => {
		const request = new Request(`${API_URL}/login`, {
			method: 'POST',
			body: JSON.stringify({
				user: {
					// name: 'Oliver',
					email: username,
					password,
				},
			}),
			headers: new Headers({
				Accept: '*/*',
				'Content-Type': 'application/json',
			}),
			mode: 'cors',
		});
		return fetch(request)
			.then((response) => {
				// console.log(...response.headers);
				const token = response.headers.get('authorization');
				if (response.status < 200 || response.status >= 300) {
					//обработка ошибки
					let error = new Error(response.statusText);
					error.response = response.json(); //передаём в свойство значение ответа, который будет преобразован
					error.status = response.status;
					throw error;
				} else {
					localStorage.setItem('auth_token', token);
					return response.json(); //если ошибки нет, то передаём дальше
				}
			})
			.then((user) => {
				// console.log(user)
				//заносим данные в базу данных и разрешаем вход
				localStorage.setItem('username', user.name);
				localStorage.setItem('email', user.email);
				return Promise.resolve();
			})
			.catch((e) => {
				console.log('Ошибка: ' + e.message);
				e.response.then((res) => {
					//поскольку передаётся только обещание преобразования, то ставим условие на случай, когда обещание будет выполнено (т. е. ответ преобразуется в нужное нам сообщение из сервера)
					console.log('Подробности об ошибке:');
					console.log(res);
				});
				//запрещаем вход
				return e.status;
			});
	},

	// called when the user clicks on the logout button
	logout: () => {
		localStorage.removeItem('username');

		return Promise.resolve();
	},
	// called when the API returns an error
	checkError: ({ status }) => {
		if (status === 401 || status === 403 || status === 400) {
			localStorage.removeItem('username');

			if (typeof window !== undefined) window.location.href = '/';

			return Promise.reject();
		}
		return Promise.resolve();
	},
	// called when the user navigates to a new location, to check for authentication
	checkAuth: () => {
		return localStorage.getItem('username') ? Promise.resolve() : Promise.reject();
	},
	// called when the user navigates to a new location, to check for permissions / roles
	getPermissions: () => Promise.resolve(),
};
