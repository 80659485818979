import React from 'react';
import PropTypes from 'prop-types';

import { useStyles } from './style';

const DeleteButton = ({ action = () => null, parameter_for_action = null }) => {
	const classes = useStyles();

	return (
		<button
			className={classes.deleteButton}
			type='button'
			onClick={() => action(parameter_for_action)}
		>
			<svg
				width='24'
				height='24'
				viewBox='0 0 24 24'
				fill='none'
				xmlns='http://www.w3.org/2000/svg'
			>
				<g fill='currentColor'>
					<path
						fillRule='evenodd'
						clipRule='evenodd'
						d='M7 5V4C7 2.89543 7.89543 2 9 2H15C16.1046 2 17 2.89543 17 4V5H20C20.5523 5 21 5.44772 21 6C21 6.55228 20.5523 7 20 7H19V18C19 19.6569 17.6569 21 16 21H8C6.34315 21 5 19.6569 5 18V7H4C3.44772 7 3 6.55228 3 6C3 5.44772 3.44772 5 4 5H7ZM9 4H15V5H9V4ZM7 7H17V18C17 18.5523 16.5523 19 16 19H8C7.44772 19 7 18.5523 7 18V7Z'
						fillOpacity='0.5'
					/>
					<path d='M15 9H13V17H15V9Z' fillOpacity='0.5' />
					<path d='M11 9H9V17H11V9Z' fillOpacity='0.5' />
				</g>
			</svg>
		</button>
	);
};

DeleteButton.propTypes = {
	action: PropTypes.func,
	parameter_for_action: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
};

export default DeleteButton;
