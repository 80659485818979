import { makeStyles } from '@material-ui/core/styles';

import { borderDefault, borderRadius, color_map } from '../../../const/style';

export const useStyles = makeStyles({
	listContainer: {
		border: borderDefault,
		borderRadius: borderRadius,
		overflow: 'hidden',
	},

	headerContainer: {
		background: color_map.white,
		borderBottom: `1px solid ${color_map.outline_gray}`,
	},
	header: {
		fontWeight: 700,
		fontSize: 12,
		lineHeight: 1.2,
		color: color_map.dark_gray,
	},

	exportList: {
		listStyle: 'none',
		backgroundColor: color_map.white,
	},

	item: {
		// alignItems: 'center',
	},

	itemContainer: {
		borderBottom: `1px solid ${color_map.outline_gray}`,
		transition: '.2s ease',

		'&:last-child': {
			borderBottom: `none`,
		},

		'&:is(:hover, :active)': {
			backgroundColor: color_map.light_gray,
		},
	},

	item__text: {
		fontSize: 14,
		color: color_map.black,
	},
});
