import { makeStyles } from '@material-ui/core/styles';

import { borderRadius, color_map } from '../../../../../../const/style';

export const useStyles = makeStyles({
	inputContainer: {
		display: 'flex',
		position: 'relative',
		width: '100%',
		paddingRight: 12,
	},
	label: {
		position: 'absolute',
		top: 12,
		left: 12,
		fontSize: 14,
		lineHeight: 1.15,
		display: 'flex',
		color: color_map.black,
		backgroundColor: color_map.white,
		zIndex: 1,
		transition: '.2s ease',

		'&:hover': {
			cursor: 'text',
		},
	},
	label_active: {
		top: -4,
		transform: 'translate(12px, -6px) scale(0.75) !important',
		paddingLeft: 2,
		paddingRight: 2,
		left: -7,
	},
	label_focus: {
		color: color_map.blue,
	},
	input: {
		padding: 12,
		border: `1px solid ${color_map.control_gray}`,
		boxSizing: 'border-box',
		borderRadius: borderRadius,
		outline: 'none',
		transition: '.2s ease',
		width: '100%',

		'&:focus': {
			borderColor: color_map.blue,
		},
		'&:hover': {
			borderColor: color_map.blue,
		},
	},

	attributesListContainer: {
		position: 'absolute',
		maxWidth: 300,
		width: '100%',
		borderRadius: borderRadius,
		top: 'calc(100% - 10px)',
		left: 15,
		maxHeight: 200,
		overflow: 'auto',
		backgroundColor: color_map.white,
		zIndex: 2,
		border: `1px solid ${color_map.control_gray}`,
		padding: '12px 0',
	},
	attributesList: {
		margin: 0,
		padding: 0,
		listStyle: 'none',
		display: 'flex',
		flexDirection: 'column',
	},
	attributesList__item: {
		padding: '5px 12px',
		transition: '.2s ease',

		'&:hover': {
			backgroundColor: color_map.blue,
			cursor: 'pointer',
			color: color_map.white,
		},
	},
	attributesListContainer__text: {
		margin: 0,
		padding: '0 12px',
	},
});
