import React from 'react';
import {
	DeleteButton,
	Edit,
	required,
	SaveButton,
	SimpleForm,
	TextInput,
	Toolbar,
	useNotify,
	useRedirect,
	useShowController,
} from 'react-admin';

import SEO from '../../Common/SEO';
import UndoButton from '../../Common/Interface/UndoButton';

import { createEditStyles } from '../../Common/createEditStyle';

export const CurrencyEdit = (props) => {
	const classes = createEditStyles();

	const redirect = useRedirect();
	const notify = useNotify();

	const {
		record, // record fetched via dataProvider.getOne() based on the id from the location
	} = useShowController(props);

	const on_success = React.useCallback(() => {
		notify(`Валюта удалена`);
		redirect('/currencies');
	}, []);

	const undo_edit = React.useCallback(() => {
		notify(`Редактирование отменено`);
		redirect('/currencies');
	}, []);

	const PostCreateToolbar = (props) => (
		<Toolbar {...props}>
			<SaveButton submitOnEnter={true} disabled />
			<UndoButton action={undo_edit} />
			{/* <DeleteButton
                style={{ marginLeft: 'auto' }}
                mutationMode="pessimistic"
                onSuccess={on_success} /> */}
		</Toolbar>
	);

	return (
		<Edit {...props} mutationMode={'pessimistic'}>
			<SimpleForm toolbar={<PostCreateToolbar />}>
				<SEO title={record && record.iso_code && `Редактирование ${record.iso_code}`} />
				<h1 className={classes.pageHeader}>
					{record && record.iso_code && `Редактирование ${record.iso_code}`}
				</h1>
				<TextInput source='value' inputMode='decimal' validate={[required()]} />
			</SimpleForm>
		</Edit>
	);
};
