import React from 'react';
import PropTypes from 'prop-types';

import { color_map } from '../../../const/style';

import { useStyles } from './style';

/**
 * Текстовое поле с ограниченным числом ввода
 *
 * @param {String} name                     имя поля
 * @param {String} title                    заголовок поля
 * @param {String} parent_value             значение поля от родителя (с сервера)
 * @param {Number} maxLength                максимальное число ввода
 *
 * @param {String} view                     'block' / 'full'
 *
 * @param {Function} update_parent_value    функция обновления значения в родителе
 */
const TextArea = ({
	name = '',
	title = 'Текст',
	parent_value = null,
	maxLength = 255,

	view = 'block',

	update_parent_value = () => null,
}) => {
	const classes = useStyles();
	const [value, update_value] = React.useState('');
	const [title_on_top, toggle_title_on_top] = React.useState(false);
	const [focus, toggle_focus] = React.useState(false);

	const title_block = React.useRef(null);
	const textarea_block = React.useRef(null);

	React.useEffect(() => {
		if (parent_value) {
			update_value(parent_value);
		}
	}, []);

	React.useEffect(() => {
		if (!title_on_top && value !== '') toggle_title_on_top(true);
		else if (title_on_top && !value && !focus) toggle_title_on_top(false);
	}, [value]);

	React.useEffect(() => {
		if (focus && !value) toggle_title_on_top(true);
		else if (!focus && !value) toggle_title_on_top(false);

		if (focus) {
			title_block.current.style.color = color_map.blue;
			textarea_block.current.style.borderColor = color_map.blue;
		} else {
			title_block.current.style.color = '';
			textarea_block.current.style.borderColor = '';
		}
	}, [focus]);

	const update_value_proc = (new_value = '') => {
		update_value(new_value);
		update_parent_value(new_value);
	};

	return (
		<div
			className={
				view === 'full'
					? `${classes.textAreaContainer} h-full flex flex-col`
					: `${classes.textAreaContainer} flex flex-col`
			}
		>
			<div
				className={
					view === 'full' ? `flex flex-col relative h-full` : `flex flex-col relative`
				}
			>
				<label
					for={name}
					className={
						title_on_top
							? `${classes.textArea__title} ${classes.textArea__title_open}`
							: classes.textArea__title
					}
					ref={title_block}
				>
					{title}
				</label>
				<textarea
					id={name}
					name={name}
					type='text'
					className={
						view === 'full'
							? `${classes.textArea__input} h-full`
							: `${classes.textArea__input} ${classes.textArea__input_notFull}`
					}
					onChange={(e) => update_value_proc(e.target.value)}
					maxLength={maxLength ? +maxLength : null}
					onFocus={() => toggle_focus(true)}
					onBlur={() => toggle_focus(false)}
					ref={textarea_block}
					value={value}
				/>
			</div>
			{maxLength ? (
				<p className={classes.textArea_maxLength}>{maxLength - value.length}</p>
			) : null}
		</div>
	);
};

TextArea.propTypes = {
	name: PropTypes.string.isRequired,
	title: PropTypes.string.isRequired,
	parent_value: PropTypes.string,
	maxLength: PropTypes.number,

	view: PropTypes.string,

	update_parent_value: PropTypes.func,
};

export default TextArea;
