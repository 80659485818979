import React from 'react';
import PropTypes from 'prop-types';
import { DragDropContext, Droppable } from 'react-beautiful-dnd';
import { nanoid } from 'nanoid';

import AddButton from '../Interface/AddButton';
import ImageBlock from './ImageBlock';

import { reorder, set_sort_index, sort_elements } from '../../../utils/drag-n-drop';

import { useStyles } from './style';

class UpdateWrapper extends React.Component {
	/**
	 * @param {Array} data              data for wrapped components
	 * @param {Function} change_action
	 * @param {Function} delete_action
	 * @param {Function} update_caption
	 * @constructor
	 */

	shouldComponentUpdate(nextProps) {
		// if (
		//     nextProps.data === this.props.data &&
		//     nextProps.change_action === this.props.change_action &&
		//     nextProps.delete_action === this.props.delete_action
		// ) return false;

		// return true;
		let is_need_to_update = false;

		if (nextProps.data?.length !== this.props.data?.length) is_need_to_update = true;
		else {
			for (let i = 0; i < nextProps.data.length; i++) {
				if (
					nextProps.data[i].attachment_id !== this.props.data[i].attachment_id ||
					nextProps.data[i].sort_position !== this.props.data[i].sort_position ||
					nextProps.data[i].url !== this.props.data[i].url
				) {
					is_need_to_update = true;
					break;
				}
			}
		}

		if (is_need_to_update) return true;
		else return false;
	}

	render() {
		const { data, change_action, delete_action, update_caption } = this.props;

		if (!data) return null;

		return data.map((image, index) => (
			<ImageBlock
				key={nanoid()}
				data={image}
				update_method={change_action}
				delete_method={delete_action}
				update_caption={update_caption}
				index={index}
			/>
		));
	}

	static defaultProps = {
		data: [],

		change_action: () => null,
		delete_action: () => null,
		update_caption: () => null,
	};
	static propTypes = {
		data: PropTypes.array,

		change_action: PropTypes.func.isRequired,
		delete_action: PropTypes.func.isRequired,
		update_caption: PropTypes.func,
	};
}

/**
 * Компонент списка изображений
 *
 * @param {Object} data
 * @param {Function} action
 *
 * @param {Boolean} sorting
 * @param {Boolean} caption
 *
 * @class
 */
const ImagesBlock = ({
	data = null,
	action = () => null,

	sorting = false,
	caption = false,
}) => {
	const classes = useStyles();
	const [state_data, update_state_data] = React.useState([]);
	const [data_to_send, update_data_to_send] = React.useState({
		add: [],
		delete: [],
		list: [],
	});

	const upload_input = React.useRef(null);

	/**
	 * Processing image data for sort work
	 */
	React.useEffect(() => {
		if (data) {
			const temp_data = Array.from(data);
			update_state_data(temp_data.sort(sort_elements));

			update_data_to_send({
				...data_to_send,
				list: temp_data.sort(sort_elements),
			});
		}
	}, [data]);

	/**
	 * Processing data for sending to the server
	 */
	React.useEffect(() => {
		const list_to_the_server = state_data.map((item) => {
			return {
				attachment_id: item.attachment_id,
				sort_position: item.sort_position,
				caption: item.caption,
			};
		});
		update_data_to_send({
			...data_to_send,
			list: list_to_the_server,
		});
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [state_data]);

	/**
	 * Sending data to the parent component (for sending to the server)
	 */
	React.useEffect(() => {
		action(data_to_send);
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [data_to_send]);

	/**
	 * Image update func
	 *
	 * @param {Number} index    needed image index to update
	 * @param {File} file       new image file
	 */
	const update_image = (index = null, file = null) => {
		if (index !== null && index !== undefined) {
			let new_data = [];

			if (state_data) new_data = JSON.parse(JSON.stringify(state_data));

			const new_data_to_send = Object.create(null);
			new_data_to_send.add = data_to_send.add;
			new_data_to_send.delete = data_to_send.delete;

			new_data_to_send.add.push(file);

			if (new_data[index].url) {
				new_data_to_send.delete.push(new_data[index].attachment_id);

				new_data[index].url = null;
				new_data[index].sort_position = null;
				new_data[index].caption = null;
			}

			new_data[index].file = file;
			update_state_data(new_data);

			update_data_to_send(new_data_to_send);
		}
	};

	/**
	 * Update few images func
	 *
	 * @param {Array} index_arr         image index array to update
	 * @param {Array} files_arr         new image files
	 * @param {Array} new_state_data    new image state data
	 */
	const update_images = (index_arr = [], files_arr = [], new_state_data = null) => {
		if (index_arr?.length > 0 && files_arr?.length > 0 && new_state_data) {
			// let new_data = JSON.parse(JSON.stringify(new_state_data));

			const new_data_to_send = Object.create(null);
			new_data_to_send.add = data_to_send.add;
			new_data_to_send.delete = data_to_send.delete;

			for (let i = 0; i < files_arr.length; i++) {
				new_data_to_send.add.push(files_arr[i]);

				// const index = index_arr[i];

				// if (new_data[index].url) {
				//     new_data_to_send.delete.push(new_data[index].attachment_id);

				//     new_data[index].url = null;
				//     new_data[index].sort_position = null;
				//     new_data[index].caption = null;
				// }
			}

			update_data_to_send(new_data_to_send);
		}
	};

	/**
	 * Delete image func
	 *
	 * @param {Number} index    image index to delete
	 */
	const delete_image = (index = null) => {
		if (index !== null && index !== undefined) {
			let new_data = [];
			state_data.forEach((item) => new_data.push(item));

			const new_data_to_send = Object.create(null);
			new_data_to_send.add = data_to_send.add;
			new_data_to_send.delete = data_to_send.delete;

			if (new_data[index].url) new_data_to_send.delete.push(new_data[index].attachment_id);
			else if (new_data[index].file) {
				const object_index = new_data_to_send.add.findIndex(
					(item) => item.name === new_data[index].file.name
				);

				new_data_to_send.add.splice(object_index, 1);
			}

			new_data.splice(index, 1);

			update_state_data(new_data);
			update_data_to_send(new_data_to_send);
		}
	};

	/**
	 * drag-end post-processing
	 *
	 * @param {Object} result react-beautiful-dnd data
	 * @returns reordered list
	 */
	const _on_drag_end = (result) => {
		if (sorting) {
			// dropped outside the list
			if (!result.destination) {
				return;
			}

			// reorder list
			let reordered_list = reorder(state_data, result.source.index, result.destination.index);

			reordered_list = set_sort_index(reordered_list);

			update_state_data(reordered_list);
		}
	};

	/**
	 *
	 * @param {Number} attachment_id    attachment id for the new caption
	 * @param {String} text             new caption value
	 */
	const update_caption = (attachment_id = null, text = null) => {
		if (attachment_id !== null && attachment_id !== undefined) {
			let is_need_to_update = false;

			// build new state array
			const new_state_data = state_data.map((item) => {
				let temp_caption = item.caption;
				if (item.attachment_id === attachment_id && temp_caption !== text) {
					is_need_to_update = true;

					if (text === null || text === '') temp_caption = null;
					else temp_caption = text;
				}

				return {
					...item,
					caption: temp_caption,
				};
			});

			if (is_need_to_update) update_state_data(new_state_data);
		}
	};

	/**
	 * Adding new images function from the input
	 *
	 * @param {Event} event
	 */
	const add_new_images = (event = null) => {
		if (event) {
			const element = event.target;

			let new_files = [];

			if (element?.files.length > 0) {
				if (state_data) new_files = JSON.parse(JSON.stringify(state_data));

				// building new item for the state from the input files array
				for (let i = 0; i < element.files.length; i++) {
					const new_image = {
						url: null,
						sort_position: null,
						caption: null,
						new: true,
						file: element.files[i],
					};

					new_files.push(new_image);
				}

				// sending new data to the state
				update_state_data(new_files);

				// sending new files and data to the image processing
				new_images_processing(element.files, new_files);
			}
		}
	};

	/**
	 * New images processing func
	 *
	 * @param {Array} files             array with new images
	 * @param {Array} new_state_data    new state data
	 */
	const new_images_processing = (files = [], new_state_data = null) => {
		if (files?.length > 0 && new_state_data) {
			let index_arr = [];

			for (let i = 0; i < files.length; i++) {
				index_arr.push(i + state_data.length);
			}

			// send data to the update images func
			update_images(index_arr, files, new_state_data);
		}
	};

	return (
		<div className={classes.container}>
			{state_data && state_data.length > 0 ? (
				<DragDropContext onDragEnd={_on_drag_end}>
					<Droppable droppableId='droppable'>
						{(provided) => (
							<div {...provided.droppableProps} ref={provided.innerRef}>
								<UpdateWrapper
									data={state_data}
									change_action={update_image}
									delete_action={delete_image}
									update_caption={update_caption}
								/>
							</div>
						)}
					</Droppable>
				</DragDropContext>
			) : null}
			<div className={classes.addNewContainer}>
				<AddButton action={() => upload_input.current.click()} />

				<input
					type='file'
					name={'new images'}
					className={`invisible w-[1px]`}
					accept='.jpg, .jpeg, .png'
					multiple={true}
					onChange={add_new_images}
					ref={upload_input}
				/>
			</div>
		</div>
	);
};

ImagesBlock.propTypes = {
	data: PropTypes.array,
	action: PropTypes.func,

	sorting: PropTypes.bool,
	caption: PropTypes.bool,
};

export default ImagesBlock;
