import { API_URL } from '../../../const/params';

export const patch_product_data = async (full_data, patched_data) => {
	const config = {
		method: 'PATCH',
		headers: {
			Authorization: localStorage.getItem('auth_token'),
			'Content-Type': 'application/json',
			Accept: '*/*',
		},
		mode: 'cors',
		body: JSON.stringify(patched_data),
	};

	return fetch(`${API_URL}/products/${full_data.id}`, config).then((res) => {
		return res.ok;
	});
};
