/**
 * Reorder list
 *
 * @param {Array} list          draggable list
 * @param {Number} start_index
 * @param {Number} end_index
 * @returns reordered list
 */
export const reorder = (list = [], startIndex = 0, endIndex = 0) => {
	const result = Array.from(list);
	const [removed] = result.splice(startIndex, 1);
	result.splice(endIndex, 0, removed);

	return result;
};

/**
 * @param {Array} list sorted list
 * @returns list with sort index
 */
export const set_sort_index = (list = []) => {
	const result = list.map((item, index) => {
		return {
			...item,
			sort_position: index,
		};
	});

	return result;
};

/**
 * Sort elements is list after data fetched
 *
 * @param {Object} a
 * @param {Object} b
 * @returns sorted elements
 */
export const sort_elements = (a = null, b = null) => {
	if (a.sort_position > b.sort_position) return 1;
	else return -1;
};

/**
 * Sort elements is list after data fetched
 *
 * @param {Object} a
 * @param {Object} b
 * @returns sorted elements
 */
export const sort_elements_by_id = (a = null, b = null) => {
	if (a.id > b.id) return 1;
	else return -1;
};
