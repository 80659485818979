import React from 'react';
import PropTypes from 'prop-types';
import { DataProviderContext } from 'react-admin';
import { nanoid } from 'nanoid';
import { DragDropContext, Droppable } from 'react-beautiful-dnd';

import Input from '../../../Common/Input';
import AddButton from '../../../Common/Interface/AddButton';
import VariantItem from './VariantItem';

import { reorder } from '../../../../utils/drag-n-drop';

import { createEditStyles } from '../../../Common/createEditStyle';

class UpdateWrapper extends React.Component {
	/**
	 * @param {Array} data              data for wrapped components
	 * @param {Function} delete_action
	 * @constructor
	 */

	shouldComponentUpdate(nextProps) {
		if (
			nextProps.data === this.props.data &&
			nextProps.delete_action === this.props.delete_action
		)
			return false;

		return true;
	}

	render() {
		const { data, delete_action } = this.props;

		if (!data) return null;

		return data.map((product, index) => (
			<VariantItem
				key={nanoid()}
				index={index}
				product={product}
				delete_action={delete_action}
			/>
		));
	}

	static defaultProps = {
		data: [],

		delete_action: () => null,
	};
	static propTypes = {
		data: PropTypes.array,

		delete_action: PropTypes.func.isRequired,
	};
}

/**
 * @param {Object} record   current data
 *
 * @param {Function} action action for data processing in parent
 *
 * @class
 */
const VariantsList = ({
	record = {},

	action = () => null,
}) => {
	const create_edit_classes = createEditStyles();
	const dataProvider = React.useContext(DataProviderContext);

	const { product_variants } = record;

	const [is_new_view, toggle_new_view] = React.useState(false);
	const [list, update_list] = React.useState([]);
	const [search_state, update_search_state] = React.useState('');
	const [search_list, update_search_list] = React.useState([]);
	const [selected_product, update_selected_product] = React.useState(null);

	/**
	 * Send server data to the component state
	 */
	React.useEffect(() => {
		_UPDATE_VARIANTS_LIST();
	}, []);

	React.useEffect(() => {
		if (search_state) {
			dataProvider
				.getList('products', {
					pagination: { page: 1, perPage: 20 },
					sort: { field: 'title', order: 'ASC' },
					filter: {
						search: encodeURIComponent(search_state).toLocaleLowerCase(),
					},
				})
				.then(({ data }) => {
					// data processing for the `Input` component search work
					const new_data = data.map((item) => {
						return {
							id: item.id,
							name: item.title,
							price: item.price,
						};
					});

					if (new_data.length > 20) new_data.length = 20;

					// send data to the state
					update_search_list(new_data);
				});
		} else update_search_list([]);
	}, [search_state]);

	/**
	 * Update variants list
	 */
	const _UPDATE_VARIANTS_LIST = () => {
		if (record) {
			if (product_variants && product_variants.length > 0) {
				const new_list = product_variants.map((product, index) => {
					return {
						...product,
						sort_position: index,
					};
				});

				update_list(new_list);
				_PARENT_ACTION(new_list);
			}
		}
	};

	const ADD_PRODUCT = () => {
		toggle_new_view(true);
	};

	const SUBMIT_NEW_PRODUCT = () => {
		const new_list = Array.from(list);

		const new_element = {
			...selected_product,
			title: selected_product.name,
		};

		delete new_element.name;

		new_list.push(new_element);

		update_list(new_list);
		_PARENT_ACTION(new_list);
		toggle_new_view(false);
		_RESET_STATE();
	};

	const DELETE_PRODUCT = (id = null) => {
		if (id) {
			const new_list = list.filter((item) => item.id !== id);

			update_list(new_list);
			_PARENT_ACTION(new_list);
		}
	};

	const CANCEL_PRODUCT_SEARCH = () => {
		_RESET_STATE();
	};

	const _RESET_STATE = () => {
		toggle_new_view(false);
		update_search_state('');
		update_search_list([]);
		update_selected_product(null);
	};

	const _PARENT_ACTION = (list_to_send = null) => {
		if (list_to_send !== null && list_to_send !== undefined) {
			const new_list = list_to_send.map((item) => {
				return item.id;
			});

			action(new_list);
		} else action([]);
	};

	/**
	 * drag-end post-processing
	 *
	 * @param {Object} result react-beautiful-dnd data
	 * @returns reordered list
	 */
	const _on_drag_end = (result) => {
		// dropped outside the list
		if (!result.destination) {
			return;
		}

		// reorder list
		let items = reorder(list, result.source.index, result.destination.index);

		items = items.map((item, index) => {
			return {
				...item,
				sort_position: index,
			};
		});

		update_list(items);
		_PARENT_ACTION(items);
	};

	return (
		<div className='flex flex-col my-md max-w-[700px]'>
			<h2 className={create_edit_classes.blockHeader}>Варианты товара</h2>
			<div className='flex py-xs'>
				<p className='font-bold dark-gray h9 w-8/12'>Наименование</p>
				<p className='font-bold dark-gray h9 pl-2sm w-2/12'>Стоимость</p>
				<p className='font-bold dark-gray h9 pl-2sm w-2/12' />
			</div>
			{list && list.length > 0 ? (
				<ul className='flex flex-col'>
					<DragDropContext onDragEnd={_on_drag_end}>
						<Droppable droppableId='variants-droppable'>
							{(provided) => (
								<div {...provided.droppableProps} ref={provided.innerRef}>
									<UpdateWrapper data={list} delete_action={DELETE_PRODUCT} />
									{provided.placeholder}
								</div>
							)}
						</Droppable>
					</DragDropContext>
				</ul>
			) : (
				<p className='py-xs'>
					<i>Список пуст...</i>
				</p>
			)}
			<div className='mt-2sm'>
				{is_new_view ? (
					<div className='flex flex-col w-full'>
						<p>Выберите товар для добавления в список</p>
						<div className='flex flex-col w-full max-w-[500px] mt-sm'>
							<Input
								name='order_product_search'
								type='text'
								placeholder={'Поиск товара'}
								search_list={search_list}
								change={update_search_state}
								search_select_action={update_selected_product}
							/>
						</div>
						<div className='flex py-sm'>
							{selected_product ? (
								<React.Fragment>
									<p>
										Выбранный товар: <br />
										<b>
											{selected_product.name} - {+selected_product.price} руб.
										</b>
									</p>
								</React.Fragment>
							) : (
								<p className='mt-sm'>Ничего не выбрано</p>
							)}
						</div>
						<div className='flex mt-sm'>
							<button
								type='button'
								aria-labelledby='confirm new product to the order'
								className='btn btn-add'
								disabled={selected_product ? false : true}
								onClick={() => SUBMIT_NEW_PRODUCT()}>
								Добавить
							</button>
							<button
								type='button'
								aria-labelledby='cancel new product to the order'
								className='btn btn-delete ml-sm'
								onClick={() => CANCEL_PRODUCT_SEARCH()}>
								Отменить
							</button>
						</div>
					</div>
				) : (
					<AddButton action={ADD_PRODUCT} />
				)}
			</div>
		</div>
	);
};

VariantsList.propTypes = {
	record: PropTypes.object,

	action: PropTypes.func,
};

export default VariantsList;
