export const delivery_state = [
	// {
	//     title: 'Самовывоз, г. Минск, пер. Софьи Ковалевской 52/23',
	//     value: '1',
	// },
	// {
	//     title: 'Самовывоз, г. Минск, ТЦ «Кольцо», Меньковский тракт, 2 пав. 287',
	//     value: '2',
	// },
	// {
	//     title: 'Доставка курьером по Минску или Беларуси',
	//     value: '3',
	// },

	{
		title: 'Самовывоз',
		value: 'pickup',
	},
	{
		title: 'Доставка',
		value: 'courier',
	},
];
