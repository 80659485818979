import React from 'react';
import { useRecordContext } from 'react-admin';
import PropTypes from 'prop-types';
import { DragDropContext, Droppable } from 'react-beautiful-dnd';

import Attribute from './Attribute';
import AddButton from '../../../Common/Interface/AddButton';

import { reorder, set_sort_index, sort_elements } from '../../../../utils/drag-n-drop';

import { useStyles } from './style';

export const SelectListContext = React.createContext();

class UpdateWrapper extends React.Component {
	/**
	 * @param {Array} data              data for wrapped components
	 * @param {Function} change_action
	 * @param {Function} delete_action
	 * @constructor
	 */

	shouldComponentUpdate(nextProps) {
		if (
			nextProps.data === this.props.data &&
			nextProps.change_action === this.props.change_action &&
			nextProps.delete_action === this.props.delete_action
		)
			return false;

		return true;
	}

	render() {
		const { data, change_action, delete_action } = this.props;

		if (!data) return null;

		return data.map((attribute, index) => (
			<Attribute
				key={`attribute-${attribute.id ? attribute.id + '' : attribute.temp_id + ''}`}
				id={attribute.id}
				title={attribute.title}
				description={attribute.description}
				temp_id={attribute.temp_id}
				index={index}
				essential={attribute.essential}
				deleted={attribute._destroy ? true : false}
				change_action={change_action}
				delete_action={delete_action}
			/>
		));
	}

	static defaultProps = {
		data: [],

		change_action: () => null,
		delete_action: () => null,
	};
	static propTypes = {
		data: PropTypes.array,

		change_action: PropTypes.func.isRequired,
		delete_action: PropTypes.func.isRequired,
	};
}

/**
 * Компонент атрибутов при редактировании карточки товара
 *
 * @param {Function} props.update_data  обновление данных атрибутов родителя
 *
 * @class
 */
const Attributes = (props) => {
	const classes = useStyles();

	//данные продукта
	const record = useRecordContext();

	//внутреннее состояние данных атрибутов (потом передаются родителю)
	const [attributes_data, update_attributes_data] = React.useState([]);

	// select menu sync open state (for close other select menu)
	const [was_selects_processed, toggle_selects_processed] = React.useState(true);

	/**
	 * Чтение и обработка данных записи (уже существующих атрибутов)
	 */
	React.useEffect(() => {
		_update_attributes();
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	/**
	 * Чтение и обработка данных записи (уже существующих атрибутов)
	 */
	React.useEffect(() => {
		_update_attributes();
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [record]);

	/**
	 * Передача данных родителю при изменении внутренних данных
	 */
	React.useEffect(() => {
		props.update_data(attributes_data);
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [attributes_data]);

	/**
	 * Update attributes list
	 */
	const _update_attributes = () => {
		if (record) {
			if (record.product_attributes && record.product_attributes.length > 0) {
				const new_attrs = record.product_attributes
					.map((item1) => {
						return {
							id: item1.id,
							title: item1.title,
							description: item1.description,
							sort_position: item1.sort_position,
							essential: item1.essential,
						};
					})
					.sort(sort_elements);

				update_attributes_data(new_attrs);
			}
		}
	};

	/**
	 * Метод добавления нового атрибута в данные
	 */
	const _add_new_attribute = () => {
		const temp_arr = Array.from(attributes_data);

		//создаём новый объект и отправляем его в массив
		let new_attr_object = Object.create({
			temp_id: attributes_data.length,
			title: null,
			description: null,
			sort_position: attributes_data.length,
			essential: false,
			new: true,
		});

		temp_arr.push(new_attr_object);

		update_attributes_data(temp_arr);
	};

	/**
	 * Метод добавления атрибута из данных
	 */
	const _delete_attribute = React.useCallback(
		(id) => {
			//делаем копию массива данных
			let temp_data = Array.from(attributes_data);

			//находим нужный идекс объекта и удаляем его из массива
			const needed_index = attributes_data.findIndex(
				(elem) => id === elem.id || id === elem.temp_id
			);

			if (temp_data[needed_index].id) {
				temp_data[needed_index] = {
					id: temp_data[needed_index].id,
					_destroy: '1',
				};
			} else temp_data.splice(needed_index, 1);

			update_attributes_data(temp_data);
			// eslint-disable-next-line react-hooks/exhaustive-deps
		},
		[attributes_data]
	);

	/**
	 * Изменение внутренних данных при изменении данных в полях
	 *
	 * @param {Number} id    `id` атрибута для изменения
	 * @param {String} value    изменённое значение
	 * @param {String} type     тип поля (имя атрибута или его значение)
	 */
	const _change_attributes = React.useCallback(
		(id, value, type) => {
			//делаем копию массива данных
			let temp_data = Array.from(attributes_data);

			//находим индекс атрибута у которого были изменения
			const needed_index = attributes_data.findIndex(
				(elem) => id === elem.id || id === elem.temp_id
			);

			//в зависимости от типа меняем данные
			if (type === 'title') {
				if (!value) temp_data[needed_index].title = null;
				else if (value) temp_data[needed_index].title = value;
			} else if (type === 'description') {
				if (!value) temp_data[needed_index].description = null;
				else if (value) temp_data[needed_index].description = value;
			} else if (type === 'essential') {
				if (value && value === 'true') temp_data[needed_index].essential = true;
				else temp_data[needed_index].essential = false;
			}

			//отправляем новые данные
			update_attributes_data(temp_data);
			// eslint-disable-next-line react-hooks/exhaustive-deps
		},
		[attributes_data]
	);

	/**
	 * drag-end post-processing
	 *
	 * @param {Object} result react-beautiful-dnd data
	 * @returns reordered list
	 */
	const _on_drag_end = (result) => {
		// dropped outside the list
		if (!result.destination) {
			return;
		}

		// reorder list
		let items = reorder(attributes_data, result.source.index, result.destination.index);

		items = set_sort_index(items);

		update_attributes_data(items);
	};

	return (
		<div className={classes.mainContainer}>
			<SelectListContext.Provider value={{ was_selects_processed, toggle_selects_processed }}>
				<DragDropContext onDragEnd={_on_drag_end}>
					<Droppable droppableId='attribute-droppable'>
						{(provided) => (
							<div {...provided.droppableProps} ref={provided.innerRef}>
								<UpdateWrapper
									data={attributes_data}
									change_action={_change_attributes}
									delete_action={_delete_attribute}
								/>
								{provided.placeholder}
							</div>
						)}
					</Droppable>
				</DragDropContext>
			</SelectListContext.Provider>
			<AddButton action={_add_new_attribute} />
		</div>
	);
};

Attributes.defaultProps = {
	update_data: null,
};

Attributes.propTypes = {
	update_data: PropTypes.func,
};

export default Attributes;
