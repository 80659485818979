import React from 'react';
import { Link, List, useListContext } from 'react-admin';

import HeaderBlock from '../../Common/HeaderBlock';
import ImageComponent from '../../Common/ImageComponent';
// import Pagination from '../../Common/Pagination';
import SEO from '../../Common/SEO';

import { categories_sorter } from '../../../utils/categories_sort';

import { useStyles } from './style';

const Categories = () => {
	const classes = useStyles();
	const { data, ids } = useListContext();
	const [categories_data, change_categories_data] = React.useState(null);
	// const [ids_handler, change_ids_handler] = React.useState(null);

	React.useEffect(() => {
		let new_categories_data = [];

		if (ids.length > 0) {
			new_categories_data = categories_sorter(data);
		}

		// console.log(data);

		change_categories_data(new_categories_data);
	}, [data, ids]);

	return (
		<div className={classes.categoryListContainer}>
			{categories_data !== null ? (
				<ul className={classes.categoryList}>
					{categories_data.map((first_level, index) => {
						const sub_category_checker = (object) => {
							// в зависимости от уровня вложенности, рассчитываем и выставляем отступ слева
							const padding_left = 12 + 24 * (object.category_level - 1);

							if (object.sub_categories) {
								return (
									<li
										key={object.id}
										className={`${classes.subCategoryListContainer} sub_category_list`}
									>
										<ul className={classes.categoryList}>
											<li className={classes.mainCategoryItemContainer}>
												<Link
													to={`/categories/${object.id}`}
													className={classes.mainCategoryItem}
													style={{
														paddingLeft: isFinite(padding_left)
															? padding_left
															: 12,
													}}
												>
													<ImageComponent data={object?.image?.url} />
													<p className={classes.categoryItem__text}>
														{object.title}
													</p>
												</Link>
											</li>
											<ul className={classes.subCategoryList}>
												{object.sub_categories.map((item) =>
													sub_category_checker(item)
												)}
											</ul>
										</ul>
									</li>
								);
							} else {
								return (
									<li
										key={object.id}
										className={classes.subCategoryItemContainer}
									>
										<Link
											to={`/categories/` + object.id}
											className={classes.subCategoryItem}
											style={{ paddingLeft: padding_left }}
										>
											<ImageComponent data={object?.image?.url} />
											<p className={classes.categoryItem__text}>
												{object.title}
											</p>
										</Link>
									</li>
								);
							}
						};

						if (first_level.sub_categories) {
							return sub_category_checker(first_level);
						} else {
							const padding_left = 12;

							return (
								<li key={index} className={classes.categoryItemContainer}>
									<Link
										to={`/categories/` + first_level.id}
										className={`${classes.categoryItem} category-item`}
										style={{ paddingLeft: padding_left }}
									>
										<ImageComponent data={first_level?.image.url} />
										<p className={classes.categoryItem__text}>
											{first_level.title}
										</p>
									</Link>
								</li>
							);
						}
					})}
				</ul>
			) : null}
		</div>
	);
};

export const CategoryList = (props) => {
	const classes = useStyles();

	return (
		<List
			component='div'
			bulkActionButtons={false}
			{...props}
			perPage={300}
			actions={false}
			pagination={null}
		>
			<div className={classes.mainContainer}>
				<SEO title={'Список категорий'} />
				<HeaderBlock title={'Категории'} link={'categories'} />
				<Categories />
				{/* <Pagination /> */}
			</div>
		</List>
	);
};
