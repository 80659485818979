export const build_form_data = (form_data, data, parent_key) => {
	// check for object type
	if (
		data &&
		!Array.isArray(data) &&
		typeof data === 'object' &&
		!(data instanceof Date) &&
		!(data instanceof File)
	) {
		Object.keys(data).forEach((key) => {
			if (parent_key) {
				if (key !== parent_key)
					build_form_data(form_data, data[key], `${parent_key}[${key}]`);
				else build_form_data(form_data, data[key], key);
			} else if (!parent_key) build_form_data(form_data, data[key], key);
		});
	} else if (data && Array.isArray(data)) {
		if (data.length > 0) {
			data.forEach((item, index) => {
				// check type for object (but not image object)
				if (
					(Array.isArray(item) || typeof item === 'object') &&
					!(data instanceof Date) &&
					!(item instanceof File)
				) {
					if (parent_key.includes('[images_data]'))
						build_form_data(form_data, item, `${parent_key}[]`);
					else
						build_form_data(
							form_data,
							item,
							parent_key !== undefined && parent_key !== null
								? `${parent_key}[${index}]`
								: [index]
						);
					// build_form_data(form_data, item, parent_key !== undefined && parent_key !== null ? `${parent_key}[${index}]` : [index]);
				} else {
					// if this is image object or primitive type - add like array item to formData
					const value = item === null ? '' : item;
					form_data.append(`${parent_key}[]`, value);
				}
			});
		} else form_data.append(`${parent_key}[]`, '');
	} else {
		const value = data == null ? '' : data;

		form_data.append(parent_key, value);
	}
};
