import * as React from 'react';
import { Card, CardContent, Typography, List, ListItem, ListItemText } from '@material-ui/core';
import { Title } from 'react-admin';

import SEO from './components/Common/SEO';

import { useStyles } from './Dashboard.style';

const content_list = [
	'Панель управления.',
	'Категории.',
	'Товары.',
	'Бренды.',
	'Пользователи.',
	'Курсы валют.',
	'Карусели.',
	'Услуги.',
	'Заказы.',
	'Выгрузки.',
];

const Dashboard = () => {
	const classes = useStyles();

	const tirer = <span className={classes.tirer}>–</span>;

	return (
		<Card className={classes.root}>
			<SEO title={'Панель управления'} />
			<Title title='Панель управления' />
			<Typography className={classes.header} variant='h1'>
				Панель управления
			</Typography>
			<Typography className={`${classes.text} ${classes.mainText}`}>
				Добро пожаловать в новую админку!
			</Typography>
			<Typography className={classes.text}>В ней есть следующие разделы:</Typography>
			<CardContent className={classes.disablePadding}>
				<List dense={true} className={classes.disablePadding}>
					{content_list.map((item, index) => (
						<ListItem
							key={index}
							className={`${classes.disablePadding} ${classes.listItem}`}
						>
							{tirer}
							<ListItemText
								className={`${classes.disableMargin} ${classes.text}`}
								primary={item}
							/>
						</ListItem>
					))}
				</List>
			</CardContent>
		</Card>
	);
};

export default Dashboard;
