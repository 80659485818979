import React from 'react';

import { API_URL } from '../../../../const/params';
import { fade_in, fade_out } from '../../../../utils/fade';
import { fetch_wrapper } from '../../../../wrappers/fetch_wrapper';

import { useStyles } from './style';

let timer;

/**
 * @class
 */
const UpdatePricies = () => {
	const classes = useStyles();

	const modal = React.useRef(null);

	const [is_updating, toggle_updating] = React.useState(false);
	const [is_mount, toggle_mount] = React.useState(false);
	const [is_error, toggle_error] = React.useState(false);

	React.useEffect(() => {
		toggle_mount(true);
	}, []);

	React.useEffect(() => {
		if (is_mount) {
			const header = document.getElementsByTagName('header')[0];

			// change header style when modal is open
			if (is_updating) {
				header.style.zIndex = '-1';
				fade_in(modal.current, 10, 'flex');
			} else {
				fade_out(modal.current);

				timer = setTimeout(() => {
					if (is_error) toggle_error(false);

					header.style.zIndex = '';
				}, 500);
			}
		}

		// clear timeout
		return () => {
			clearTimeout(timer);
		};
	}, [is_updating]);

	/**
	 * Calculate a new pricies
	 */
	const recalculate_price = () => {
		toggle_updating(true);

		// send fetch request
		fetch_wrapper(`${API_URL}/products/update_all_prices`, show_error, 'PATCH')
			.then((res) => res.json())
			.then(() => toggle_updating(false));
	};

	/**
	 * @param {Boolean} server_error server request status
	 */
	const show_error = (server_error = false) => {
		if (server_error) {
			toggle_error(true);

			// hide modal
			timer = setTimeout(() => {
				toggle_updating(false);
			}, 5000);
		}
	};

	return (
		<React.Fragment>
			<button
				type='button'
				aria-labelledby='recalculate price'
				onClick={recalculate_price}
				className='btn btn-add'
			>
				Пересчитать цены каталога
			</button>
			<div className={classes.modal} ref={modal}>
				<div className={classes.content}>
					{!is_error && (
						<img
							className={classes.modal__loader}
							src='/assets/icons/loading-ico.svg'
							alt='loading'
						/>
					)}
					<p className={classes.modal__text}>
						{!is_error ? 'Пересчёт цен...' : 'Произошла ошибка. Повторите ещё раз.'}
					</p>
				</div>
				<div type='button' className={classes.modal__background} />
			</div>
		</React.Fragment>
	);
};

export default React.memo(UpdatePricies);
