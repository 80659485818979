import React from 'react';
import {
	Link,
	// Link,
	List,
	useListContext,
} from 'react-admin';

import HeaderBlock from '../../Common/HeaderBlock';
import SEO from '../../Common/SEO';

import { delivery_method_data, pay_method_data, payment_state_data } from '../const/translate';

import { useStyles } from './style';
import { nanoid } from 'nanoid';

/**
 * Компонент списка пользователя
 */
const Orders = () => {
	const classes = useStyles();
	const { data, ids } = useListContext();
	const [orders_data, change_orders_data] = React.useState([]);

	React.useEffect(() => {
		let new_orders_data = [];

		if (ids.length > 0) {
			for (let key in data) {
				new_orders_data.push(data[key]);
			}
		}

		change_orders_data(new_orders_data);
	}, [data, ids]);

	return (
		<div className={classes.listContainer}>
			<div className={classes.headerContainer}>
				<p className={`w-1/12 ${classes.header}`}>Номер</p>
				<p className={`ml-2sm w-2/12 ${classes.header}`}>Дата заказа</p>
				<p className={`ml-2sm w-2/12 ${classes.header}`}>Телефон</p>
				<p className={`ml-2sm w-2/12 ${classes.header}`}>Способ доставки</p>
				<p className={`ml-2sm w-2/12 ${classes.header}`}>Способ оплаты</p>
				<p className={`ml-2sm w-2/12 ${classes.header}`}>Статус оплаты</p>
				<p className={`ml-2sm w-1/12 ${classes.header}`}>Сумма</p>
			</div>
			{orders_data !== null && orders_data.length > 0 ? (
				<ul className={classes.orderList}>
					{orders_data.map((order) => {
						let total_price = 0;

						if (order?.order_items?.length > 0) {
							order.order_items.forEach((order_item) => {
								total_price += +order_item.quantity * +order_item.price;
							});
						}

						return (
							<li key={nanoid()} className={classes.orderContainer}>
								<Link
									to={`/orders/${order.id}`}
									className={`flex py-md px-2sm items-center`}
								>
									<p className={`w-1/12 ${classes.order__text}`}>№{order.id}</p>
									<p className={`ml-2sm w-2/12 ${classes.order__text}`}>
										{new Date(order.created_at).toLocaleString()}
									</p>
									<p className={`ml-2sm w-2/12 ${classes.order__text}`}>
										{order.customer_phone}
									</p>
									<p className={`ml-2sm w-2/12 ${classes.order__text}`}>
										{delivery_method_data[order.delivery_method]}
									</p>
									<p className={`ml-2sm w-2/12 ${classes.order__text}`}>
										{pay_method_data[order.payment_method]}
									</p>
									<p className={`ml-2sm w-2/12 ${classes.order__text}`}>
										{payment_state_data[order.payment_state]}
									</p>
									<p className={`ml-2sm w-1/12 ${classes.order__text}`}>
										{total_price} руб.
									</p>
								</Link>
							</li>
						);
					})}
				</ul>
			) : null}
		</div>
	);
};

export const OrdersList = (props) => {
	// const classes = useStyles();

	return (
		<List
			component='div'
			bulkActionButtons={false}
			{...props}
			perPage={300}
			actions={false}
			pagination={null}
		>
			<div>
				<SEO title={'Заказы'} />
				<HeaderBlock title={'Заказы'} link={'orders'} />
				<Orders />
			</div>
		</List>
	);
};
