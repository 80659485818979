import { makeStyles } from '@material-ui/core/styles';

import { color_map } from '../../../../const/style';

export const useStyles = makeStyles({
	checkbox: {
		width: '100%',
		display: 'flex',
		alignItems: 'center',
		fontSize: 14,
		lineHeight: 1.15,
		color: color_map.black,
		userSelect: 'none',
		transition: '.2s ease',

		'&:is(:hover, :active)': {
			color: color_map.blue,
			cursor: 'pointer',
		},
		'&:is(:hover, :active) span::before': {
			borderColor: color_map.blue,
		},
	},

	checkbox__input: {
		// marginRight: 6,
		outline: 'none',
		position: 'absolute',
		zIndex: -1,
		opacity: 0,

		'&:checked + span::before': {
			backgroundImage: `url("data:image/svg+xml,%3Csvg width='14' height='14' viewBox='0 0 14 14' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M0.5 3C0.5 1.61929 1.61929 0.5 3 0.5H11C12.3807 0.5 13.5 1.61929 13.5 3V11C13.5 12.3807 12.3807 13.5 11 13.5H3C1.61929 13.5 0.5 12.3807 0.5 11V3Z' fill='%231263AF' stroke='%231263AF'/%3E%3Cg filter='url(%23filter0_d_2084_225)'%3E%3Cpath d='M3 6.30769L6.42857 10L11 4' stroke='white' stroke-width='2' stroke-linecap='round' stroke-linejoin='round'/%3E%3C/g%3E%3Cdefs%3E%3Cfilter id='filter0_d_2084_225' x='0' y='3' width='14' height='12' filterUnits='userSpaceOnUse' color-interpolation-filters='sRGB'%3E%3CfeFlood flood-opacity='0' result='BackgroundImageFix'/%3E%3CfeColorMatrix in='SourceAlpha' type='matrix' values='0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0' result='hardAlpha'/%3E%3CfeOffset dy='2'/%3E%3CfeGaussianBlur stdDeviation='1'/%3E%3CfeColorMatrix type='matrix' values='0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.15 0'/%3E%3CfeBlend mode='normal' in2='BackgroundImageFix' result='effect1_dropShadow_2084_225'/%3E%3CfeBlend mode='normal' in='SourceGraphic' in2='effect1_dropShadow_2084_225' result='shape'/%3E%3C/filter%3E%3C/defs%3E%3C/svg%3E")`,
			backgroundSize: 14,
		},
	},

	checkbox__text: {
		marginLeft: 6,
		display: 'inline-flex',
		alignItems: 'center',

		'&::before': {
			content: '""',
			display: 'inline-block',
			width: 12,
			height: 12,
			flexShrink: 0,
			flexGrow: 0,
			border: `1px solid ${color_map.control_gray}`,
			borderRadius: '0.25em',
			marginRight: '0.5em',
			backgroundRepeat: 'no-repeat',
			backgroundPosition: 'center center',
			backgroundSize: '50% 50%',
			transition: '.2s ease',
		},
	},

	checkbox__text_disabled: {
		color: color_map.gray,
	},
});
