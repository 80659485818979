import React from 'react';

import { useStyles } from './style';

/**
 * @param {Function} action
 *
 * @class
 */
const UndoButton = ({ action = () => null }) => {
	const classes = useStyles();

	return (
		<button className={`ml-[12px] ${classes.button_undo}`} type='button' onClick={action}>
			Отменить
		</button>
	);
};

export default UndoButton;
