import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { useNotify, useRedirect } from 'react-admin';

import Input from '../Common/Input';
import SEO from '../Common/SEO';
import authProvider from '../../authProvider';

import { borderDefault, borderRadius, color_map } from '../../const/style';

const useStyles = makeStyles({
	loginContainer: {
		backgroundColor: color_map.background_light_blue,
		height: '100%',
		position: 'relative',
	},

	login: {
		padding: '18px 24px',
		background: color_map.white,
		border: borderDefault,
		borderRadius: borderRadius,
		position: 'absolute',
		top: '50%',
		left: '50%',
		transform: 'translate(-50%, -50%)',
		display: 'flex',
		flexDirection: 'column',
		maxWidth: 350,
		width: '100%',
	},

	logo: {
		display: 'flex',
		justifyContent: 'center',

		'& img': {
			width: 128,
			height: 30,
		},
	},

	form: {
		marginTop: 18,
		display: 'flex',
		flexDirection: 'column',
	},

	submit_button: {
		marginTop: 12,
		padding: 6,
		fontSize: 14,
		lineHeight: '1.2',
		color: color_map.white,
		backgroundColor: color_map.blue,
		border: 'none',
		borderRadius: borderRadius,
		transition: '.2s ease',

		'&:hover': {
			cursor: 'pointer',
			backgroundColor: color_map.bright_blue,
		},

		'&:disabled': {
			backgroundColor: color_map.outline_gray,
			cursor: 'default',
		},
	},

	error_text: {
		color: color_map.red,
		fontSize: 14,
		lineHeight: '1.2',
		margin: '12px 0 0',
		textAlign: 'center',
	},
});

const Login = () => {
	const classes = useStyles();
	const redirect = useRedirect();
	const notify = useNotify();
	const form = React.useRef(null);

	const [is_active, toggle_active] = React.useState(false);
	const [name, change_name] = React.useState('');
	const [pass, change_pass] = React.useState('');
	const [error, update_error] = React.useState(null);

	React.useEffect(() => {
		if (name && pass && !is_active) toggle_active(true);
		else if ((!name || !pass) && is_active) toggle_active(false);

		if ((name || pass) && error) update_error(null);
	}, [name, pass]);

	const submit_handler = async (event) => {
		event.preventDefault();

		const data = {
			username: name,
			password: pass,
		};

		const res = await authProvider.login(data);
		if (res === 401) {
			form.current.reset();
			change_name('');
			change_pass('');
			update_error('Неправильное имя пользователя или пароль');
		} else {
			notify('Вы успешно зашли');
			redirect('/');
		}
	};

	return (
		<div className={classes.loginContainer}>
			<SEO title='Страница входа' />
			<div className={classes.login}>
				<div className={classes.logo}>
					<img src={'/assets/temp/ru-logo_with-slogan.svg'} alt='logo' />
				</div>
				<form onSubmit={submit_handler} className={classes.form} ref={form}>
					<Input
						name='name'
						type='text'
						change={change_name}
						parent_value={name}
						error={error ? true : false}
						placeholder='Имя пользователя'
					/>
					<Input
						name='pass'
						type='password'
						change={change_pass}
						parent_value={pass}
						error={error ? true : false}
						placeholder='Пароль'
					/>
					<button type='submit' className={classes.submit_button} disabled={!is_active}>
						Войти
					</button>
				</form>
				{error ? <p className={classes.error_text}>{error}</p> : null}
			</div>
		</div>
	);
};

export default Login;
