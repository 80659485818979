import { API_URL } from '../const/params';
import { fetch_wrapper } from '../wrappers/fetch_wrapper';

/**
 * Функция вызова данных о валютах
 *
 * @returns пришедшие из вызова данные
 */
export const currency_request = async () => {
	const res = await fetch_wrapper(`${API_URL}/currencies.json`)
		.then((res) => res.json())
		.then((data) => {
			const new_data = data.map((data_item) => {
				return {
					id: data_item.id,
					title: data_item.title,
					currency: data_item.value,
					value: data_item.iso_code,
				};
			});

			return new_data;
		});

	return res;
};
