export const headers_translate = {
	export_to: 'Название выгрузки',
	amount: 'Кол-во товаров',
	state: 'Состояние',
	created_at: 'Время выгрузки',
};

export const export_to_translate = {
	onliner: 'Выгрузка на Onliner',
	ozon: 'Выгрузка на Ozon',
	wildberries: 'Выгрузка на Wildberries',
	yandex_market: 'Выгрузка на Yandex Market',
};

export const state_translate = {
	success: 'Успешно выгружено',
	error: 'Ошибка выгрузки',
};
