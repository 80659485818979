import React from 'react';
import {
	Create,
	required,
	SaveButton,
	SelectInput,
	SimpleForm,
	TextInput,
	Toolbar,
	useNotify,
	useRedirect,
} from 'react-admin';

import SEO from '../../Common/SEO';
import UndoButton from '../../Common/Interface/UndoButton';
import RichText from '../../Common/RichText';

import { createEditStyles } from '../../Common/createEditStyle';

import { store_state } from '../../../data/select/store';

export const BrandCreate = (props) => {
	const classes = createEditStyles();

	const image_ref = React.createRef();

	const [image_data, update_image_data] = React.useState(null);
	const [rich_data, update_rich_data] = React.useState(null);

	const redirect = useRedirect();
	const notify = useNotify();

	const undo_edit = React.useCallback(() => {
		notify(`Создание отменено`);
		redirect('/brands');
	}, []);

	const PostCreateToolbar = (props) => (
		<Toolbar {...props}>
			<SaveButton
				transform={(data) => {
					const new_data = {
						...data,
						image: image_data,
						description: rich_data,
					};
					return new_data;
				}}
				submitOnEnter={true}
				label='Создать'
			/>
			<UndoButton action={undo_edit} />
		</Toolbar>
	);

	return (
		<Create {...props}>
			<SimpleForm toolbar={<PostCreateToolbar />}>
				<SEO title={'Создание бренда'} />
				<h1 className={classes.pageHeader}>Создание бренда</h1>
				<TextInput source='title' validate={[required()]} />
				<TextInput source='short_description' validate={[required()]} />
				<h2 className={`${classes.blockHeader}`}>Описание</h2>
				<RichText
					parent_value={rich_data}
					update_data={(new_data) => update_rich_data(new_data)}
				/>
				<SelectInput
					source='store'
					choices={store_state}
					allowEmpty={false}
					validate={[required()]}
				/>
				<h2 className={classes.blockHeader}>Изображение</h2>
				<div className={classes.dataBlock}>
					<input
						type='file'
						onChange={(event) => update_image_data(event.target.files[0])}
						ref={image_ref}
					/>
					{image_data ? (
						<div className={classes.imagesBlock}>
							<img
								src={
									image_data && typeof image_data !== 'string'
										? URL.createObjectURL(image_data)
										: image_data && typeof image_data === 'string'
										? image_data
										: null
								}
								alt='изображение'
							/>
						</div>
					) : null}
					{/* <button
                        type="button"
                        className='flex self-baseline btn btn-delete mt-sm'
                        onClick={reset_image_data}>
                        Сбросить
                    </button> */}
				</div>
			</SimpleForm>
		</Create>
	);
};
