import React from 'react';
import PropTypes from 'prop-types';

import { useStyles } from './style';

/**
 * @param {Boolean} is_open     is modal open
 *
 * @param {Function} update_modal   update modal state
 * @param {Function} close_html     close html-editor state
 *
 * @class
 */
const BackModal = ({
	is_open = false,

	update_modal = () => null,
	close_html = () => null,
}) => {
	const classes = useStyles();
	const container = React.useRef(null);

	/**
	 * Remove event listener
	 */
	React.useEffect(() => {
		return () => {
			window.removeEventListener('click', check_window_click);
		};
	}, []);

	/**
	 * Manage window click listener
	 */
	React.useEffect(() => {
		if (is_open) window.addEventListener('click', check_window_click);
		else window.removeEventListener('click', check_window_click);
	}, [is_open]);

	/**
	 * @param {Event} event
	 */
	const check_window_click = (event) => {
		let element = event.target;

		// close modal if clicked element not contains in container.current
		if (!container.current.contains(element)) update_modal(false);
	};

	return (
		<div className={classes.backModal} ref={container}>
			<p className={classes.backModal__text}>
				Вы уверены?
				<br />
				<b>Результат может быть утерян</b>.
			</p>
			<div className={classes.backModalButtonsContainer}>
				<button
					className={classes.backModal__option}
					onClick={() => {
						update_modal(false);

						close_html();
					}}
				>
					Да
				</button>
				<button className={classes.backModal__option} onClick={() => update_modal(false)}>
					Нет
				</button>
			</div>
		</div>
	);
};

BackModal.propTypes = {
	is_open: PropTypes.bool,

	update_modal: PropTypes.func,
	close_html: PropTypes.func,
};

export default BackModal;
