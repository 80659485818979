import { makeStyles } from '@material-ui/core/styles';

import { borderRadius, color_map } from '../../../const/style';

export const useStyles = makeStyles({
	container: {
		'&:nth-child(n+2)': {
			marginTop: 12,
		},
	},
	inputComponent: {
		display: 'flex',
		flexDirection: 'column',
		fontSize: 14,
		lineHeight: '1.2',
		color: color_map.dark_gray,
		position: 'relative',
		flexWrap: 'nowrap',
	},

	inputComponent_value: {
		'& input': {
			borderRadius: `3px 0px 0px 3px`,
		},
	},

	inputComponent_focus: {
		'& input': {
			borderColor: color_map.blue,
		},
		'& p': {
			color: color_map.blue,
		},
	},

	inputComponent_error: {
		'& input': {
			borderColor: color_map.red,
		},
		'& p': {
			color: color_map.red,
		},
	},

	inputComponent__text: {
		position: 'absolute',
		margin: 0,
		top: 1,
		left: 12,
		padding: '0px 3px',
		backgroundColor: color_map.white,
		transform: 'translate(0, 12px) scale(1)',
		transition: '.2s ease',
		userSelect: 'none',
		cursor: 'text',
		zIndex: 3,
	},

	inputComponent__text_active: {
		transform: 'translate(-13%, -9px) scale(.75)',
	},

	input: {
		padding: 12,
		border: `1px solid ${color_map.control_gray}`,
		borderRadius: borderRadius,
		outline: 'none',
		width: '100%',
		transition: '.2s ease',
		position: 'relative',
		zIndex: 1,
		color: color_map.black,

		'&:is(:hover, :active)': {
			// borderColor: color_map.blue,
		},

		'&:disabled': {
			color: color_map.black,
		},
	},

	inputContainer: {
		display: 'flex',
		position: 'relative',
	},

	valueContainer: {
		border: `1px solid ${color_map.control_gray}`,
		borderLeft: 'none',
		borderRadius: `0px 3px 3px 0px`,
		display: 'flex',
		alignItems: 'center',
		justifyContent: 'center',
		minWidth: 66,
		position: 'relative',
		zIndex: 2,

		'& p': {
			color: color_map.gray,
			fontSize: 14,
			lineHeight: 1.15,
			margin: 0,
			userSelect: 'none',
		},
	},

	valueContainer_dropdown: {
		backgroundColor: 'transparent',
		padding: 0,
		transition: '.2s ease',

		'& p': {
			color: color_map.black,
		},

		'& img': {
			marginLeft: 7,
		},

		'&:is(:hover, :active)': {
			borderColor: color_map.blue,
		},
	},

	dropdownList: {
		position: 'absolute',
		right: 0,
		top: 35,
		margin: 0,
		padding: '6px 0',
		border: `1px solid ${color_map.control_gray}`,
		backgroundColor: color_map.white,
		zIndex: 3,
		listStyle: 'none',
		width: 66,
		display: 'flex',
		flexDirection: 'column',
	},

	dropdownItem: {
		backgroundColor: 'transparent',
		width: '100%',
		border: 'none',
		padding: '6px 0',
		transition: '.2s ease',

		'&:is(:hover, :active)': {
			backgroundColor: color_map.blue,
			color: color_map.white,
			cursor: 'pointer',
		},
	},
	dropdownItem_active: {
		backgroundColor: color_map.blue,
		color: color_map.white,

		'&:is(:hover, :active)': {
			cursor: 'default',
		},
	},
});
