import React from 'react';
import { Create, SaveButton, SimpleForm, Toolbar, useNotify, useRedirect } from 'react-admin';

import SEO from '../../Common/SEO';
import UndoButton from '../../Common/Interface/UndoButton';
import ProductList from '../Common/ProductList';
import Input from '../../Common/Input';
import Select from '../../Common/Interface/Select';

import { delivery_state } from '../../../data/select/delivery';
import { pay_method_data, pay_status_data } from '../../../data/select/pay';
import { store_state } from '../../../data/select/store';

import { createEditStyles } from '../../Common/createEditStyle';

export const OrderCreate = (props) => {
	const classes = createEditStyles();

	const [client_name, update_client_name] = React.useState('');
	const [client_phone, update_client_phone] = React.useState('');
	const [client_comment, update_client_comment] = React.useState('');
	const [delivery, update_delivery] = React.useState(delivery_state[0].value);
	const [client_address, update_client_address] = React.useState('');
	const [payment_state, update_payment_state] = React.useState(pay_status_data[1].value);
	const [payment_method, update_payment_method] = React.useState(pay_method_data[0].value);
	const [store, update_store] = React.useState(store_state[0].value);
	const [product_list, update_product_list] = React.useState([]);

	const redirect = useRedirect();
	const notify = useNotify();

	/**
	 * Запрос и обработка данных брендов
	 */
	React.useEffect(() => {
		redirect('/orders/create');
	}, []);

	const undo_edit = React.useCallback(() => {
		notify(`Создание отменено`);
		redirect('/orders');
	}, []);

	const PostCreateToolbar = (props) => (
		<Toolbar {...props}>
			<SaveButton
				transform={() => {
					let customer_id = 1;

					const new_product_list = product_list.map((item) => {
						return {
							product_id: item.id,
							name: item.name,
							price: item.price,
							quantity: item.quantity,
						};
					});

					const new_data = {
						state: 'pending',
						payment_state: payment_state,
						delivery_method: delivery,
						payment_method: payment_method,
						source: store,
						customer_id: customer_id,
						order_items_attributes: new_product_list,
						customer_comment: client_comment,
						customer_name: client_name,
						customer_phone: client_phone,
						delivery_address: client_address,
					};

					return new_data;
				}}
				submitOnEnter={true}
			/>
			<UndoButton action={undo_edit} />
		</Toolbar>
	);

	const product_count = React.useMemo(() => {
		let counter = 0;

		product_list.forEach((product) => {
			counter += product.quantity;
		});

		return counter;
	}, [product_list]);

	const total_price = React.useMemo(() => {
		let counter = 0;

		product_list.forEach((product) => {
			counter += +product.quantity * +product.price;
		});

		return counter;
	}, [product_list]);

	let delivery_price = 25;
	if (total_price >= 100 && client_address?.toLowerCase().includes('минск')) delivery_price = 0;

	return (
		<Create {...props}>
			<SimpleForm toolbar={<PostCreateToolbar />}>
				<SEO title={`Создание заказа`} />
				<h1 className={classes.pageHeader}>Создание заказа</h1>
				<ProductList
					list={product_list}
					can_change={true}
					update_list={update_product_list}
				/>
				<div className='flex flex-col mt-md'>
					<h2 className='h7'>Контактная информация</h2>
					<div className='flex flex-col max-w-[700px] mt-2sm'>
						<Input
							name='client-name'
							type='text'
							placeholder='Имя'
							parent_value={client_name}
							change={update_client_name}
						/>
						<Input
							name='client-phone'
							type='text'
							placeholder='Телефон'
							parent_value={client_phone}
							change={update_client_phone}
						/>
					</div>
				</div>
				<div className='flex flex-col mt-md'>
					<h2 className='h7'>Доставка</h2>
					<div className='flex flex-col max-w-[700px] mt-2sm'>
						<Select
							name='Способ доставки'
							list={delivery_state}
							selected={delivery_state[0]}
							action={update_delivery}
						/>
						<Input
							name='client_address'
							type='text'
							placeholder='Адрес'
							parent_value={client_address}
							change={update_client_address}
						/>
						<Input
							name='client_comment'
							type='text'
							placeholder='Комментарий'
							parent_value={client_comment}
							change={update_client_comment}
						/>
					</div>
				</div>
				<div className='flex flex-col mt-md'>
					<h2 className='h7'>Оплата</h2>
					<div className='flex flex-col max-w-[700px] mt-2sm'>
						<Select
							name='Способ оплаты'
							list={pay_method_data}
							selected={pay_method_data[0]}
							change={update_payment_method}
						/>
					</div>
					<div className='flex flex-col max-w-[700px] mt-2sm'>
						<Select
							name='Статус оплаты'
							list={pay_status_data}
							selected={pay_status_data[1]}
							action={update_payment_state}
						/>
					</div>
				</div>
				<div className='flex flex-col mt-md'>
					<div className='flex flex-col max-w-[700px] mt-2sm'>
						<Select
							name='Магазин'
							list={store_state}
							selected={store_state[0]}
							change={update_store}
						/>
					</div>
				</div>
				<div className='flex flex-col mt-md'>
					<h2 className='h7'>Детали</h2>
					<div className='mt-2sm'>
						<section className='h8 flex'>
							<p className='max-w-[155px] w-full'>{product_count} товара</p>
							<p className='ml-sm'>{+total_price} руб.</p>
						</section>
						<section className='h8 flex mt-xs'>
							<p className='max-w-[155px] w-full'>Доставка</p>
							<p className='ml-sm'>
								{delivery === 'pickup'
									? 'Бесплатно'
									: delivery === 'courier'
									? `${delivery_price} руб.`
									: null}
							</p>
						</section>
						<section className='flex mt-xs'>
							<p className='max-w-[155px] w-full'>Итоговая стоимость</p>
							<p className='font-bold ml-sm'>
								{delivery === 'courier'
									? total_price + delivery_price
									: total_price}{' '}
								руб.
							</p>
						</section>
					</div>
				</div>
			</SimpleForm>
		</Create>
	);
};
