import { makeStyles } from '@material-ui/core/styles';

import { borderRadius } from '../../../../const/style';

export const useStyles = makeStyles({
	addButton: {
		border: 'none',
		backgroundColor: '#44CB3A',
		padding: 0,
		borderRadius: borderRadius,
		width: 36,
		height: 36,
		display: 'flex',
		alignItems: 'center',
		justifyContent: 'center',
		transition: '.2s ease',

		'&:hover': {
			cursor: 'pointer',
			opacity: '.8',
		},
	},
});
