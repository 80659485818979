import React from 'react';
import { Link, useNotify, useRefresh } from 'react-admin';
import PropTypes from 'prop-types';
import { nanoid } from 'nanoid';

import ImageComponent from '../../../Common/ImageComponent';
import Currencies from '../../../Common/Currencies';

import { patch_product_data } from '../../../../utils/products/Edit';
import { stock_status } from '../../../../data/select/stock_status';

import { useStyles } from './style';

const initial_price_data = {
	initial_price: 0,
	currency_id: 1,
	discount: 0,
};

/**
 * Элемент списка товаров
 *
 * @param {Object} data             данные товара
 * @param {Array} currencies_data   данные валют с сервера
 *
 * @class
 */
const ListItem = ({ data = {}, currencies_data = null }) => {
	const classes = useStyles();
	const notify = useNotify();
	const refresh = useRefresh();
	const [price, change_price] = React.useState('0');
	const [status, change_status] = React.useState(false);
	const [state, change_state] = React.useState('');
	const [price_data, update_price_data] = React.useState(initial_price_data);
	const [is_price_container_open, toggle_price_container_open] = React.useState(false);
	const price_container = React.useRef(null);
	const price_button = React.useRef(null);

	/**
	 * Устанавливаем первичные данные при монтировании
	 */
	React.useEffect(() => {
		if (data) {
			if (data.price) change_price(+data.price);
			if (data.status) {
				if (data.status === false) change_status(false);
				else if (data.status === true) change_status(true);
			}
			if (data.state) change_state(data.state);
		}

		// убираем слушателя при удалении компонента
		return () => {
			_CLOSE_PRICE_CONTAINER();
		};
	}, []);

	/**
	 * При обновлении данных обновляем и локальные переменные
	 */
	React.useEffect(() => {
		if (data) {
			if (data.price !== price) change_price(+data.price);
			if (data.status !== status) {
				if (data.status === false) change_status(false);
				else if (data.status === true) change_status(true);
			}
			if (data.state !== state) change_state(data.state);

			const new_price_data = {
				initial_price: +data.price,
				currency_id: data.current_currency ? +data.current_currency.id : 1,
				markup: data.markup ? +data.markup : 1,
				discount: data.discount ? +data.discount : 0,
			};

			update_price_data(new_price_data);
		}
	}, [data]);

	/**
	 * Функция слушатель нажатия клавиш при открытии окна цены
	 *
	 * @param {Event} event событие нажатия клавиши
	 */
	const _PRICE_KEYDOWN_LISTENER = async (event) => {
		switch (event.key) {
			// если `Enter`, то отправляем данные (пока скрыто)
			// case 'Enter':
			//     _UPDATE_PRICE();
			//     break;
			// если `Esc`, то закрываем меню
			case 'Escape':
				_CLOSE_PRICE_CONTAINER();
				break;
			default:
				break;
		}
	};

	/**
	 * Функция изменения статуса товара
	 *
	 * @param {Event} event вызывающее событие
	 */
	const _STATUS_CHECK_LISTENER = async (event) => {
		const formData = Object.create(null);
		formData.product = Object.create(null);
		formData.product.status = !status;

		patch_product_data(data, formData).then((res) => {
			if (res) {
				if (event.target) event.target.blur();
				change_status(!status);
				notify(`Статус товара ${data.title} изменено`, 'info');
			} else notify(`Произошла ошибка, статус товара ${data.title} не изменён`, 'error');

			refresh();
		});
	};

	/**
	 * Функция изменения наличия товара
	 *
	 * @param {Event} event вызывающее событие
	 */
	const _STATE_TOGGLE_LISTENER = async (event) => {
		const formData = Object.create(null);
		formData.product = Object.create(null);
		formData.product.state = event.target.value;

		patch_product_data(data, formData).then((res) => {
			if (res) {
				if (event.target) event.target.blur();
				change_state(event.target.value);
				notify(`Наличие товара ${data.title} изменено`, 'info');
			} else notify(`Произошла ошибка, наличие товара ${data.title} не изменено`, 'error');

			refresh();
		});
	};

	/**
	 * Функция изменения цены
	 */
	const _UPDATE_PRICE = async () => {
		const formData = Object.create(null);
		formData.product = Object.create(null);
		formData.product.initial_price = price_data.initial_price;
		formData.product.currency_id = price_data.currency_id;
		formData.product.markup = price_data.markup;
		formData.product.discount = price_data.discount;

		// проверка данных, чтобы при неправильном формате не вызвать ошибку
		if (!isFinite(price_data.initial_price)) {
			formData.product.initial_price = data.initial_price;
			formData.product.currency_id = data.currency_id;
			formData.product.markup = data.markup;
			formData.product.discount = data.discount;
			change_price('0');
		}

		// отправка данных
		patch_product_data(data, formData).then((res) => {
			if (res) notify(`Цена товара ${data.title} изменена`, 'info');
			else notify(`Произошла ошибка, цена товара ${data.title} не изменена`, 'error');

			refresh();

			// закрываем блок с изменением цены
			_CLOSE_PRICE_CONTAINER();
		});
	};

	/**
	 * Фунция открытия блока изменения цены
	 */
	const _OPEN_PRICE_CONTAINER = () => {
		toggle_price_container_open(true);
		window.addEventListener('keydown', _PRICE_KEYDOWN_LISTENER, { passive: true });
	};

	/**
	 * Функция закрытия блока изменения цены
	 */
	const _CLOSE_PRICE_CONTAINER = () => {
		toggle_price_container_open(false);
		window.removeEventListener('keydown', _PRICE_KEYDOWN_LISTENER);
	};

	return (
		<li className={classes.productContainer}>
			<Link to={`/products/${data.id}`} className={classes.product}>
				<ImageComponent
					data={
						data && data.images && data.images.length > 0 ? data.images[0]?.url : null
					}
				/>
				<p className={`${classes.product__text} ${classes.nameSpace}`}>{data.title}</p>
			</Link>
			<div>
				<select
					className={classes.product__state}
					value={state}
					onChange={(e) => _STATE_TOGGLE_LISTENER(e)}
				>
					{stock_status.map((status) => (
						<option value={status.value} key={nanoid()}>
							{status.name}
						</option>
					))}
				</select>
				<input
					className={classes.product__status}
					type='checkbox'
					name='status'
					checked={status}
					onChange={(e) => _STATUS_CHECK_LISTENER(e)}
					// disabled
				/>
				<button
					className={classes.product__priceButton}
					onClick={() => _OPEN_PRICE_CONTAINER(true)}
					ref={price_button}
				>
					{price_data.discount && +price_data.discount > 0 ? (
						<span className={classes.product__priceTextBefore}>
							{price + price_data.discount}
						</span>
					) : null}
					{price}
				</button>
				<div
					className={
						is_price_container_open
							? `${classes.productPriceContainer} ${classes.productPriceContainer_open}`
							: classes.productPriceContainer
					}
					ref={price_container}
				>
					<p className={classes.productPriceContainer__header}>{data.model}</p>
					{is_price_container_open ? (
						<Currencies
							record={data}
							update_data={update_price_data}
							props_currency_data={currencies_data}
							need_currency_request={false}
						/>
					) : null}
					<div className={classes.buttonsContainer}>
						<button className='btn' onClick={() => _UPDATE_PRICE()}>
							Сохранить
						</button>
						<button
							className={`btn btn-outline ${classes.undoButton}`}
							onClick={() => _CLOSE_PRICE_CONTAINER()}
						>
							Отменить
						</button>
					</div>
				</div>
				<div
					className={
						is_price_container_open
							? `${classes.productPriceCloseBackground} ${classes.productPriceCloseBackground_open}`
							: classes.productPriceCloseBackground
					}
					onClick={() => _CLOSE_PRICE_CONTAINER()}
				/>
			</div>
		</li>
	);
};

ListItem.propTypes = {
	data: PropTypes.object,
	currencies_data: PropTypes.array,
};

export default ListItem;
