import React from 'react';
import {
	DeleteButton,
	Edit,
	required,
	SaveButton,
	SimpleForm,
	TextInput,
	Toolbar,
	useNotify,
	useRedirect,
	useRefresh,
	useShowController,
} from 'react-admin';

import SEO from '../../Common/SEO';
import Input from '../../Common/Input';
import UndoButton from '../../Common/Interface/UndoButton';
import ProductList from '../Common/ProductList';

import { createEditStyles } from '../../Common/createEditStyle';
import Select from '../../Common/Interface/Select';
import { delivery_state } from '../../../data/select/delivery';
import { pay_method_data, pay_status_data } from '../../../data/select/pay';
import { store_state } from '../../../data/select/store';
// import { state_data } from '../const/translate';

/**
 * Компонент списка заказов
 *
 * @param {Object} props параметры компонента
 * @class
 */
export const OrderEdit = (props) => {
	const [client_name, update_client_name] = React.useState('');
	const [client_phone, update_client_phone] = React.useState('');
	const [client_comment, update_client_comment] = React.useState('');
	const [client_address, update_client_address] = React.useState(null);
	const [delivery, update_delivery] = React.useState('');
	const [payment_state, update_payment_state] = React.useState('');
	const [store, update_store] = React.useState('');
	const [product_list, update_product_list] = React.useState([]);

	const {
		record, // record fetched via dataProvider.getOne() based on the id from the location
	} = useShowController(props);

	const classes = createEditStyles();

	const refresh = useRefresh();
	const redirect = useRedirect();
	const notify = useNotify();

	/**
	 * Обработка данных заказа для последующей работы
	 */
	React.useEffect(() => {
		if (record && record.customer) {
			if (client_name !== record.customer_name) update_client_name(record.customer_name);
			if (client_phone !== record.customer_phone) update_client_phone(record.customer_phone);
			if (client_comment !== record.customer_comment)
				update_client_comment(record.customer_comment);
			if (delivery !== record.delivery_method) update_delivery(record.delivery_method);
			if (payment_state !== record.payment_state) update_payment_state(record.payment_state);
			if (store !== record.source) update_store(record.source);
			if (record.delivery_address && client_address !== record.delivery_address)
				update_client_address(record.delivery_address);
		}

		refresh();
	}, [record]);

	const on_success = React.useCallback(() => {
		notify(`Заказ удалён`);
		redirect('/orders');
	}, []);

	const undo_edit = React.useCallback(() => {
		notify(`Редактирование отменено`);
		redirect('/orders');
	}, []);

	const product_count = React.useMemo(() => {
		let counter = 0;

		if (record?.order_items?.length > 0) {
			record?.order_items.forEach((product) => {
				counter += product.quantity;
			});
		}

		return counter;
	}, [record]);

	const total_price = React.useMemo(() => {
		let counter = 0;

		if (record?.order_items?.length > 0) {
			record?.order_items.forEach((product) => {
				counter += +product.quantity * +product.price;
			});
		}

		return counter;
	}, [record]);

	if (!record) return null;

	// const discount = React.useMemo(() => {
	//     let counter = 0;

	//     record.order_items.forEach(product => {
	//         counter += product.discount * product.quantity;
	//     })

	//     return counter;
	// }, [record]);

	const PostEditToolbar = (props) => (
		<Toolbar {...props}>
			<SaveButton
				disabled={true}
				title='Сохранить'
				transform={() => {
					const new_data = {
						delivery_method: delivery,
						customer_comment: client_comment,
					};

					return new_data;
				}}
				submitOnEnter={true}
			/>
			<UndoButton action={undo_edit} />
			{/* <DeleteButton
                style={{ marginLeft: 'auto' }}
                mutationMode="pessimistic"
                onSuccess={on_success} /> */}
		</Toolbar>
	);

	let delivery_price = 25;
	if (total_price >= 100 && client_address?.toLowerCase().includes('минск')) delivery_price = 0;

	return (
		<Edit
			{...props}
			component={'div'}
			classes={{ root: classes.editContainer, noActions: classes.mainContainer }}
			mutationMode={'pessimistic'}>
			<SimpleForm
				toolbar={
					<PostEditToolbar
					// save_disable={save_disable}
					/>
				}>
				<SEO title={`Заказ №${record.id}`} />
				<h1 className={classes.pageHeader}>Заказ №{record.id}</h1>
				<p className='mt-2sm'>Создан: {new Date(record.created_at).toLocaleString()}</p>
				{/* <p className='mt-2sm'>Статус: {state_data[record.state]}</p> */}
				<ProductList list={record?.order_items} />
				<div className='flex flex-col mt-md'>
					<h2 className='h7'>Контактная информация</h2>
					<div className='flex flex-col max-w-[700px] mt-2sm'>
						<Input
							name='client-name'
							type='text'
							placeholder='Имя'
							parent_value={client_name}
							disabled={true}
							// change={update_client_name}
						/>
						<Input
							name='phone'
							type='text'
							placeholder='Телефон'
							parent_value={client_phone}
							disabled={true}
							// change={update_client_phone}
						/>
					</div>
				</div>
				<div className='flex flex-col mt-md'>
					<h2 className='h7'>Доставка</h2>
					<div className='flex flex-col max-w-[700px] mt-2sm'>
						<Select
							name='Способ доставки'
							list={delivery_state}
							selected={delivery_state.find(
								(item) => item.value === record.delivery_method
							)}
							// action={update_delivery}
							disabled={true}
						/>
						<Input
							name='client_address'
							type='text'
							placeholder='Адрес'
							parent_value={client_address === null ? 'не указан' : client_address}
							// change={client_address === null ? () => null : update_client_address}
							// disabled={client_address === null ? true : false}
							disabled={true}
						/>
						<Input
							name='client_comment'
							type='text'
							placeholder='Комментарий'
							parent_value={client_comment}
							// change={update_client_comment}
							disabled={true}
						/>
					</div>
				</div>
				<div className='flex flex-col mt-md'>
					<h2 className='h7'>Оплата</h2>
					<div className='flex flex-col max-w-[700px] mt-2sm'>
						<Select
							name='Способ оплаты'
							list={pay_method_data}
							selected={pay_method_data.find(
								(item) => item.value === record.payment_method
							)}
							disabled={true}
						/>
					</div>
					<div className='flex flex-col max-w-[700px] mt-2sm'>
						<Select
							name='Статус оплаты'
							list={pay_status_data}
							selected={pay_status_data.find((item) => item.value === record.state)}
							// action={update_payment_state}
							disabled={true}
						/>
					</div>
				</div>
				<div className='flex flex-col mt-md'>
					<div className='flex flex-col max-w-[700px] mt-2sm'>
						<Select
							name='Магазин'
							list={store_state}
							selected={store_state.find((item) => item.value === record.source)}
							disabled={true}
						/>
					</div>
				</div>
				<div className='flex flex-col mt-md'>
					<h2 className='h7'>Детали</h2>
					<div className='mt-2sm'>
						<section className='h8 flex'>
							<p className='max-w-[155px] w-full'>{product_count} товара</p>
							<p className='ml-sm'>{+total_price} руб.</p>
							{/* <p className='ml-sm'>{+temp_data.total_price + +discount} руб.</p> */}
						</section>
						{/* <section className='h8 flex mt-xs'>
                            <p className='max-w-[155px] w-full'>Скидка</p>
                            <p className='ml-sm'>{discount} руб.</p>
                        </section> */}
						<section className='h8 flex mt-xs'>
							<p className='max-w-[155px] w-full'>Доставка</p>
							<p className='ml-sm'>
								{delivery === 'pickup'
									? 'Бесплатно'
									: delivery === 'courier'
									? `${delivery_price} руб.`
									: null}
							</p>
						</section>
						<section className='flex mt-xs'>
							<p className='max-w-[155px] w-full'>Итоговая стоимость</p>
							<p className='font-bold ml-sm'>
								{delivery === 'courier'
									? total_price + delivery_price
									: total_price}{' '}
								руб.
							</p>
						</section>
					</div>
				</div>
			</SimpleForm>
		</Edit>
	);
};
