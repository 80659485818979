import React from 'react';
import { useListContext } from 'react-admin';

import { useStyles } from './style';

/**
 * @class
 */
const Pagination = () => {
	const classes = useStyles();
	const { total, page, perPage, setPage } = useListContext();

	const page_count = Math.ceil(total / perPage);

	const temp_page_arr = [];

	if (page_count > 1) {
		for (let i = 0; i < page_count; i++) {
			if (i + 1 > page - 3 && i + 1 < page + 3) temp_page_arr.push(i + 1);
		}
	}

	return (
		<div className={classes.paginationContainer}>
			{page_count > 1 && page_count !== Infinity ? (
				<nav className={classes.pageContainer}>
					{page > 1 ? (
						<div className={classes.pagePlaceholder}>
							<button
								className={`${classes.page} ${classes.page_prev}`}
								onClick={() => setPage(page - 1)}
							>
								<img src={'/assets/icons/chevron-left.svg'} alt='prev' />
							</button>
						</div>
					) : null}
					<ul className={classes.pagesList}>
						{temp_page_arr.map((item, index) => {
							if (item === page) {
								return (
									<li key={index}>
										<button
											key={item}
											className={`${classes.page} ${classes.page_active}`}
											onClick={() => setPage(item)}
											disabled
										>
											{item}
										</button>
									</li>
								);
							} else {
								return (
									<li key={index}>
										<button
											key={item}
											className={classes.page}
											onClick={() => setPage(item)}
										>
											{item}
										</button>
									</li>
								);
							}
						})}
					</ul>
					{page < page_count ? (
						<div className={classes.pagePlaceholder}>
							<button
								className={`${classes.page} ${classes.page_next}`}
								onClick={() => setPage(page + 1)}
							>
								<img src={'/assets/icons/chevron-left.svg'} alt='next' />
							</button>
						</div>
					) : null}
				</nav>
			) : null}
		</div>
	);
};

export default Pagination;
