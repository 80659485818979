export const check_input_for_only_numbers = (value = '') => {
	let temp_value = '' + value,
		new_value = '';

	for (let i = 0; i < temp_value.length; i++) {
		if (isFinite(temp_value[i]) || temp_value[i] === '.') new_value += temp_value[i];
	}

	return new_value;
};
