import React from 'react';
import { Link, List, useListContext } from 'react-admin';
import { nanoid } from 'nanoid';

import HeaderBlock from '../../Common/HeaderBlock';
import ImageComponent from '../../Common/ImageComponent';
import SEO from '../../Common/SEO';

import { useStyles } from './style';

/**
 * Sliders list
 */
const Sliders = () => {
	const classes = useStyles();
	const { data, ids } = useListContext();
	const [sliders_data, change_sliders_data] = React.useState(null);

	React.useEffect(() => {
		let new_sliders_data = [];

		if (ids.length > 0) {
			for (let key in data) {
				new_sliders_data.push(data[key]);
			}
		}

		change_sliders_data(new_sliders_data);
	}, [data, ids]);

	return (
		<div className={classes.listContainer}>
			<div className={classes.headerContainer}>
				<p className={classes.header}>Наименование</p>
			</div>
			{sliders_data !== null && sliders_data.length > 0 ? (
				<ul className={classes.sliderList}>
					{sliders_data.map((slider) => (
						<li key={nanoid()} className={classes.sliderContainer}>
							<Link to={`/sliders/${slider.id}`} className={classes.slider}>
								{/* <ImageComponent data={slider && slider.avatar ? slider.avatar : null} /> */}
								<p className={`${classes.slider__text}`}>{slider.title}</p>
							</Link>
						</li>
					))}
				</ul>
			) : null}
		</div>
	);
};

export const SlidersList = (props) => {
	const classes = useStyles();

	return (
		<List
			component='div'
			bulkActionButtons={false}
			{...props}
			perPage={300}
			actions={false}
			pagination={null}
		>
			<div className={classes.mainContainer}>
				<SEO title={'Карусели'} />
				<HeaderBlock title={'Карусели'} link={'sliders'} />
				<Sliders />
			</div>
		</List>
	);
};
