import React from 'react';
import PropTypes from 'prop-types';
import { Draggable } from 'react-beautiful-dnd';

import DeleteButton from '../../Interface/DeleteButton';
import DragButton from '../../Interface/DragButton';
import TextArea from '../../TextArea';

import { useStyles } from './style';

/**
 * Блок изображения
 *
 * @param {Object} data
 * @param {String} name
 * @param {Function} update_method
 * @param {Function} delete_method
 * @param {Function} update_caption
 * @param {Number} index
 * @class
 */
const ImageBlock = ({
	data = null,
	name = 'image_uploads',
	update_method = () => null,
	delete_method = () => null,
	update_caption = () => null,

	index = null,
}) => {
	const classes = useStyles();

	const [uploaded_name, change_uploaded_name] = React.useState(null);
	const [uploaded_image, change_uploaded_image] = React.useState(null);

	const upload_input = React.useRef(null);
	const upload_button = React.useRef(null);

	React.useEffect(() => {
		upload_input.current.addEventListener('change', update_image_listener);
	}, []);

	React.useEffect(() => {
		if (data && data.file) {
			let url = null;
			if (data.file && data.file.name) url = URL.createObjectURL(data.file);

			if (data.file.name === uploaded_name) {
				change_uploaded_name(null);
				change_uploaded_image(null);
			} else if (uploaded_image !== url) {
				change_uploaded_image(url);
			}
		} else if (uploaded_image !== null) {
			change_uploaded_name(null);
			change_uploaded_image(null);
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [data]);

	const update_image_listener = (event) => {
		const element = event.target;

		change_uploaded_name(element.files[0].name);

		if (URL && element.files && element.files.length > 0) {
			const url = URL.createObjectURL(element.files[0]);
			change_uploaded_image(url);
		}
	};

	const delete_image = () => {
		delete_method(index);
	};

	const update_caption_for_children = React.useCallback((text) => {
		update_caption(data.attachment_id, text);
	}, []);

	return (
		<Draggable draggableId={`attribute-${index}`} index={index}>
			{(provided) => (
				<section
					className={classes.imageBlock}
					ref={provided.innerRef}
					{...provided.draggableProps}
				>
					<DragButton {...provided.dragHandleProps} />
					<div className={`shrink-0 ${classes.imageContainer}`}>
						<div className={classes.image}>
							<input
								type='file'
								name={name}
								className={classes.image__input}
								accept='.jpg, .jpeg, .png'
								onChange={(event) => update_method(index, event.target.files[0])}
								ref={upload_input}
							/>
							<input
								className={classes.image__button}
								type='button'
								ref={upload_button}
								value='Добавить'
								onClick={() => upload_input.current.click()}
							/>
							{uploaded_image ? (
								<img
									src={uploaded_image}
									alt='товар'
									className={classes.image__show}
									loading='lazy'
								/>
							) : data && data.url ? (
								<img
									src={data.url}
									alt='товар'
									className={classes.image__show}
									loading='lazy'
								/>
							) : (
								<div className={classes.image__placeholder} />
							)}
						</div>
						{/* <div className={classes.nameContainer}>
                            <p className={classes.name}>{uploaded_name ? 'Новое' : 'Загруженное'}</p>
                        </div> */}
					</div>
					{!data?.new ? (
						<div className='w-full mr-2sm'>
							<TextArea
								name={name}
								title={'Описание'}
								view={'full'}
								parent_value={data.caption}
								update_parent_value={update_caption_for_children}
							/>
						</div>
					) : null}
					<div className={classes.buttonContainer}>
						<DeleteButton action={delete_image} parameter_for_action={index} />
					</div>
				</section>
			)}
		</Draggable>
	);
};

ImageBlock.propTypes = {
	data: PropTypes.object,
	name: PropTypes.string,
	update_method: PropTypes.func,
	delete_method: PropTypes.func,
	update_caption: PropTypes.func,
	index: PropTypes.number,
};

export default ImageBlock;
