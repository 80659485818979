import React from 'react';
import {
	BooleanInput,
	Create,
	DataProviderContext,
	required,
	SaveButton,
	SelectInput,
	SimpleForm,
	TextInput,
	Toolbar,
	useNotify,
	useRedirect,
} from 'react-admin';

import Attributes from '../Common/Attributes';
import SEO from '../../Common/SEO';
import RichText from '../../Common/RichText';
import Currencies from '../../Common/Currencies';
import Dropdown from '../../Common/Interface/Dropdown';
import Select from '../../Common/Interface/Select';
import UndoButton from '../../Common/Interface/UndoButton';

import { stock_status } from '../../../data/select/stock_status';
import { store_state } from '../../../data/select/store';
import { item_state } from '../../../data/select/item_state';
import { html_options } from '../data/select';

import { categories_sorter, change_sub_categories } from '../../../utils/categories_sort';

import { createEditStyles } from '../../Common/createEditStyle';
import ImagesBlock from '../../Common/ImagesBlock';
import ComplementaryList from '../Common/ComplementaryList';

const initial_price_data = {
	initial_price: 0,
	currency_id: 1,
};

export const ProductCreate = (props) => {
	const classes = createEditStyles();
	const [categories_data, update_categories_data] = React.useState([]);
	const [brands_data, update_brands_data] = React.useState([]);
	const [images_data, update_images_data] = React.useState(null);
	const [complementary_data, update_complementary_data] = React.useState([]);
	const [is_html_only, update_html_only] = React.useState(false);
	const [price_data, update_price_data] = React.useState(initial_price_data);
	const [sorted_categories, update_sorted_categories] = React.useState(null);
	const [rich_data, update_rich_data] = React.useState(null);
	const [attributes_data, update_attributes_data] = React.useState(null);

	const dataProvider = React.useContext(DataProviderContext);
	const redirect = useRedirect();
	const notify = useNotify();

	/**
	 * Запрос и обработка данных брендов
	 */
	React.useEffect(() => {
		redirect('/products/create');
		dataProvider
			.getList('brands', {
				pagination: { page: 1, perPage: 200 },
				sort: { field: 'title', order: 'ASC' },
				filter: {},
			})
			.then(({ data }) => {
				// обработка данных для работы `SelectInput`
				const new_data = data.map((item) => {
					return {
						id: item.id,
						name: item.title,
					};
				});

				// отправлялем данные в `state`
				update_brands_data(new_data);
			});

		// составляем список категорий
		dataProvider
			.getList('categories', {
				pagination: { page: 1, perPage: 200 },
				sort: { field: 'title', order: 'ASC' },
				filter: {},
			})
			.then(({ data }) => {
				// сортируем список категорий с вложением
				let new_categories_data = categories_sorter(data);

				// форматируем данные для выпадающего списка
				new_categories_data = change_sub_categories(new_categories_data);

				// отправлялем данные в `state`
				update_sorted_categories(new_categories_data);
			});
	}, []);

	/**
	 * Метод обработки данных категорий
	 *
	 * @param {Array} new_data новые данные
	 */
	const _update_categories = (new_data) => {
		// проходим по массиву данных выбранных категорий и приводим их к нужному для отправки виду
		const new_category_data = new_data.map((category) => {
			return category.id;
		});

		update_categories_data(new_category_data);
	};

	const _update_attributes = (new_data) => {
		update_attributes_data(
			new_data.filter((item) => item.title !== null && item.description !== null)
		);
	};

	/**
	 * Метод обновления данных изображения
	 *
	 * @param {Event} event вызывающее событие
	 */
	// const _update_images = (event) => {
	//     if (event.target.files.length < 6) {
	//         update_images_data([...event.target.files]);
	//     } else {
	//         let new_images_data = [];

	//         for (let i = 0; i < event.target.files.length; i++) {
	//             if (i < 6) new_images_data.push(event.target.files[i]);
	//         }

	//         update_images_data([...new_images_data]);
	//     }
	// }

	/**
	 * Метод обновления данных изображения
	 */
	const _update_images = (data) => {
		update_images_data(data);
	};

	/**
	 * Method to update complementary data
	 */
	const _update_complementary = (data) => {
		update_complementary_data(data);
	};

	const undo_edit = React.useCallback(() => {
		notify(`Создание отменено`);
		redirect('/products');
	}, []);

	const PostCreateToolbar = (props) => (
		<Toolbar {...props}>
			<SaveButton
				transform={(data) => {
					const server_attributes_data = Object.create(null);

					attributes_data.forEach((attribute, index) => {
						if (!attribute._destroy) {
							server_attributes_data[index] = {
								title: attribute.title,
								description: attribute.description,
							};

							if (attribute.new) server_attributes_data[index].new = true;
							if (attribute.id) server_attributes_data[index].id = attribute.id;
						} else {
							server_attributes_data[index] = {
								id: attribute.id,
								_destroy: '1',
							};
						}
					});

					const new_data = {
						...data,
						...price_data,
						images: images_data,
						category_ids: categories_data,
						description: rich_data,
						html_layout: is_html_only,
						product_attributes: [],
						product_attributes_attributes: server_attributes_data,
						complementary_product_ids: complementary_data,
					};

					// добавляем поля изображений для отправки
					if (images_data.add && images_data.add.length > 0)
						new_data.images = [...images_data.add];
					if (images_data.delete && images_data.delete.length > 0)
						new_data.attachment_ids = [...images_data.delete];

					return new_data;
				}}
				submitOnEnter={true}
			/>
			<UndoButton action={undo_edit} />
		</Toolbar>
	);

	return (
		<Create {...props}>
			<SimpleForm toolbar={<PostCreateToolbar />}>
				{/* <ReferenceInput source="userId" reference="users">
                    <SelectInput optionText="name" />
                </ReferenceInput> */}
				<SEO title={'Создание товара'} />
				<h1 className={classes.pageHeader}>Создание товара</h1>
				<h2 className={classes.blockHeader}>Основное</h2>
				<TextInput
					source='title'
					label={'Наименование'}
					type='text'
					helperText={false}
					validate={[required()]}
				/>
				<TextInput source='model' label={'Модель'} type='text' helperText={false} />
				<Dropdown
					name='Категории'
					list_data={sorted_categories}
					action={_update_categories}
				/>
				<TextInput source='sku' label={'Артикул'} type='text' helperText={false} />
				<SelectInput source='brand_id' choices={brands_data} allowEmpty={true} />
				<TextInput
					source='manufacturer'
					label={'Производитель'}
					type='text'
					helperText={false}
				/>
				<TextInput
					source='warranty_term'
					label={'Срок гарантии'}
					type='text'
					helperText={false}
				/>

				<h2 className={classes.blockHeader}>Описание</h2>
				<RichText update_data={(new_data) => update_rich_data(new_data)} />
				<Select
					name='Только HTML?'
					list={html_options}
					selected={is_html_only ? html_options[0] : html_options[1]}
					tooltip_text={null}
					action={update_html_only}
				/>

				<h2 className={classes.blockHeader}>Цена</h2>
				<Currencies update_data={update_price_data} />

				<h2 className={classes.blockHeader}>Информация на сайте</h2>
				<SelectInput source='store' choices={store_state} allowEmpty={false} />
				<SelectInput source='status' choices={item_state} allowEmpty={false} />
				<SelectInput source='state' choices={stock_status} allowEmpty={false} />

				<h2 className={classes.blockHeader}>Deal.by</h2>
				<TextInput
					source='dealby_description'
					label={'Описание'}
					type='text'
					helperText={false}
				/>

				<h2 className={classes.blockHeader}>Onliner</h2>
				<TextInput
					source='halva_price'
					label={'Цена (халва), BYN'}
					type='text'
					helperText={false}
				/>
				<div className={classes.dataBlock}>
					<TextInput
						source='halva_term'
						label={'Срок рассрочки, месяцев'}
						type='text'
						helperText={false}
					/>
					<TextInput
						source='onliner_description'
						label={'Описание'}
						type='text'
						helperText={false}
					/>
				</div>

				<div className={classes.dataBlock}>
					<TextInput
						source='onliner_article'
						label={'Артикул'}
						type='text'
						helperText={false}
					/>
					<TextInput
						source='onliner_category'
						label={'Категория'}
						type='text'
						helperText={false}
					/>
				</div>

				<h2 className={classes.blockHeader}>Limars</h2>
				<TextInput
					source='limars_vendor_code'
					label={'Limars vendor code'}
					type='text'
					helperText={false}
				/>

				<h2 className={classes.blockHeader}>Атрибуты</h2>
				<Attributes update_data={_update_attributes} edit={true} />

				<h2 className={classes.blockHeader}>Изображения</h2>
				<ImagesBlock action={_update_images} />
				<p>
					<small>
						<i>
							На данный момент, сортировка не будет учтена - возможна только при
							редактировании
						</i>
					</small>
				</p>

				{/* <h2 className={classes.blockHeader}>Сопутствующие товары</h2>
                <ComplementaryList action={_update_complementary} /> */}
			</SimpleForm>
		</Create>
	);
};
