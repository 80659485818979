import { makeStyles } from '@material-ui/core/styles';

import { borderDefault, borderRadius, color_map } from '../../../../const/style';

export const useStyles = makeStyles({
	priceSpace: {
		width: 80,
		marginLeft: '50px !important',
	},

	stateSpace: {
		width: 100,
		marginLeft: 'auto !important',
	},

	statusSpace: {
		width: 50,
		marginLeft: '50px !important',
	},

	productContainer: {
		display: 'flex',
		position: 'relative',

		'&:nth-child(n+2) > a': {
			borderTop: borderDefault,
		},

		'&:hover a, &:active a': {
			backgroundColor: color_map.light_gray,
		},
	},
	product: {
		padding: '6px 12px',
		width: '100%',
		transition: '.2s ease',
		height: 72,
		display: 'flex',
		alignItems: 'center',
	},
	product__text: {
		fontWeight: 400,
		fontSize: 14,
		lineHeight: 1.2,
		display: 'flex',
		alignItems: 'center',
		color: color_map.black,
	},

	product__price: {
		padding: 12,
		backgroundColor: 'transparent',
		fontSize: 14,
		lineHeight: 1.15,
		display: 'flex',
		alignItems: 'center',
		color: color_map.black,
		border: '1px solid transparent',
		transition: '.2s ease',
		maxWidth: 'calc(92px - 32px)',
		position: 'absolute',
		right: 12,
		top: '50%',
		transform: 'translateY(-50%)',
		borderRadius: borderRadius,
		outline: 'none',

		'&:is(:hover, :active)': {
			borderColor: color_map.blue,
		},
		'&:is(:hover)': {
			cursor: 'pointer',
		},
		'&:is(:focus)': {
			cursor: 'text',
			borderColor: color_map.blue,
		},
	},

	product__priceTextBefore: {
		position: 'absolute',
		left: '-60%',
		color: color_map.gray,
		fontWeight: 'bold',
		textDecoration: 'line-through',
	},

	product__status: {
		position: 'absolute',
		margin: 0,
		right: 170,
		top: '50%',
		transform: 'translateY(-50%)',
	},

	product__state: {
		position: 'absolute',
		margin: 0,
		top: '50%',
		transform: 'translateY(-50%)',
		right: 230,
		height: 22,
	},

	product__priceButton: {
		padding: 12,
		backgroundColor: 'transparent',
		fontSize: 14,
		lineHeight: 1.15,
		display: 'flex',
		alignItems: 'center',
		color: color_map.black,
		border: '1px solid transparent',
		transition: '.2s ease',
		position: 'absolute',
		right: 12,
		top: '50%',
		transform: 'translateY(-50%)',
		borderRadius: borderRadius,
		outline: 'none',

		'&:is(:hover, :active)': {
			borderColor: color_map.blue,
		},
		'&:is(:hover)': {
			cursor: 'pointer',
		},
		'&:is(:focus)': {
			cursor: 'text',
			borderColor: color_map.blue,
		},
	},

	productPriceContainer: {
		background: '#FFFFFF',
		boxShadow: '0px 0px 10px rgba(0, 0, 0, 0.16)',
		borderRadius: 4,
		padding: 12,
		position: 'absolute',
		right: 30,
		top: -30,
		display: 'none',
		flexDirection: 'column',
		zIndex: 3,
	},
	productPriceContainer_open: {
		display: 'flex',
	},

	productPriceContainer__header: {
		fontSize: 14,
		lineHeight: 1.15,
		display: 'flex',
		alignItems: 'center',
		color: '#000000',
		fontWeight: 'bold',
	},

	buttonsContainer: {
		marginTop: 12,
		display: 'flex',
	},

	undoButton: {
		marginLeft: 'auto',
	},

	productPriceCloseBackground: {
		position: 'fixed',
		left: 0,
		right: 0,
		top: 0,
		bottom: 0,
		backgroundColor: 'transparent',
		zIndex: 2,
		display: 'none',

		'&:is(:hover, :active)': {
			cursor: 'pointer',
		},
	},

	productPriceCloseBackground_open: {
		display: 'flex',
	},
});
