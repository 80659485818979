import { makeStyles } from '@material-ui/core/styles';

import { borderDefault, borderRadius, color_map } from '../../../const/style';

export const useStyles = makeStyles({
	navigationListContainer: {
		backgroundColor: color_map.white,
		borderRadius: borderRadius,
		border: borderDefault,
	},
	siteInfoContainer: {
		margin: '0 12px',
		padding: '12px 0',
		borderBottom: borderDefault,
		display: 'flex',
		justifyContent: 'space-between',
	},
	logo: {
		'&:hover': {
			cursor: 'pointer',
		},
	},
	user: {
		backgroundColor: 'transparent',
		border: 'none',
		boxShadow: 'none',

		'&:hover': {
			cursor: 'pointer',
		},
	},
	navigationList: {
		padding: '6px 0',
		listStyle: 'none',
		margin: 0,
	},
});
