import { makeStyles } from '@material-ui/core/styles';

import { borderRadius, color_map, containerBorder } from '../../../../../const/style';

export const QuantityComponent = makeStyles({
	quantityBtn: {
		border: containerBorder,
		borderRadius: borderRadius,
		transition: `.2s ease`,

		'&:is(:hover, :active)': {
			borderColor: color_map.blue,
		},
	},
	quantityBtn_active: {
		borderColor: color_map.blue,
	},

	list: {
		position: 'absolute',
		left: 0,
		top: '100%',
		background: color_map.white,
		border: containerBorder,
		boxShadow: `0px 0px 10px rgba(0, 0, 0, 0.1)`,
		borderRadius: borderRadius,
		width: 56,
		zIndex: 8,
	},

	list__item: {
		transition: `.2s ease`,

		'&:is(:hover, :active)': {
			backgroundColor: color_map.light_red,
		},
	},
	list__item_active: {
		backgroundColor: color_map.light_red,
	},
});
