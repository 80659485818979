export const html_options = [
	{
		title: 'Да',
		value: 'true',
	},
	{
		title: 'Нет',
		value: 'false',
	},
];
