import React from 'react';
import {
	DataProviderContext,
	DeleteButton,
	Edit,
	required,
	SaveButton,
	SelectInput,
	SimpleForm,
	TextInput,
	Toolbar,
	useNotify,
	useRedirect,
	useRefresh,
	useShowController,
} from 'react-admin';

import Attributes from '../Common/Attributes';
import Currencies from '../../Common/Currencies';
import RichText from '../../Common/RichText';
import ImagesBlock from '../../Common/ImagesBlock';
import Dropdown from '../../Common/Interface/Dropdown';
import Select from '../../Common/Interface/Select';
import SEO from '../../Common/SEO';
import ComplementaryList from '../Common/ComplementaryList';
import VariantsList from '../Common/VariantsList';
import UndoButton from '../../Common/Interface/UndoButton';

import { stock_status } from '../../../data/select/stock_status';
import { item_state } from '../../../data/select/item_state';
import { store_state } from '../../../data/select/store';
import { html_options } from '../data/select';

import { categories_sorter, change_sub_categories } from '../../../utils/categories_sort';
import { days_processing } from '../../../utils/string_processing';

import { createEditStyles } from '../../Common/createEditStyle';

/**
 * Начальные данные для цены
 */
const initial_price_data = {
	initial_price: 0,
	currency_id: 1,
};

/**
 * Компонент списка товаров
 *
 * @param {Object} props параметры компонента
 * @class
 */
export const ProductEdit = (props) => {
	const [categories_data, update_categories_data] = React.useState([]);
	const [attributes_data, update_attributes_data] = React.useState([]);
	const [complementary_data, update_complementary_data] = React.useState([]);
	const [variants_data, update_variants_data] = React.useState([]);
	const [brands_data, update_brands_data] = React.useState([]);
	const [images_data, update_images_data] = React.useState(null);
	const [price_data, update_price_data] = React.useState(initial_price_data);
	// const [save_disable, change_save_disable] = React.useState(true);
	const [rich_data, update_rich_data] = React.useState(null);
	const [record_fetched, update_record_fetched] = React.useState(false);
	const [is_html_only, update_html_only] = React.useState('false');
	const [sorted_categories, update_sorted_categories] = React.useState(null);
	const [active_categories, update_active_categories] = React.useState(null);

	const dataProvider = React.useContext(DataProviderContext);
	const {
		record, // record fetched via dataProvider.getOne() based on the id from the location
	} = useShowController(props);

	const classes = createEditStyles();
	const redirectTo = useRedirect();

	const refresh = useRefresh();
	const redirect = useRedirect();
	const notify = useNotify();

	/**
	 * Запрос и обработка данных брендов
	 */
	React.useEffect(() => {
		redirectTo(`/products/${props.id}`);
		dataProvider
			.getList('brands', {
				pagination: { page: 1, perPage: 200 },
				sort: { field: 'title', order: 'ASC' },
				filter: {},
			})
			.then(({ data }) => {
				// обработка данных для работы `SelectInput`
				const new_data = data.map((item) => {
					return {
						id: item.id,
						name: item.title,
					};
				});

				// отправлялем данные в `state`
				update_brands_data(new_data);
			});
	}, []);

	// пока что скрытый метод выключения кнопки "Сохранить"
	// React.useEffect(() => {
	//     if ((attributes_data && attributes_data.length > 0) || (categories_data && categories_data.length > 0)) change_save_disable(false);
	//     else change_save_disable(true);
	// }, [attributes_data, categories_data]);

	/**
	 * Обработка данных товара для последующей работы
	 */
	React.useEffect(() => {
		if (record) {
			// обработка изображений
			if (record.images && record.images.length > 0) {
				let new_images_data = [];

				for (let i = 0; i < record.images.length; i++) {
					new_images_data.push(record.images[i].url);
				}

				update_images_data(new_images_data);
			}

			update_html_only(record.html_layout + '');

			// обработка описания
			if (record.description && (record.description.body || record.description.body === ''))
				update_rich_data(record.description.body);

			// console.log(record.description);

			update_record_fetched(true);

			// обработка данных категорий
			if (record.categories) {
				// составляем активные категории (уже выбранные у товара)
				const new_active_categories = record.categories.map((category) => {
					return {
						id: category.id,
						title: category.title,
					};
				});

				update_active_categories(new_active_categories);

				// составляем список категорий
				dataProvider
					.getList('categories', {
						pagination: { page: 1, perPage: 200 },
						sort: { field: 'title', order: 'ASC' },
						filter: {},
					})
					.then(({ data }) => {
						// сортируем список категорий с вложением
						let new_categories_data = categories_sorter(data);

						// форматируем данные для выпадающего списка
						new_categories_data = change_sub_categories(new_categories_data);

						// отправлялем данные в `state`
						update_sorted_categories(new_categories_data);
					});
			}
		}

		refresh();
	}, [record]);

	const capital_delivery_date = React.useMemo(() => {
		if (record && record.capital_delivery_time !== null) {
			const current_date = new Date();

			const next_day = 1000 * 60 * 60 * 24 * +record.capital_delivery_time;

			return new Date(+current_date + next_day);
		} else return null;
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [record]);

	const capital_delivery_date_string = React.useMemo(() => {
		if (capital_delivery_date) {
			let day = capital_delivery_date.getDate(),
				mounth = capital_delivery_date.getMonth() + 1;

			if (day < 10) day = `0${day}`;
			if (mounth < 10) mounth = `0${mounth}`;

			return `${day}.${mounth}`;
		} else return '';
	}, [capital_delivery_date]);

	const country_delivery_date = React.useMemo(() => {
		if (record && record.country_delivery_time !== null) {
			const current_date = new Date();

			const next_day = 1000 * 60 * 60 * 24 * +record.country_delivery_time;

			return new Date(+current_date + next_day);
		} else return null;
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [record]);

	const country_delivery_date_string = React.useMemo(() => {
		if (country_delivery_date) {
			let day = country_delivery_date.getDate(),
				mounth = country_delivery_date.getMonth() + 1;

			if (day < 10) day = `0${day}`;
			if (mounth < 10) mounth = `0${mounth}`;

			return `${day}.${mounth}`;
		} else return '';
	}, [country_delivery_date]);

	/**
	 * Метод обработки данных категорий
	 *
	 * @param {Array} new_data новые данные
	 */
	const _update_categories = (new_data) => {
		// проходим по массиву данных выбранных категорий и приводим их к нужному для отправки виду
		const new_category_data = new_data.map((category) => {
			return category.id;
		});

		update_categories_data(new_category_data);
	};

	/**
	 * Метод обновления данных атрибутов (для обновления при сохранении)
	 *
	 * @param {Array} new_data новые данные
	 */
	const _update_attributes = (new_data) => {
		update_attributes_data(
			new_data.filter((item) => item.title !== null && item.description !== null)
		);
	};

	/**
	 * Метод обновления данных изображения
	 */
	const _update_images = (data) => {
		update_images_data(data);
	};

	/**
	 * Method to update complementary data
	 */
	const _update_complementary = (data) => {
		update_complementary_data(data);
	};

	/**
	 * Method to update product variants data
	 */
	const _update_variants = (data) => {
		update_variants_data(data);
	};

	const on_success = React.useCallback(() => {
		notify(`Товар удалён`);
		redirect('/products');
	}, []);

	const undo_edit = React.useCallback(() => {
		notify(`Редактирование отменено`);
		redirect('/products');
	}, []);

	const PostEditToolbar = (props) => (
		<Toolbar {...props}>
			<SaveButton
				disabled={false}
				title='Сохранить'
				transform={(data) => {
					const server_attributes_data = Object.create(null);

					attributes_data.forEach((attribute, index) => {
						if (!attribute._destroy) {
							server_attributes_data[index] = {
								title: attribute.title,
								description: attribute.description,
								sort_position: attribute.sort_position,
								essential: attribute.essential,
							};

							if (attribute.new) server_attributes_data[index].new = true;
							if (attribute.id) server_attributes_data[index].id = attribute.id;
						} else {
							server_attributes_data[index] = {
								id: attribute.id,
								_destroy: '1',
							};
						}
					});

					const new_data = {
						...data,
						...price_data,
						// images: images_data.list,
						category_ids: categories_data,
						description: rich_data,
						html_layout: is_html_only,
						product_attributes: [],
						product_attributes_attributes: server_attributes_data,
						complementary_product_ids: complementary_data,
						product_variant_ids: variants_data,
					};

					// удаляем ненужные для сервера поля
					if (new_data.images) delete new_data.images;
					if (new_data.price) delete new_data.price;
					if (new_data.categories) delete new_data.categories;
					if (new_data.product_variants) delete new_data.product_variants;

					// добавляем поля изображений для отправки
					if (images_data.add && images_data.add.length > 0) {
						// if (images_data.list && images_data.list.length > 0) {
						//     new_data.images = [...images_data.list, ...images_data.add];
						// } else {
						//     new_data.images = [...data.images, ...images_data.add];
						// }
						new_data.images = [...images_data.add];
					}
					if (
						images_data.list &&
						images_data.list.length > 0 &&
						(!images_data.add || images_data.add.length === 0) &&
						(!images_data.delete || images_data.delete.length === 0)
					) {
						new_data.images_data = images_data.list.map((item) => {
							return {
								attachment_id: item.attachment_id,
								sort_position: item.sort_position,
								caption: item.caption,
							};
						});
					}
					if (images_data.delete && images_data.delete.length > 0)
						new_data.attachment_ids = [...images_data.delete];

					return new_data;
				}}
				submitOnEnter={true}
			/>
			<UndoButton action={undo_edit} />
			<DeleteButton
				style={{ marginLeft: 'auto' }}
				mutationMode='pessimistic'
				onSuccess={on_success}
			/>
		</Toolbar>
	);

	if (!record) return null;

	return (
		<Edit
			{...props}
			component={'div'}
			classes={{
				root: classes.editContainer,
				noActions: classes.mainContainer,
			}}
			mutationMode={'pessimistic'}
		>
			<SimpleForm
				toolbar={
					<PostEditToolbar
					// save_disable={save_disable}
					/>
				}
			>
				<SEO title={record && record.title} />
				<h1 className={classes.pageHeader}>{record && record.title}</h1>
				<h2 className={classes.blockHeader}>Основное</h2>
				<TextInput
					source='title'
					label={'Наименование'}
					type='text'
					helperText={false}
					validate={[required()]}
				/>
				<TextInput source='model' label={'Модель'} type='text' helperText={false} />
				<Dropdown
					name='Категории'
					list_data={sorted_categories}
					active_data={active_categories}
					action={_update_categories}
				/>
				<TextInput source='sku' label={'Артикул'} type='text' helperText={false} />
				<SelectInput source='brand_id' choices={brands_data} allowEmpty={true} />
				<TextInput
					source='manufacturer'
					label={'Производитель'}
					type='text'
					helperText={false}
				/>
				<TextInput
					source='warranty_term'
					label={'Срок гарантии'}
					type='text'
					helperText={false}
				/>

				{record_fetched ? (
					<React.Fragment>
						<h2 className={`${classes.blockHeader}`}>Описание</h2>
						<RichText
							parent_value={rich_data}
							update_data={(new_data) => update_rich_data(new_data)}
							is_html_first={is_html_only}
							update_html_only={update_html_only}
						/>
						<Select
							name='Только HTML?'
							list={html_options}
							selected={is_html_only === 'true' ? html_options[0] : html_options[1]}
							tooltip_text={null}
							action={update_html_only}
						/>
					</React.Fragment>
				) : null}

				<h2 className={classes.blockHeader}>Цена</h2>
				<Currencies update_data={update_price_data} />

				<h2 className={classes.blockHeader}>Информация на сайте</h2>
				<SelectInput source='store' choices={store_state} allowEmpty={false} />
				<SelectInput source='status' choices={item_state} allowEmpty={false} />
				<SelectInput source='state' choices={stock_status} allowEmpty={false} />
				<div className='flex flex-col'>
					<p>Сроки доставки:</p>
					<p>
						По Минску -{' '}
						{record?.capital_delivery_time !== null
							? `${record.capital_delivery_time} ${days_processing(
									record.capital_delivery_time
							  )} (${capital_delivery_date_string})`
							: `Нет доставки`}
					</p>
					<p>
						По Беларуси -{' '}
						{record.country_delivery_time !== null
							? `${record.country_delivery_time} ${days_processing(
									record.country_delivery_time
							  )} (${country_delivery_date_string})`
							: `Нет доставки`}
					</p>
					<p>
						<small>сроки доставки будут обновлены после сохранения товара</small>
					</p>
				</div>

				<h2 className={classes.blockHeader}>Deal.by</h2>
				<TextInput
					source='dealby_description'
					label={'Описание'}
					type='text'
					helperText={false}
				/>

				<h2 className={classes.blockHeader}>Onliner</h2>
				<TextInput
					source='halva_price'
					label={'Цена (халва), BYN'}
					type='text'
					helperText={false}
				/>
				<div className={classes.dataBlock}>
					<TextInput
						source='halva_term'
						label={'Срок рассрочки, месяцев'}
						type='text'
						helperText={false}
					/>
					<TextInput
						source='onliner_description'
						label={'Описание'}
						type='text'
						helperText={false}
					/>
				</div>

				<div className={classes.dataBlock}>
					<TextInput
						source='onliner_article'
						label={'Артикул'}
						type='text'
						helperText={false}
					/>
					<TextInput
						source='onliner_category'
						label={'Категория'}
						type='text'
						helperText={false}
					/>
				</div>

				<h2 className={classes.blockHeader}>Limars</h2>
				<TextInput
					source='limars_vendor_code'
					label={'Limars vendor code'}
					type='text'
					helperText={false}
				/>

				<h2 className={classes.blockHeader}>Атрибуты</h2>
				<Attributes update_data={_update_attributes} edit={true} />

				<h2 className={classes.blockHeader}>Изображения</h2>
				<ImagesBlock
					data={record && record.images}
					action={_update_images}
					sorting={true}
				/>
				<p>
					<small>
						<i>
							На данный момент, можно только сортировать или добавлять/удалять
							изображения за одно сохранение данных (иначе сортировка не будет учтена)
						</i>
					</small>
				</p>

				<ComplementaryList action={_update_complementary} />

				<VariantsList action={_update_variants} />
			</SimpleForm>
		</Edit>
	);
};
