/**
 * Key listener wrapper
 *
 * @param {KeyboardEvent} event     keyboard event
 * @param {Function} action         callback for close
 */
export const close_listener = (event = null, action = () => null) => {
	if (event) {
		switch (event.key) {
			case 'Escape':
				action();
				break;
			case 'ArrowUp':
				event.preventDefault();
				break;
			case 'ArrowDown':
				event.preventDefault();
				break;
			default:
				break;
		}
	}
};
