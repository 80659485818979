import * as React from 'react';
import { Admin, Resource } from 'react-admin';
import polyglotI18nProvider from 'ra-i18n-polyglot';
import russianMessages from 'ra-language-russian';

import { CategoryList } from './components/categories/list';
import { CategoryEdit } from './components/categories/edit';
import { CategoryCreate } from './components/categories/create';

import { ProductList } from './components/products/list';
import { ProductEdit } from './components/products/edit';
import { ProductCreate } from './components/products/create';

import { BrandList } from './components/brands/list';
import { BrandEdit } from './components/brands/edit';
import { BrandCreate } from './components/brands/create';

import { UsersList } from './components/users/list';

import { CurrenciesList } from './components/currencies/list';
import { CurrencyEdit } from './components/currencies/edit';
import { CurrencyCreate } from './components/currencies/create';

import { SlidersList } from './components/sliders/list';
import { SliderEdit } from './components/sliders/edit';
import { SliderCreate } from './components/sliders/create';

import { ServiceList } from './components/services/list';
import { ServiceEdit } from './components/services/edit';
import { ServiceCreate } from './components/services/create';

import { OrdersList } from './components/orders/list';
import { OrderEdit } from './components/orders/edit';
import { OrderCreate } from './components/orders/create';

import { ExportsList } from './components/exports/list';

import Login from './components/Login';
import Dashboard from './Dashboard';

import { LayoutComponent } from './containers/Layout';

import authProvider from './authProvider';
import dataProviderComponent from './dataProvider';

import { resources } from './data/translate';

import { myTheme } from './App.style';

russianMessages.ra.auth.username = 'Почта';
russianMessages.ra.page.dashboard = 'Панель управления';
russianMessages.resources = resources;

const i18nProvider = polyglotI18nProvider(() => russianMessages, 'ru');

const App = () => (
	<Admin
		loginPage={Login}
		layout={LayoutComponent}
		authProvider={authProvider}
		dataProvider={dataProviderComponent}
		i18nProvider={i18nProvider}
		dashboard={Dashboard}
		theme={myTheme}
	>
		<Resource
			name='categories'
			list={CategoryList}
			edit={CategoryEdit}
			create={CategoryCreate}
		/>
		<Resource name='products' list={ProductList} edit={ProductEdit} create={ProductCreate} />
		<Resource name='brands' list={BrandList} edit={BrandEdit} create={BrandCreate} />
		<Resource name='users' list={UsersList} />
		<Resource
			name='currencies'
			list={CurrenciesList}
			edit={CurrencyEdit}
			create={CurrencyCreate}
		/>
		<Resource name='sliders' list={SlidersList} edit={SliderEdit} create={SliderCreate} />
		<Resource name='services' list={ServiceList} edit={ServiceEdit} create={ServiceCreate} />
		<Resource name='orders' list={OrdersList} edit={OrderEdit} create={OrderCreate} />
		<Resource name='exports' list={ExportsList} />
	</Admin>
);

export default App;
