import { makeStyles } from '@material-ui/core/styles';

import { borderDefault, borderRadius, color_map } from '../../../../const/style';

export const useStyles = makeStyles({
	modal: {
		position: 'fixed',
		left: 0,
		right: 0,
		top: 0,
		bottom: 0,
		display: 'none',
		opacity: '.01',
	},

	modal_open: {
		display: 'flex',
	},

	modal__background: {
		background: `rgba(18, 99, 175, 0.7)`,
		position: 'absolute',
		width: '100%',
		height: '100%',
		zIndex: 1,
		transition: '.2s ease',
		padding: 0,
		border: 'none',
	},

	content: {
		position: 'absolute',
		left: '50%',
		top: '50%',
		transform: 'translate(-50%, -50%)',
		backgroundColor: color_map.white,
		border: borderDefault,
		borderRadius: borderRadius,
		display: 'flex',
		flexDirection: 'column',
		padding: '32px 48px',
		zIndex: 2,
	},

	modal__loader: {
		maxWidth: 64,
		alignSelf: 'center',
		animation: 'spin 1s cubic-bezier(.7,.3,.3,.99) infinite',
	},

	modal__text: {
		margin: '8px 0 0 0',
		fontSize: 14,
		lineHeight: 1.15,
		color: color_map.black,
	},
});
